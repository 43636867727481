.navBar{
    width: 190px;
    min-width: 190px;
    height: 100vh;
    background-color: #272626;
    padding: 14px 24px;
    box-sizing: border-box;
    color:#f5e3c5;
}


.appName{
    font-family: 'Eczar', serif;
    font-weight: 600;
    font-size: 30px;
    color:#ebd7b6;
    padding-left: 11px;
}

.appName.min{
    display: none;
}


.navItems{
    padding-top: 22px;
}

.navItem{
    display: flex;
    align-items: center;
    padding: 8px 0;
    margin-bottom: 12px;
    text-decoration: none;
    color: unset;
    cursor: pointer;
}

.navText{
    font-family: 'Poppins', sans-serif;
    font-size: 14.5px;
    margin-left: 6.5px;
}

.navItem:hover{
    background-color: #4f4c4c;
    padding: 8px 16px;
    margin-left: -16px;
    border-radius: 25px;
}

.navItem.selected{
    background-color: #f5e3c5;
    color: #000;
    font-weight: 600;
    padding: 8px 16px;
    margin-left: -16px;
    border-radius: 25px;
}

.closeIcon{
    display: none;
}

@media (max-width: 1300px){


    .navBar{
        width: 180px;
        min-width: 180px;
    }
}


@media(max-width: 1190px) and (min-width:600px){
    .navBar{
        width: 65px;
        min-width: 65px;
        padding: 14px 0;
    }

    .navText{
        display: none;
    }

    .navItem{
        width: 34px;
        height: 34px;
        padding:0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
    }


    .navIcon{
        font-size: 20px;
    }

    .navItem.selected, .navItem:hover{
        padding: 0;
        margin-left: 0;
        border-radius: 50%;
    }

    .navItems{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .appName{
        display: none;
    }
    .appName.min{
        display: block;
        text-align: center;
        padding: 0;
        font-family: 'Parisienne', cursive;
        font-size: 35px;
        transform: rotate(-15deg);
    }
}


@media (max-width: 600px){

    .navWrapper{
        width: 0;
    }
    
    .navWrapper.hide .navBar{
        display: none;
    }

    .navBar:not(.hide){
        position: fixed;
        display: block;
        z-index: 10;
        padding-top: 6px;
        box-shadow: 0 0 0 1px #424244;
        background-color: #1e1f21;
        border-right: 0.8px solid rgb(73, 72, 72);
        box-sizing: border-box;
    }

    .navWrapper.hide .navMenu{
        position: fixed;
        top: 0;
        left: 7px;
        height: 58px;
        display: flex;
        align-items: center;
        color: #f2e5cd;
    }

    .menuIcon{
        width: 33px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
        border-radius: 10px;
    }

    .menuIcon:hover, .closeIcon:hover{
        background-color: #4f4c4c;
    }

    .closeIcon{
        float: right;
        width: 33px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
        border-radius: 10px;
        margin-right: -20px;
    }

    .appName{
        padding-top: 25px;
    }

    .navItems{
        padding-top: 18px;
    }

}