.wrapper{
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 25px;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    z-index: 10;
    box-sizing: border-box;
    min-width: 280px;
}

.box{
    background-color: #252424;
    width: 85%;
    min-width: 410px;
    max-width: 500px;
    border-top-left-radius: 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    max-height: calc(100vh - 25px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.headerArea{
    height: 85px;
    min-height: 85px;
    background: radial-gradient(circle, rgba(232,210,173,1) 0%, rgba(212,172,103,1) 91%);
    width: 100%;
    border-top-left-radius: 5px;
    padding: 7px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.firstArea{
    color: #000;
    display: flex;
    align-items: center;
}

.arrowIcon{
    width: 20px;
    height: 20px;
    font-size: 17px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    cursor: pointer;
}

.arrowIcon:hover{
    background-color: #c59d54;
}

.title{
    font-family: "Outfit", sans-serif;
    font-size: 16.5px;
     font-weight: 600;
}

.btns{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.imageArea{
    position: absolute;
}

.editBtn, .deleteBtn{
    width: 20px;
    height: 20px;
    border: 1.65px solid #a07e3f;
    color:#694f1b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 4.5px;
    cursor: pointer;
}

.editBtn:hover, .deleteBtn:hover{
    transform: scale(1.1,1.1);
}

.editBtn{
    margin-right: 8px;
}

.imageArea{
    left: 10px;
    bottom: -30px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3.5px solid #343333;
    box-sizing: border-box;
}

.imageBox{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.85);
    background-color: #dcbd87;
    color: #000;
    font-size: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}

.imageBox.set{
    background-color: #252424;
}

.imageBox img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.box main{
    padding: 34px 16px 20px;
    font-family: 'Poppins', sans-serif;
    overflow-y: auto;
}

.name{
    font-size: 12.9px;
}

.joinDate{
    color: #d4d0d0;
    font-family: 'DM Sans', sans-serif;
    font-size: 11.7px;
    padding-left: 1.8px;
}

.status{
    color: #e0c8a0;
    background-color: #4e4638;
    padding: 3.5px 6px;
    border-radius: 15px;
    font-size: 11.2px;
    display: inline-flex;
    margin-top: 6.5px;
    text-transform: capitalize;
}

.section{
    border-bottom: 1.4px solid #484848;
    margin: 10px 0;
    padding-bottom: 10px;
}

.mainInfo{
    border-bottom: 1.4px solid #484848;
    padding-bottom: 10px;
}

.financial.section{
    border: 0;
    padding-bottom: 0;
}

.sectionTitle{
    font-family: "Outfit", sans-serif;
    font-size: 15.5px;
    margin-bottom: 6.5px;
}

.detailLabelBox{
    color: #d4d0d0;
    display: flex;
    align-items: center;
}

.detailLabel{
    font-family: 'DM Sans', sans-serif;
    font-size: 12.6px;
    margin-left: 4px;
}

.detailContent{
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.detailBox{
    margin-bottom: 7.5px;
}

.financial .detailBox{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.financial .detailContent{
    margin-top: unset;
}

.section .detailBox:last-of-type, .section.financial .detailBox:last-of-type{
    margin-bottom: 0;
}

.financial .sectionTitle{
    margin-bottom: 10px;
}

.detailIcon{
    display: flex;
    align-items: center;
    font-size: 14.5px;
}

.money{
    font-size: 13px;
}

.diamond{
    font-size: 12.4px;
}

.transactions{
    font-size: 14px;
}

.minArrow{
    margin: 0 3.5px;
    display: flex;
    align-items: center;
}

@media (min-height: 780px){
    .box{
        max-width: 510px;
    }
    
    .headerArea{
        height: 90px;
        min-height: 90px;
    }
    
    .arrowIcon{
        width: 21px;
        height: 21px;
        font-size: 18px;
        border-radius: 3.7px;
    }
    
    .title{
        font-size: 17.3px;
    }
    
    .editBtn, .deleteBtn{
        width: 21px;
        height: 21px;
        font-size: 15px;
    }
    
    .imageArea{
        bottom: -33px;
        width: 73px;
        height: 73px;
    }
    
    .imageBox{
        font-size: 47px;
    }
    
    .box main{
        padding: 37px 16px 20px;
    }
    
    .name{
        font-size: 13.7px;
    }
    
    .joinDate{
        font-size: 12.5px;
    }
    
    .status{
        border-radius: 16px;
        font-size: 12px;
    }

    .sectionTitle{
        font-size: 16.3px;
    }
    
    .detailLabel{
        font-size: 13.4px;
    }
    
    .detailContent{
        font-size: 12.8px;
    }
    
    .detailIcon{
        font-size: 15.3px;
    }
    
    .money{
        font-size: 13.8px;
    }
    
    .diamond{
        font-size: 13.2px;
    }
    
    .transactions{
        font-size: 14.8px;
    }
}