.spread{
    aspect-ratio: 1 / 1.23;
    height: 28vh;
    background-size: 100% 100%;
    border-radius: 7%;
    color: #000;
}

.spread.min{
    height: 26vh;
}

.spread.active{
    background-image: url("../media/stepCard.png");
}

.spread.completed, .intention.completed{
    cursor: pointer;
}

.spread.completed:hover, .intention.completed:hover{
    transform: scale(1.15,1.15);
    z-index:2500
}

.spread.completed:hover .spreadInner, .intention.completed:hover .spreadInner{
    transform: rotateY(180deg);
}

.contentWrapper{
    width: 100%;
    height: 100%;
    position: relative;
}

.spreadContent{
    top: 9%;
    position: absolute;
    width: 100%;
    height: 100%;
}

.spreadTitle{
    height: 13%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'IM Fell English SC', serif;
    font-size: 2.7vh;
}

.spread.min .spreadTitle{
    font-size: 2.4vh;
}

.lockWrapper{
    margin: 28% auto 0;
    width: 25%;
    height: 22%; 
    animation:  expand 3s linear 2s infinite alternate;
}

.lockWrapper img{
    width: 100%;
    height: 100%;
}

.spread.active{
    box-shadow: 0 0 15px 3.5px rgb(255,255,255,0.4);
    box-sizing: border-box; 
    animation:  expand 3s linear 2s infinite alternate;
}

.spreadBox{
    position:relative;
    perspective: 800px;
    width: 100%;
    height: 100%;
}

.spreadInner{
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition:transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.front{
    display: flex;
    align-items: center;
    justify-content: center;
}

.front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-size: 100% 100%;
    background-image: url("../media/stepCard.png");
}

.back {
    transform: rotateY(180deg);
}

.spreadName{
    margin: 28% auto 0;
    padding: 0 17%;
    text-align: center;
    font-family: 'Almendra', serif;
    font-size: 1.82vh;
    font-weight: 600;
    line-height: 1.3;
}

.spread.min .spreadName{
    font-size: 1.7vh;
}


.spreadDesc{
    margin: 19% auto 0;
    padding: 0 12%;
    text-align: center;
    font-family: 'Almendra', serif;
    font-size: 1.55vh;
    font-weight: 600;
    line-height: 1.3;
    color: #a2998d;
    font-family: 'Macondo', cursive;
    height: 34%;
    display: flex;
    align-items: center;
}

.spread.min .spreadDesc{
    font-size: 1.43vh;
}


@keyframes expand{
    from {transform: scale(1,1);}
    to {transform: scale(1.1,1.1)}
}

@media (max-width: 125vh){
    .spread{
        left: 6vw
    }
}

@media (not (orientation:landscape)) and (not (height:100vw)){
    .spread{
        height: 25vw;
        top: 11.5vw;
        left: 6.5vw;
        aspect-ratio: 1 / 1.3;
    }

    .spreadTitle{
        font-size: 2.8vw;
    }

    .spreadName{
        font-size: 1.76vw;
    }
    
    
    .spreadDesc{
        font-size: 1.45vw;
    }
}