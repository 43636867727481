.container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #030c12;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;

}

.box{
    perspective: 500px;
}

.box.starting{
    transform: scale(1.3,1.3);
}
  
.content {
    transition: transform 1s;
    transform-style: preserve-3d;
    height: 44vh;
    width: 44vh;
}
  
.box.starting .content{
    animation: flip 250ms forwards;
    animation-iteration-count: 5;
    animation-delay: 50ms;
    animation-direction: reverse;
}

.front,.back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
  
.back {
    transform: rotateY( 180deg );
    position: absolute;
}


.mysticCircle{
    border: 0.17vh solid rgba(242,230,208,0.7);
    border-radius: 50%;
    width: 44vh;
    height: 44vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 1.65vw 0.23vw #bbae98;
    box-sizing: border-box;
}

.innerCircle{
    width: 37vh;
    height: 37vh;
    border-radius: 50%;
    background-color: #F2E6D0;
    box-shadow: 0 0 1.15vw 0.13vw #c8bca6;
}

.innerCircle img{
    height: 100%;
    width: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
}

.loader{
    height: 8.7vh;
    aspect-ratio: 7 / 1;
    border-radius: 4vh;
    background-color: #F2E6D0;
    margin-top: 4vh;
    box-shadow: 0 0 1.15vw 0.13vw #c8bca6;
    padding: 0.7vh;
    box-sizing: border-box;
}

.innerLoader{
    border-radius: 4vh;
    height: 7.3vh;
    background-color: #b2a690;
    position: relative;
}

.progressBar{
    background-color: #030c12;
    border-radius: 4vh 0 0 4vh;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.progressBar.min{
    display: none;
}

.progressBar.max{
    border-radius: 4vh 3.5vh 3.5vh 4vh;
}

.progressBar.completed{
    border-radius: 4vh;
}

.progress{
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #F2E6D0;
    font-family: 'Special Elite' ,sans-serif;
    font-weight: 600;
    font-size: 2.8vh;
    z-index: 3;
}

@media (max-width: 90vh){

    .content {
        width: 63vw;
        height: 63vw;
    }
    
    .mysticCircle{
        border-width: 0.4vw;
        width: 63vw;
        height: 63vw;
    }
    
    .innerCircle{
        width: 56vw;
        height: 56vw;
    }
    
    .loader{
        height: 10.53vw;
        border-radius: 5vw;
        margin-top: 4.03vw;
        padding: 0.73vw;
    }
    
    .innerLoader{
        border-radius: 5vw;
        height: 9.07vw;
    }

    .progressBar{
        border-radius: 5vw 0 0 5vw;
    }

    .progressBar.max{
        border-radius: 5vw 4.4vw 4.4vw 5vw;
    }
    
    .progressBar.completed{
        border-radius: 5vw
    }
    
    .progress{
        font-size: 3vw;
    }
}
@keyframes flip{
    from{
        transform: none;
    }
    to{
        transform: rotateY(180deg);

    }
}