.wrapper{
    background-color: #1e1d1d;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 30px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box{
    background-color: #000;
    max-width: 450px;
    width: 100%;
    height: fit-content;
    margin: auto;
    border-radius: 8px;
    padding-bottom: 42px;
    box-shadow: rgba(112, 128, 144, 0.05) 0px 54px 55px, rgba(112, 128, 144, 0.12) 0px -12px 30px, rgba(112, 128, 144, 0.12) 0px 4px 6px, rgba(112, 128, 144, 0.17) 0px 12px 13px, rgba(112, 128, 144, 0.09) 0px -3px 5px;
}

.header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #e8d2ad;
    border-radius: 8px 8px 0 0;
    color: #000;
    font-weight: 600;
    padding: 15px 0;
}

.logo img{
    width: 32px;
    height: 32px;
}

.appTitle{
    font-family: 'Parisienne', cursive;
    font-size: 29px;
    margin-left: 6px;
}

.admin{
    font-family: "Sora", sans-serif;
    font-size: 22px;
    letter-spacing: 0.1px;
    margin-left: 8px;
}

.inputs{
    padding: 35px 30px 15px;
}

.inputBox label{
    color: #c8c3c3;
    margin-bottom: 3px;
    display: block;
    font-family: 'DM Sans', sans-serif;
    font-size: 13.8px;
}

.inputBox{
    margin-bottom: 16px;
}

.inputBox input{
    height: 47px;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    background: rgba(255, 255, 255, 0);
    border: 1.65px solid #353535;
    padding: 0 8px;
    box-sizing: border-box;
    font-size: 12.5px;
    font-family: 'Poppins', sans-serif;
    border-radius: 8px;
    background-color:rgba(255, 255, 255, 0.07);
}

.inputBox input:focus{
    border-color: #d2bd99
}


.box button[type="submit"]{
    width: calc(100% - 60px);
    border: 0;
    outline: 0;
    background-color: #e8d2ad;
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size:16px;
    height: 48px;
    margin: 0 30px;
    border-radius: 8px;
}

.errorBox{
    background-color: #ad3118;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.5px;
    padding: 7px 8px;
    margin: -6px 0 12px
}

.warningIcon{
    display: flex;
    align-items: center;
    font-size: 15.5px;
    margin-right: 2.5px;
    margin-top: -2px;
}

.errorText{
    font-size: 12.7px;
    font-family: 'Poppins', sans-serif;
}

@media (max-width: 460px){
    .wrapper{
        display: block;
        padding: 0;
        background-color: #000;
    }

    .inputs{
        padding: 35px 15px 15px;
    }

    .box button[type="submit"]{
        width: calc(100% - 30px);
        margin: 0 15px;
    }

    .box{
        width: 100%;
        border-radius: 0;
        box-shadow: unset;
        max-width: unset;
        padding-bottom: 10px;
    }

    .header{
        border-radius: 0;  
    }
}

@media(max-width: 375px){
    .logo img{
        width: 30px;
        height: 30px;
    }
    
    .appTitle{
        font-size: 27px;
        margin-left: 5px;
    }
    
    .admin{
        font-size: 20px;
        margin-left: 7px;
    }

    .inputBox input{
        height: 46px;
        padding: 0 6px;
        font-size: 12px;
    }
    
    .box button[type="submit"]{
        font-size:15px;
        height: 47px;
        width: calc(100% - 24px);
        margin: 0 12px;
    }

    .inputs{
        padding: 30px 12px 12px;
    }

    .inputBox label{
        font-size: 13.3px;
    }
}

@media (max-width: 315px){
    .logo img{
        width: 25px;
        height: 25px;
    }
    
    .appTitle{
        font-size: 23px;
    }
    
    .admin{
        font-size: 16px;
    }
    
    .inputs{
        padding: 22px 10px 12px;
    }

    .box button[type="submit"]{
        width: calc(100% - 20px);
        margin: 0 10px;
    }
}