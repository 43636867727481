.wrapper{
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 45px 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    z-index: 10;
    box-sizing: border-box;
    min-width: 280px;
}

.box{
    background-color: #252424;
    width: 95%;
    min-width: 410px;
    max-width: 500px;
    border-radius: 5px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    max-height: calc(100vh - 90px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.box header{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 8.5px 20px;
    padding-right: 6px;
    border-bottom:1.1px solid #484848;
}

.title{
    font-family: "Outfit", sans-serif;
    font-size: 19px;
    flex-grow: 1;
    margin-left: 6px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.userIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20.5px;
    border-radius: 6px;
    margin-top: -2px;
}

.editIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22.5px;
    border-radius: 6px;
    margin-top: -1px;
}

.closeIcon{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21.5px;
    color: #d9d5d5;
    cursor: pointer;
    border-radius: 50%;
}

.closeIcon:hover{
    color: #fff;
    background-color: #424244;
}

.box main{
    padding: 20px 20px 28px;
    overflow-y: auto;
}

.loadingArea{
    padding: 30px 0;
    overflow-y: auto;
}

.spinner{
    width: 24px;
    height: 24px;
    border: 2.4px solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.resetBtn .spinner{
    width: 20px;
    height: 20px;
}

.loadingSpinner{
    border: 3.8px solid #676667;
    border-radius: 50%;
    border-top: 3.8px solid transparent;
    width: 60px;
    height: 60px;
    -webkit-animation: rotation 1.6s linear infinite;
    animation: rotation 1.6s linear infinite;;
    box-sizing: border-box;
    margin: 0 auto;
}

.instructions{
    font-size: 11.7px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.1px;
    color: #a9a6a6;
    margin-bottom: 3.5px;
    margin-top: -1.5px;
}

.countryBtn{
    min-width: 140px;
    width: fit-content;
    padding: 0 10px;
    border-radius: 5px;
    background-color:rgba(255, 255, 255, 0.07);
    border: 1.65px solid #515050;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 35px;
    margin-top: 2px;
    cursor: pointer;
}

.countryBtn:hover{
    transform: scale(1.05,1.05);
}

.country{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    margin-left: 6.5px;
}

.flag{
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
}

.placeholder{
    font-size: 11.7px;
    font-family: 'Poppins', sans-serif; 
    color: #a9a6a6;
}

.worldIcon{
    font-size: 14.5px;
    display: flex;
    align-items: center;
    margin-right: 4.5px;
    color:#a9a6a6;
}

.box footer{
    padding: 20px;
    box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.box button[type="submit"]{
    width: 100%;
    border: 0;
    outline: 0;
    background-color: #e8d2ad;
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 14.5px;
    height: 36.5px;
    border-radius: 3px;
    text-transform: uppercase;
    cursor: pointer;
}

.box button[type="submit"]:hover, .generateBtn:hover, .resetBtn:hover{
    background-color: #dfc69b;
}

.box button[type="submit"].saveBtn{
    width: 110px;
    border-radius: 6px;
    text-transform: capitalize;
    font-size: 13.2px;
}

.cancelBtn{
    width: 66px;
    font-family: 'Poppins', sans-serif;
    font-size: 13.2px;
    border-radius: 6px;
    height: 36.5px;
    outline:0;
    border:0;
    margin-right: 13px;
    background-color: #505053;
    color:#e4e4e4;
    font-weight: 600;
}

.generateBtn{
    width: 85px;
    font-family: 'Poppins', sans-serif;
    font-size: 12.3px;
    border-radius: 5px;
    height: 31.5px;
    outline:0;
    border:0;
    color:#e4e4e4;
    font-weight: 600;
    background-color: #e8d2ad;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 6px;
}

.resetBtn{
    width: 118px;
    font-family: 'Poppins', sans-serif;
    font-size: 12.1px;
    border-radius: 5px;
    height: 31.5px;
    outline:0;
    border:0;
    color:#e4e4e4;
    font-weight: 600;
    background-color: #e8d2ad;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;  
}

.resetLoadingBtn{
    width: 118px;
    border-radius: 5px;
    height: 31.5px;
    background-color: #e8d2ad;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;  
}

.box button[type="submit"]:disabled{
    opacity: 0.7;
    pointer-events: none;
    cursor: unset;
    z-index: 0;
    position: relative;
}

.loadingBtn{
    width: 100%;
    background-color: #e8d2ad;
    height: 36.5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingBtn.save{
    height: 36.5px;
    width: 110px;
    border-radius: 6px;
}

.inputBox, .passwordBox{
    height: 36px;
    width: 75%;
    position: relative;
}

.passwordBox{
    margin-bottom: 12px;
}

.inputBox input, .passwordBox input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    border: 1.65px solid #515050;
    padding: 0 8px;
    box-sizing: border-box;
    font-size: 11.7px;
    font-family: 'Poppins', sans-serif;
    border-radius: 5px;
    background-color:rgba(255, 255, 255, 0.07);
}

.inputWrapper input[type="file"]{
    display: none;
}


.inputBox.email input:disabled{
    opacity: 0.7;
    color:#fff !important;
    cursor: not-allowed;
}

.passwordBox input:disabled{
    color:#fff !important;
}

.passwordBox{
    position: relative;
}

.passwordBox.filled input{
    padding-right: 38px;
}

.copyBtn{
    width: 28.5px;
    height: 28.5px;
    top: 4px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16.5px;
    background-color: #505053;
    border-radius: 3.5px;
    position: absolute;
    cursor: pointer;
}

.copyBtn:hover{
    transform: scale(1.03,1.03);
}

.generateIcon{
    display: flex;
    align-items: center;
    margin-right: 3.1px;
}

.resetIcon{
    display: flex;
    align-items: center;
    margin-right: 3.1px; 
    font-size: 17.5px;
    margin-top: -3.5px;
}

.inputBox.balance{
    width: 50%;
    max-width: 100px;
    position: relative;
}

.inputBox.balance input{
    padding-right: 35px;
}

.diamondIcon{
    width: 30px;
    height: 100%;
    border-left: 1.65px solid #515050;
    font-size: 15.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    border-radius: 0 5px 5px 0;
    top: 0;
    color: #a9a6a6;
}

.errorBox .diamondIcon{
    border-color: #d53d1f;
    color: #d53d1f;
}

.inputWrapper{
    margin-bottom: 10px;
}

.permissionsWrapper{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.permissionBox{
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 1.55px solid #515050;
    padding: 9px 8px;
    border-radius: 6px;
    cursor: pointer;
}

.permissionBox:hover{
    transform: scale(1.1,1.1);
}

.permissionBox.checked{
    border-color: #e8d2ad;
    background-color: #4e4638;
    color: #e8d2ad;
}

.choice.checked{
    background-color: #4e4638;
    color: #e8d2ad;
}

.dateInputs .selectorWrapper{
    width: calc(31.5% - 5px);
    position: relative;
}

.dateInputs .selectorWrapper:last-of-type{
    width: calc(37% - 5px);
}

.selectorBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 0.7px solid rgba(255, 255, 255, 0.3);
    height: 35.5px;
    padding: 0 7px;
    box-sizing: border-box;
    cursor: pointer;
}

.arrow{
    margin-left: 4.5px;
    display: flex;
    align-items: center;
    font-size: 15px;
}

.selectorInput, .placeholder{
    font-size: 12.6px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.dateInputs{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.inputWrapper.birthdate{
    width: 50%;
    max-width: 170px;
}

.menu{
    position: fixed;
    background-color: #282828;
    width: 100%;
    left: 0;
    padding: 4px 0;
    box-shadow: 0 0 0 1px #424244;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 4px;
    z-index: 3;
    overflow-y: auto;
}

.menu::-webkit-scrollbar {
    width: 6px; 
}

.menu::-webkit-scrollbar-track {
    background: #3e3e3e;
    border-radius: 6px;
}

.menu::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 6px;
}

.menuItem{
    color: #d9d6d6;
    font-family: 'DM Sans', sans-serif;
    justify-content: center;
    font-size: 12.65px;
    height: 25.5px;
    display: flex;
    cursor: pointer;
    align-items: center;
}


.menuItem:hover{
    background-color: #4a4848
}

.permissionCircle{
    width: 15.5px;
    height: 15.5px;
    border: 1.35px solid #515050;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.permissionBox.checked .permissionCircle{
    border-color: #e8d2ad;
}

.innerCircle{
    width: 11px;
    height: 11px;
    background-color: #e8d2ad;
    border-radius: 50%;
}

.perm, .statusText{
    font-family: 'Poppins', sans-serif;
    font-size: 12.5px;
    margin-left: 4px;
}

.length{
    top: -18px;
    right: 2px;
    font-family: 'Poppins', sans-serif;
    font-size: 10.7px;
    color: #d4d0d0;
    position: absolute;
}
.permissionsWrapper .permissionBox:last-of-type{
    margin-left: 10px;
}

.statusArea{
    margin-top: -10px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.statusSlider{
    width: 40px;
    height: 19.5px;
    background-color: #676667;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 2.5px;
    box-sizing: border-box;
}

.statusArea .statusSlider{
    width: 36px;
    height: 17px;
}

.statusSlider.checked{
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 2px;
    background-color: #e8d2ad;
}

.statusSlider.checked .statusCircle{
    background-color: #000;
    width: 16px;
    height: 16px;
}

.statusCircle{
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.statusArea .statusSlider.checked .statusCircle{
    width: 14px;
    height: 14px;
}

.statusArea .statusCircle{
    width: 13px;
    height: 13px;
}

.statusBox{
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.shake{
    animation: shake 0.3s ease-in-out;
}

.errorBox input{
    border-color: #ab2323;
    background-color: #36100a; 
    color:#e23535;
}

.error{
    font-size: 12px;
    font-family: 'Poppins', sans-serif; 
    color: #d53d1f;
    margin-bottom: -7px;
}

.box main .inputWrapper:last-of-type{
    margin-bottom: 0;
}

.imageBox{
    display: flex;
    align-items: center;
    margin-top: 7px;
}

.box label, .labelBox{
    color: #d4d0d0;
    display: block;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    margin-bottom: 3px;
}

.box .imageCircle{
    display: flex;
    font-size: 35px;
    margin-bottom: 0;
}

.imageCircle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-size: 100% 100%;
    border: 1.2px solid #676667;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.imageCircle:hover{
    background-color: #505053;
}

.imageCircle img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
}

.removeBtn{
    border: 1px solid #e06155;
    color:#e06155;
    font-size: 12.4px;
    font-family: 'Poppins', sans-serif;
    padding: 7px 9px;
    border-radius: 5px;
    margin-left: 8px;
    cursor: pointer;
}

.removeBtn:hover{
    background-color: #36100a;
}

.doubleWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.doubleWrapper .inputWrapper{
    width: 42%;
    margin-right: 8%;
}

.box .doubleWrapper .inputWrapper:last-of-type{
    margin-bottom: 13.5px;
}

.choices{
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color:rgba(255, 255, 255, 0.07);
    border: 1.65px solid #515050;
    height: 36.5px;
    box-sizing: border-box;
    margin-top: 2px;
    justify-content: center;
    width: fit-content;
}

.choice{
    font-family: 'Poppins', sans-serif;
    font-size: 12.2px;
    padding: 0 10px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-right: 1.65px solid #515050;
}

.choices .choice:first-of-type{
    border-radius: 5px 0 0 5px;
}

.choice:hover{
    transform: scale(1.1,1.1);
}

.choices .choice:last-of-type{
    border: 0;
    border-radius: 0 5px 5px 0;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media(max-height: 650px){
    .box{
        background-color: #252424;
        width: 85%;
        min-width: 410px;
        max-width: 440px;
    }

    .box header{
        padding: 8.5px 14px;
    }

    .box footer{
        padding: 15px;
    }

    .box main{
        padding: 12px 12px 20px;
    }

    .box .doubleWrapper .inputWrapper:last-of-type{
        margin-bottom: 10px;
    }
}

@media (max-width: 520px){
    .wrapper{
        display: block;
        padding: 0;
        background-color: #252424;
    }

    .box{
        min-width: unset;
        max-width: 100%;
        overflow-x: hidden;
        box-shadow: unset;
        border-radius: unset;
        width: 100%;
        height: 100%;
        max-height: 100%;
    }

    .box main{
        flex-grow: 1;
    }
}

@media (max-width: 350px){
    .box main{
        padding: 8px 8px 15px;
    }
    
    .box label, .labelBox{
        font-size: 11.1px;
    }

    .box header{
        padding: 8.5px 8px;
        padding-right: 5px;
    }

    .title{
        font-size: 16.5px;
    }

    .userIcon{
        font-size: 19px;
    }

    .editIcon{
        font-size: 21px;
    }

    .removeBtn{
        font-size: 11.1px;
    }

    .statusArea{
        margin-bottom: 7.5px;
    }
}
