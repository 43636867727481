.wrapper{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: fixed;
    padding-top: 1.1vh;
    min-height: 45vh;
    margin: 0 auto;
    color: #000;
    bottom: 5vh;
    --width: calc(100vh * 720 / 456);
    --offsetX : calc((100vw - var(--width)) / 2);
    left: calc(var(--offsetX) + 0.35 * var(--width));
    width: calc(var(--width) * 0.6);
}


.closeIcon{
    --width: calc(100vh * 720 / 456);
    --offsetX : calc((100vw - var(--width)) / 2);
    --width_box: calc(var(--width) * 0.6);
    position: fixed;
    left: calc(var(--offsetX) + 0.91 * var(--width));
    top: 51vh;
    background-color: #F2E6D0;
    width: 3.6vh;
    height: 3.6vh;
    border-radius: 50%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.6vh;
    cursor: pointer;
}

.closeIcon:hover{
    transform: scale(1.05,1.05);
    background-color: #e7d8bd;
}

.titleWrapper{
    display: none;
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
    padding: 0.45vh 3vh;
    box-sizing: border-box;
    /* border-top: 0.11vh solid #D98C4A;
    border-bottom: 0.11vh solid #D98C4A */
}

.titleBox{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: 'IM Fell English SC', serif;
    font-size: 3.7vh;
    letter-spacing: -0.15vh;
    color: #F2E6D0;
}

.leftDec{
    margin-right: 3vh;
}

.rightDec{
    margin-left: 3vh;
}
.leftDec, .rightDec{
    height: 3.2vh;
    width: 2.6vh;
    background-size: 100% 100%;
    margin-top: 0.25vh;
}

.container{
    background-size: 100% 100%;
    width: 80%;
    height: 78%;
    margin: 2% 5% 7% 15%;;
    border-radius: 1vh;
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-grow: 1;
    background-color: #F2E6D0;
}

.card{
    height: 85%;
    aspect-ratio: 1 / 1.44;
    position: absolute;
    left: -12%;
    border: 0.6vh solid #cdc4b4;
    box-sizing: border-box;
    background-size: 100% 100%;
    border-radius: 1.5vh;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.card.reversed{
    transform: scaleY(-1);
}

.content{
    height: 80%;
    margin-left: 20%;
    padding-right: 7%;
}

.cardTitle{
    font-family: 'Eczar', serif;
    font-size: 3.2vh;
    font-weight: 700;
}

.cardTitle span{
    font-weight: 400;
}

.cardShortDesc{
    font-family: 'Caudex', serif;
    font-size: 2.1vh;
    font-weight: 600;
    text-decoration: underline;
}

.cardLongDesc{
    font-size: 2vh;
    font-weight: 600;
    line-height: 1.5;
    color: #a2998d;;
    font-family: 'Macondo', cursive;
    padding-top: 0.5vh;
}

.stepper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 3.2vh;
    left: 0;
    width: 100%;
}

.dot{
    width: 1.5vh;
    height: 1.5vh;
    border-radius: 50%;
    margin-right: 1vh;
    cursor: pointer;
    background-color: #000;
    opacity: 0.25;
}

.dot:hover{
    transform: scale(1.2,1.2);
}

.stepper .dot:last-of-type{
    margin-right: 0;
}

.dot.active{
    width: 3.5vh;
    border-radius: 0.7vh;
    pointer-events: none;
    opacity: 1;
}
.chatWrapper{
    position: absolute;
    right: -1vh;
    bottom: -0.5vh;
}

.chatButton{
    color: #7e3b14;
    width: 6vh;
    min-width: 7vh;
    height: 6vh;
    max-height: 6vh;
    border-radius: 50%;
    background-size: 100% 100%;
    font-size: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;
    animation: expand-button 1s linear 0.5s infinite alternate;
    box-shadow: 0 0 1.85vw 0.43vw #f9e0a7; 
    box-sizing: border-box;
}

.tooltip{
    display: none;
}

.chatButton:hover{
    box-shadow: 0 0 1.85vw 0.43vw #fad5a7; 
    animation: none;
}

.chatWrapper:hover .tooltip{
    display: block;
    padding: 0.7vh 2vh;
    background-color: #7e3b14;
    color: #f7cb77;
    font-family: 'Caudex', serif;
    font-size: 1.6vh;
    border-radius: 1.8vh;
    position: fixed;
    right: -1.5vh;
    bottom: 7.8vh;
    font-weight: 600;
    letter-spacing: 0.1vh;

}

@media (not (orientation:landscape)) and (not (height:100vw)){
    .wrapper{
        padding-top: 1.6vw;
    }

    .content{
        margin-left: 13%;
    }
    
    .titleWrapper{
        padding: 0.65vw 3.1vw;
        box-sizing: border-box;
        border-width: 0.1vw;
    }
    
    .titleBox{
        font-size: 3.6vw;
        letter-spacing: -0.2vw;
    }
    
    .leftDec, .rightDec{
        height: 3.1vw;
        width: 2.7vw;
        margin-top: 0.26vw;
    }
    
    .container{
        border-radius: 1.1vw;
        margin:2% 3% 7% 13%;
        width: 84%;
    }
    
    .card{
        width: 23%;
        aspect-ratio: 1 / 1.44;
        height: unset;
        border-width: 0.7vw;
        border-radius: 1.7vw;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 0.15vw 0.3vw, rgba(0, 0, 0, 0.07) 0px 0.3vw 0.6vw, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 1.2vw 2.4vw, rgba(0, 0, 0, 0.07) 0px 2.4vw 4.8vw, rgba(0, 0, 0, 0.07) 0px 4.8vw 9.6vw;
    }

    
    .cardTitle{
        font-size: 2.4vw;
    }
    
    .cardShortDesc{
        font-size: 1.75vw;
        margin-top: -0.4vw;
    }
    
    .cardLongDesc{
        font-size: 1.82vw;
        padding-top: 0.9vw;
    }
    
    .stepper{
        bottom: 1.5vw;
    }
    
    .dot{
        width: 1.3vw;
        height: 1.3vw;
        margin-right: 1.1vw;
    }

    .dot.active{
        width: 3.6vw;
        border-radius: 1vh;
    }
    
    .itentionWrapper{
        right: 2%;
        bottom: 2vw;;
    }
    
    .intentionButton{
        width: 6vw;
        height: 6vw;
        font-size: 2.7vw;
    }

    .chatWrapper{
        right: -1.2vw;
        bottom: -0.52vw;
    }
    
    .chatButton{
        width: 6.2vw;
        min-width: 7.2vw;
        height: 6.2vw;
        max-height: 6.2vw;
        font-size: 3.2vw;
    }

    .chatWrapper:hover .tooltip{
        padding: 0.72vw 2.2vw;
        font-size: 1.62vw;
        border-radius: 1.82vw;
        right: -1.52vw;
        bottom: 8.1vw;
        letter-spacing: 0.12vw;
    
    }
}

@keyframes expand-button{
    from {transform: scale(1,1);}
    to {transform: scale(1.1,1.1)}
}