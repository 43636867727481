.wrapper{
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 45px 0;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    z-index: 10;
    box-sizing: border-box;
    min-width: 280px;
}

.box{
    background-color: #252424;
    width: 75%;
    min-width: 410px;
    max-width: 510px;
    border-radius: 8px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    max-height: calc(100vh - 50px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.box header{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 8.5px 14px;
    padding-right: 6px;
    border-bottom:1.1px solid #484848;
}

.closeIcon{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: #d9d5d5;
    cursor: pointer;
    border-radius: 50%;
}


.closeIcon:hover{
    color: #fff;
    background-color: #424244;
}

.title{
    font-family: "Outfit", sans-serif;
    font-size: 16.5px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.box main{
    padding: 12px 12px 20px;
    overflow-y: auto;
}

.loadingArea{
    padding: 30px 0;
    overflow-y: auto;
}

.loadingSpinner{
    border: 3.8px solid #676667;
    border-radius: 50%;
    border-top: 3.8px solid transparent;
    width: 60px;
    height: 60px;
    -webkit-animation: rotation 1.6s linear infinite;
    animation: rotation 1.6s linear infinite;;
    box-sizing: border-box;
    margin: 0 auto;
}

.box footer{
    padding: 15px;
    box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.box button[type="submit"]{
    width: 100%;
    border: 0;
    outline: 0;
    background-color: #ea4e4e;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    height: 37px;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
}
.box button[type="submit"]:hover{
    background-color: #dd3a3a;
}

.box button[type="submit"]:disabled{
    opacity: 0.7;
    pointer-events: none;
    cursor: unset;
    z-index: 0;
    position: relative;
    color: #fff;
}

.loadingBtn{
    width: 100%;
    background-color: #ea4e4e;
    height: 37px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner{
    width: 24px;
    height: 24px;
    border: 2.4px solid #f6cece;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.text{
    font-family: 'DM Sans', sans-serif;
    font-size: 13.4px;
    line-height: 1.5;
    color: #ccc8c8;
    margin-bottom: 13px;
}

.text span{
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.1px;
    text-decoration: underline;
}

.date{
    font-size: 12.7px;
}

.box label{
    font-size: 12.3px;
    color: #aeaaaa;
    margin-bottom: 4.5px;
    display: block;
    font-family: 'DM Sans', sans-serif;
}

.box input{
    height: 39px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    outline: 0;
    border: 1px solid #565557;
    padding: 0 8px;
    background-color: inherit;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
}

.giftIcon{
    color:#ebbb68;
    margin: 2px 4px 0;
}

.currencyIcon{
    color:#ebbb68;
    margin: 4px 4px 0;
    margin-right: 2px;
    font-size: 18px;
}

@media (min-height: 780px){
    .closeIcon{
        width: 31px;
        height: 31px;
        font-size: 22px;
    }
    
    .title{
        font-size: 17.3px;
    }

    .giftIcon{
        font-size: 17.3px;
    }

    .currencyIcon{
        font-size: 17.2px;
    }
    
    .box button[type="submit"]{
        font-size: 14.8px;
        height: 39px;
        border-radius: 6px;
    }

    .loadingBtn{
        height: 39px;
        border-radius: 6px;
    }

    .text{
        font-size: 14.2px;
    }

    .date{
        font-size: 13.4px;
    }
    
    .box label{
        font-size: 13.1px;
    }
    
    .box input{
        height: 41px;
        font-size: 12.8px;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

@media (max-width: 520px){
    .wrapper{
        display: block;
        padding: 0;
        background-color: #252424;
    }

    .box{
        min-width: unset;
        max-width: 100%;
        overflow-x: hidden;
        box-shadow: unset;
        border-radius: unset;
        width: 100%;
        height: 100%;
        max-height: 100%;
    }

    .box footer{
        box-shadow: unset;
        padding-top: 0;
    }
}

@media (max-width: 350px){
    .closeIcon{
        width: 28px;
        height: 28px;
        font-size: 19px;
    }
    
    .title{
        font-size: 15.8px;
    }

    .giftIcon{
        font-size: 15.8px;
    }

    .currencyIcon{
        font-size: 15px;
    }
    
    .box button[type="submit"]{
        font-size: 13.3px;
        height: 36px;
        border-radius: 4.5px;
    }

    .loadingBtn{
        height: 36px;
        border-radius: 4.5px;
    }

    .text{
        font-size: 12.7px;
    }

    .date{
        font-size: 12px;
    }
    
    .box label{
        font-size: 11.6px;
    }
    
    .box input{
        height: 38px;
        font-size: 11.3px;
    }

    .box main{
        padding: 12px 8px 15px;
    }

    .box footer{
        padding: 0 8px 10px;
    }

    .box header{
        padding: 8.5px 7px;
        padding-right: 3px;
    }
}

@media (max-width: 304px){
    .giftIcon, .currencyIcon{
        display: none;
    }

    .title span{
        display: none;
    }
}