.box{
    aspect-ratio: 1 / 1.23;
    height: 28vh;
    background-size: 100% 100%;
    border-radius: 7%;
    color: #000;
}

.box.min{
    height: 26vh;
}

.box:not(.completed){
    background-image: url("../media/stepCard.png");
}

.box.completed{
    cursor: pointer;
}

.box.completed:hover{
    transform: scale(1.3,1.3);
    z-index:2500
}

.box.completed:hover .innerContent{
    transform: rotateY(180deg);
}

.contentWrapper{
    width: 100%;
    height: 100%;
    position: relative;
}

.content{
    top: 9%;
    position: absolute;
    width: 100%;
    height: 100%;
}

.boxTitle{
    height: 13%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'IM Fell English SC', serif;
    font-size: 2.3vh;
}

.box.min .boxTitle{
    font-size: 2.2vh;
}

.penWrapper{
    margin: 21% auto 0;
    width: 40%;
    height: 30%; 
    padding-left: 10%;
    box-sizing: border-box;
}

.penWrapper img{
    width: 100%;
    height: 100%;
}

.intention.active .penWrapper{
    animation:  expand 3s linear 2s infinite alternate;
}

.box.active{
    box-shadow: 0 0 15px 3.5px rgb(255,255,255,0.4);
    box-sizing: border-box; 
    animation:  expand 3s linear 2s infinite alternate;
}


.container{
    position:relative;
    perspective: 800px;
    width: 100%;
    height: 100%;
}

.innerContent{
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition:transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.front{
    display: flex;
    align-items: center;
    justify-content: center;
}

.front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-size: 100% 100%;
}

.front{
    background-image: url("../media/stepCard.png") ;
}


.back {
    transform: rotateY(180deg);
    background-image: url("../media/intentionCard.png") ;
}

.intention{
    margin: 24% auto 0;
    padding: 0 17%;
    text-align: center;
    font-family: 'Macondo', cursive;
    font-size: 1.55vh;
    font-weight: 600;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden
}

.box.min .intention{
    font-size: 1.42vh;
}

.back .intention{
    margin: 9% auto 0;
    font-size: 1.34vh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden

}

@keyframes expand{
    from {transform: scale(1,1);}
    to {transform: scale(1.1,1.1)}
}

@media (max-width: 125vh){
    .box{
        right: 6vw
    }
}

@media (not (orientation:landscape)) and (not (height:100vw)){
    .box{
        height: 25vw;
        top: 11.5vw;
        right: 5.5vw;
        aspect-ratio: 1 / 1.3;
    }

    .boxTitle{
        font-size: 2.2vw;
    }

    .intention{
        font-size: 1.5vw;
    }
    
    
    .back .intention{
        font-size: 1.3vw;
    }
}