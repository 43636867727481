.menu{
    position: absolute;
    box-shadow: 0 0 0 1px #424244;
    background-color: #1e1f21;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 7px;
    top: 40px;
    left: -80.5px;
    width: 230px;
    color: #fff;
    max-height: calc(100vh - 130px);
    z-index: 3;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.header{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 7px;
}

.headerTitle{
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 600;
}

.headerIcon{
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-right: 4px;
}
.footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 11px 7px;
}

.content{
    border-top: 0.8px solid #4d4b4b;
    border-bottom: 0.8px solid #4d4b4b;
    padding: 14px 12px;
    overflow-y: auto;
}

.label{
    font-size: 13.2px;
    font-family: 'Lato', sans-serif;
    color: #ccc8c8;
    margin-bottom: 6px;
}

.selectorBox{
    position: relative;
}

.selectorInput{
    display: flex;
    align-items: center;
    border: 1.2px solid #565557;
    width: 100%;
    padding: 0 8px;
    height: 34.5px;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
}

.valueBox{
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.selectorInput:hover, .selectorInput.active{
    background-color: #3d3e3f;
}

.value{
    font-family: 'Poppins', sans-serif;
    font-size: 12.2px;
    margin-left: 4.5px;
}

.icon{
    display: flex;
    align-items: center;
    margin-top: -3px;
    font-size: 14px;
}

.content .section:first-of-type{
    margin-bottom: 10px;
}

.checkCircle{
    width: 15px;
    min-width: 15px;
    height: 15px;
    border: 1.7px solid #656566;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3.5px;
    cursor: pointer;
}

.checkCircle.checked{
    border-color: #f5e3c5;
    border-width: 1.4px;
}

.checkCircle:not(.checked):hover{
    background-color: #3d3e3f;
}

.innerCircle{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #f5e3c5;;
}

.sectionContent{
    display: flex;
    align-items: center;
}

.orderItem{
    display: flex;
    align-items: center;
}

.sectionContent .orderItem:first-of-type{
    margin-right: 14px;
}

.orderText{
    font-family: 'Poppins', sans-serif;
    font-size: 12.1px;
}

.saveBtn{
    height: 29px;
    padding: 0 12px;
    border: 0;
    outline: 0;
    background-color: #f5e3c5;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 11.8px;
    border-radius: 7px;
    font-weight: 600;
    cursor: pointer;
}

.saveBtn:hover{
    background-color: #e8d4b2;
}

.saveBtn:disabled{
    opacity: 0.6;
    pointer-events: none;
}

.cancelBtn{
    height: 29px;
    padding: 0 12px;
    border: 0;
    outline: 0;
    background-color: #726f6f;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 11.8px;
    border-radius: 7px;
    font-weight: 600;
    margin-right: 10px;
    cursor: pointer;
}

.cancelBtn:hover{
    background-color: #676464;
}

.fieldMenu{
    position: fixed;
    box-shadow: 0 0 0 1px #424244;
    background-color: #292a2d;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 4px;
    padding: 3px 0;
    box-sizing: border-box;
    z-index: 3;
    overflow-y: auto;
}


.fieldItem{
    display: flex;
    align-items: center;
    padding: 4px 12px;
    cursor: pointer;
    margin-bottom: 1px;
}

.fieldItem:hover{
    background-color: #514e4e;
}

.fieldItem.selected{
    color: #f5e3c5;
    background-color: #514e4e;
    font-weight: 600;
}

.fieldMenu .fieldItem:last-of-type{
    margin-bottom: 0;
}

.fieldName{
    font-family: 'Poppins', sans-serif;
    font-size: 11.5px;
    margin-left: 3px;
}

.fieldIcon{
    display: flex;
    align-items: center;
    font-size: 14px;
}

@media (max-width: 750px){
    .menu{
        left: unset;
        right: 0;
        max-height: calc(100vh - 114px);
    }
}