.wrapper{
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 45px 0;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    z-index: 10;
    box-sizing: border-box;
    min-width: 280px;
}

.box{
    background-color: #252424;
    width: 75%;
    min-width: 410px;
    max-width: 440px;
    border-radius: 5px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    max-height: calc(100vh - 50px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.box header{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 8.5px 14px;
    padding-right: 6px;
    border-bottom:1.1px solid #484848;
}

.modalInfo{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.giftIcon{
    width: 47px;
    height: 47px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #e0c8a0;
    background-color: #4e4638;
    margin-right: 8px;
}

.closeIcon{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: #d9d5d5;
    cursor: pointer;
    border-radius: 50%;
}


.closeIcon:hover{
    color: #fff;
    background-color: #424244;
}

.title{
    font-family: "Outfit", sans-serif;
    font-size: 18.5px;
}

.editTitle{
    font-family: "Outfit", sans-serif;
    font-size: 18.5px;
    flex-grow: 1;
    margin-left: 6px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.editIcon{
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: #d9d5d5;
    background-color: #424244;
    border-radius: 6px;
    margin-top: 1.5px;
}

.subtitle{
    font-family: "Poppins", sans-serif;
    font-size: 11.5px;
    color: #9b9999;
    margin-bottom: -1.5px;
}

.length{
    position: absolute;
    right: 7px;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    color: #bebbbb;
    top: 0;
    height: 35px;
    display: flex;
    align-items: center;
}

.box main{
    padding: 12px 12px 20px;
    overflow-y: auto;
}

.loadingArea{
    padding: 30px 0;
    overflow-y: auto;
}

.loadingSpinner{
    border: 3.8px solid #676667;
    border-radius: 50%;
    border-top: 3.8px solid transparent;
    width: 60px;
    height: 60px;
    -webkit-animation: rotation 1.6s linear infinite;
    animation: rotation 1.6s linear infinite;;
    box-sizing: border-box;
    margin: 0 auto;
}

.box label{
    color: #d4d0d0;
    display: block;
    font-family: 'DM Sans', sans-serif;
    font-size: 11.35px;
}

.sectionTitle{
    font-family: "Outfit", sans-serif;
    font-size: 16.5px;
    color: #f7f7f7;
    margin-bottom: 6px;
}


.inputBox{
    height: 35px;
    position: relative;
    width: 70%;
}

.inputBox input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    background: rgba(255, 255, 255, 0);
    border: 1.65px solid #515050;
    padding: 0 8px;
    box-sizing: border-box;
    font-size: 11.2px;
    font-family: 'Poppins', sans-serif;
    border-radius: 5px;
    background-color:rgba(255, 255, 255, 0.07);
}

.inputBox.code{
    position: relative;
    outline: 0;
    border: 0;
}

.shake{
    animation: shake 0.3s ease-in-out;
}

.inputBox.code input{
    padding-right: 20px;
}

.placeholder{
    font-size: 11.2px;
    font-family: 'Poppins', sans-serif; 
    color: #a9a6a6;
}

.errorBox{
    font-size: 11.5px;
    font-family: 'Poppins', sans-serif; 
    color: #d53d1f;
}

.inputBox.num{
    width: 50%;
    max-width: 100px;
}

.inputBox.exchange{
    width: 60%;
    max-width: 160px;
}

.inputBox.num input{
    padding-right: 35px;
}

.diamondIcon{
    width: 30px;
    height: 100%;
    border-left: 1.65px solid #515050;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    border-radius: 0 5px 5px 0;
    top: 0;
    color: #a9a6a6;
}

.inputBox{
    margin-top: 5px;
}

.inputArea{
    margin-bottom: 10px;
}

.instructions{
    font-size: 11.3px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.1px;
    color: #a9a6a6;
}

.dateBtn, .timeBtn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.dateBtn input{
    outline:0;
    border: 0;
    background-color: transparent;
    padding: 3px 1px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-size: 11.2px;
    border-radius: 4px;
    cursor: pointer;
    width: 120px;
    text-align: center;
}

.dateBtn input.year{
    width: 38px;
}

.dateBtn input.month{
    width: 28px;
}

.seperator{
    width: fit-content;
    font-family: 'Lato', sans-serif;
    font-size: 13.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
}

.timeBox .seperator{
    margin: 0 1px;
}

.timeBtn input{
    width: 28px;
    outline:0;
    border: 0;
    background-color: transparent;
    padding: 3px 3.5px;
    text-align: center;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-size: 11.2px;
    border-radius: 4px;
    cursor: pointer;
}

.timeBtn input:focus, .dateBtn input:focus{
    background-color: #e0c8a0;
    color: #000;
}

.timeBtn input:focus::placeholder, .dateBtn input:focus::placeholder{
    color: #454444;
}

.dateWrapper{
    display: flex;
    align-items: center;
}

.dateBox{
    display: flex;
    align-items: center;
    width:fit-content;
    padding: 7px 5px;
    border-radius: 5px;
    background-color:rgba(255, 255, 255, 0.07);
    border: 1.65px solid #515050;
    margin-right: 18px;
    outline: 0;
}

.dateBox.error{
    border-color: #ab2323;
    background-color: #36100a; 
    color:#e23535;
}

.dateBox.error input, .dateBox.error .calendarIcon{
    color:#ef4040;
}

.timeBox{
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 4px 5px;
    border-radius: 5px;
    background-color:rgba(255, 255, 255, 0.07);
    border: 1.65px solid #515050;
}

.calendarIcon, .timeIcon{
    width: 20px;
    height: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a9a6a6;
}

.box .dateSection label{
    margin-bottom: 4px;
}

.dateSection{
    padding-top: 5px;
}

.line{
    margin: 5px 0 10px;
    background-color: #484848;
    height: 1.1px;
}

.countryBtn{
    min-width: 140px;
    width: fit-content;
    padding: 0 10px;
    border-radius: 5px;
    background-color:rgba(255, 255, 255, 0.07);
    border: 1.65px solid #515050;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 35px;
    margin-top: 5px;
    cursor: pointer;
}

.countryBtn:hover{
    transform: scale(1.05,1.05);
}

.worldIcon{
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-right: 4.5px;
    color:#a9a6a6;
}

.box footer{
    padding: 15px;
    box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.box button[type="submit"]{
    width: 100%;
    border: 0;
    outline: 0;
    background-color: #e8d2ad;
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    height: 35px;
    border-radius: 3px;
    text-transform: uppercase;
    cursor: pointer;
}
.box button[type="submit"]:hover{
    background-color: #dfc69b;
}

.box button[type="submit"].saveBtn{
    width: 110px;
    border-radius: 6px;
    text-transform: capitalize;
    font-size: 12.65px;
}

.cancelBtn{
    width: 66px;
    font-family: 'Poppins', sans-serif;
    font-size: 12.65px;
    border-radius: 6px;
    height: 35px;
    outline:0;
    border:0;
    margin-right: 13px;
    background-color: #505053;
    color:#e4e4e4;
    font-weight: 600;
}

.box button[type="submit"]:disabled{
    opacity: 0.7;
    pointer-events: none;
    cursor: unset;
    z-index: 0;
    position: relative;
}

.loadingBtn{
    width: 100%;
    background-color: #e8d2ad;
    height: 35px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingBtn.save{
    width: 110px;
    border-radius: 6px;
}

.spinner{
    width: 24px;
    height: 24px;
    border: 2.4px solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.country{
    font-family: 'Poppins', sans-serif;
    font-size: 11.5px;
    margin-left: 6.5px;
}

.flag{
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

@media (min-height: 780px){
    .title{
        font-size: 19.3px;
    }
    
    .subtitle{
        font-size: 12.3px;
    }

    .closeIcon{
        width: 32px;
        height: 32px;
        font-size: 23px;
    }
    
    .length{
        font-size: 12px;
        height: 36px;
    } 
    
    .box label{
        font-size: 12.35px;
    }    

    .sectionTitle{
        font-size: 17.5px;
    }

    .inputBox input{
        font-size: 12.2px;
    }

    .placeholder{
        font-size: 12.2px;
    }
    
    .errorBox{
        font-size: 12.5px;
    }

    .diamondIcon{
        width: 31px;
        font-size: 16px;
    }
    
    .instructions{
        font-size: 12.3px;
    }

    .dateBtn input{
        font-size: 12.2px;
        cursor: pointer;
        width: 22px;
    }
    
    .dateBtn input.year{
        width: 36px;
    }
    
    .dateBtn input.month{
        width: 26px;
    }
    
    .seperator{
        font-size: 14.5px;
    }

    .timeBtn input{
        width: 30px;
        font-size: 12.2px;
    }

    .calendarIcon, .timeIcon{
        font-size: 15px;
    }

    .countryBtn{
        min-width: 145px;
        height: 36px;
    }
    
    .worldIcon{
        font-size: 15px;
    }
    
    .box button[type="submit"]{
        font-size: 15px;
        height: 36px;
    }

    .country{
        font-size: 12.5px;
    }
    
    .flag{
        height: 16px;
        width: 16px;
    }
}

@media (max-width: 520px){
    .wrapper{
        display: block;
        padding: 0;
        background-color: #252424;
    }

    .box{
        min-width: unset;
        max-width: 100%;
        overflow-x: hidden;
        box-shadow: unset;
        border-radius: unset;
        width: 100%;
        height: 100%;
        max-height: 100%;
    }

    .box main{
        flex-grow: 1;
    }
}

@media (max-width: 350px){
    .box main{
        padding: 8px 8px 15px;
    }

    .instructions{
        font-size: 11px;
    }

    .sectionTitle{
        font-size: 15.7px;
    }

    .box label{
        font-size: 11.1px;
    }

    .box header{
        padding: 8.5px 8px;
        padding-right: 5px;
    }

    .dateBox{
        margin-right: 13.5px;
    }

    .title{
        font-size: 17.5px;
    }

    .giftIcon{
        width: 44px;
        height: 44px;
        font-size: 22px;
    }

    .subtitle{
        font-size: 11px;
    }

}
