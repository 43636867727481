/* .wrapper{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.titleWrapper{
    width: 100%;
    margin-top: -4.9vh;
}

.titleBox{
    width: 18vh;
    height: 10vh;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    box-sizing: border-box;
    margin: 0 auto;
    font-weight: 600;
    padding-top: 2.2vh;
    font-style: italic;
    background-image: url("../media/spreadBanner.png");
    font-family: 'IM Fell English SC', serif;
    font-size: 2.8vh;
}

.title{
    transform: rotate(1deg);
    text-transform: uppercase;
}

.content{
    width: 100%;
    flex-grow: 1;
    background-size: 100% 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    padding: 0 5vh;
}

.options{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
    align-items: center;
}

.spreads{
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 30.16vh;
    align-items: center;
    box-sizing: border-box;
}

.spreadWrapper{
    position: absolute;
    width: 21.5vh;
    height: 29vh;
    cursor: pointer;
    background-size: 100% 100%;
    border-radius: 12px;
    box-sizing: border-box;
}

.spreadBox{
    position:relative;
    perspective: 800px;
    width: 100%;
    height: 100%;
}

.spreadInner{
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition:transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.front{
    display: flex;
    align-items: center;
    justify-content: center;
}

.front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-size: 100% 100%;
    background-image: url("../media/card.png")
}
  
.back {
    transform: rotateY(180deg);
}


.prevArrow, .nextArrow{
    font-size: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    animation:  twitch 3s linear 1s infinite alternate;
    cursor: pointer;
    color: #944d24;
    width: 6.5vh;
    height: 6.5vh;
    border-radius: 50%;
    background-size: 100% 100%;
}

.prevArrow:hover, .nextArrow:hover{
    transform: scale(1.2,1.2);
}

.spreadContent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
}

.spreadName{
    font-family: 'Almendra', serif;
    font-size: 1.45vh;
    font-weight: 600;
    position: absolute;
    bottom: 1.5vh;
    color: #27282a;
    padding: 0 23%;
    height: 18%;
    display: flex;
    align-items: center;
    line-height: 1.1;
}

.spreadName.min{
    font-size: 1.23vh;
}

.spreadDesc{
    font-family: 'Macondo', cursive;
    font-weight: 600;
    font-size: 1.44vh;
    line-height: 1.3;
    letter-spacing: 0.1px;
    position: absolute;
    top: 37%;
    padding: 0 12%;
    height: 32%;
    display: flex;
    align-items: center;
    color: #a2998d;
}


.spreadWrapper.flipping .spreadInner{
    animation: flip 250ms forwards;
    position: fixed;
}

.spreadWrapper.dragging{
    z-index: 100;
    transition: top 0.7s ease-out, left 0.7s ease-out, width 0.35s ease-out 0.35s, height 0.35s ease-out 0.35s
}

.spreadWrapper.active:not(.flipping):not(.sliding) .spreadInner{
    transform: rotateY(180deg);
}

.spreads .spreadWrapper:nth-of-type(3){
    transform: translateX(-30%) scale(.95);
    z-index: 4;
}

.spreads .spreadWrapper.sliding{
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    z-index: 10;
}   

.spreads .spreadWrapper:nth-of-type(3).sliding{
    animation-name: swap3;
    animation-duration:250ms;
}


.spreads .spreadWrapper:nth-of-type(2){
    transform: translateX(-50%) scale(.85);
    z-index: 3;
}

.spreads .spreadWrapper:nth-of-type(2).sliding{
    animation-name: swap2;
    animation-duration:250ms;
    z-index: 10;
}

.spreads .spreadWrapper:nth-of-type(1){
    transform: translateX(-70%) scale(.75);
    z-index: 2;
}

.spreads .spreadWrapper:nth-of-type(1).sliding{
    animation-name: swap1;
    animation-duration:250ms;
    z-index: 10;
}

 .spreadWrapper.active:not(.dragging){
    z-index: 5;
    animation:  expand 3s linear 2s infinite alternate;
}

.spreadWrapper.active:hover{
    box-shadow: 0 0 25px 7.5px rgb(255,255,255,0.4);
    box-sizing: border-box;
}

.spreads .spreadWrapper:nth-of-type(5){
    transform: translateX(30%) scale(.95);
    z-index: 4;
}

.spreads .spreadWrapper:nth-of-type(5).sliding{
    animation-name: swap5;
    animation-duration:250ms;
    z-index: 10;
}

.spreads .spreadWrapper:nth-of-type(6){
    transform: translateX(50%) scale(.85);
    z-index: 3;
}

.spreads .spreadWrapper:nth-of-type(6).sliding{
    animation-name: swap6;
    animation-duration:250ms;
    z-index: 10;
}

.spreads .spreadWrapper:nth-of-type(7){
    transform: translateX(70%) scale(.75);
    z-index: 2;
}

.spreads .spreadWrapper:nth-of-type(7).sliding{
    animation-name: swap7;
    animation-duration:250ms;
    z-index: 10;
}


.spreadWrapper:not(.active):not(.sliding):hover{
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    filter: blur(0);
    -webkit-filter: blur(0);
}

.spreads .spreadWrapper:nth-of-type(3):not(.active):not(.sliding):hover{
    animation: rise3 0.1s forwards;
}

.spreads .spreadWrapper:nth-of-type(5):not(.active):not(.sliding):hover{
    animation: rise5 0.1s forwards;
}

.spreads .spreadWrapper:nth-of-type(2):not(.active):not(.sliding):hover{
    animation:  rise2 0.1s forwards;
}

.spreads .spreadWrapper:nth-of-type(6):not(.active):not(.sliding):hover{
    animation:  rise6 0.1s forwards;
}

.spreads .spreadWrapper:nth-of-type(1):not(.active):not(.sliding):hover{
    animation:  rise1 0.1s forwards;
}

.spreads .spreadWrapper:nth-of-type(7):not(.active):not(.sliding):hover{
    animation:  rise7 0.1s forwards;
}


@keyframes rise1{
    0%   {transform:translateX(-70%) scale(.75) translateY(0px);}
    25%  {transform:translateX(-70%) scale(.75) translateY(-0.7vh);}
    50%  {transform:translateX(-70%) scale(.75) translateY(-1.625vh);}
    75%  {transform:translateX(-70%) scale(.75) translateY(-2.245vh);}
    100% {transform:translateX(-70%) scale(.75) translateY(-3.375vh);}
}

@keyframes rise2{
    0%   {transform:translateX(-50%) scale(.85) translateY(0px);}
    25%  {transform:translateX(-50%) scale(.85) translateY(-0.3vh);}
    50%  {transform:translateX(-50%) scale(.85) translateY(-1.025vh);}
    75%  {transform:translateX(-50%) scale(.85) translateY(-1.745vh);}
    100% {transform:translateX(-50%) scale(.85) translateY(-2.875vh);}
}

@keyframes rise3{
    0%   {transform:translateX(-30%) scale(.95) translateY(0px);}
    25%  {transform:translateX(-30%) scale(.95) translateY(-0.05vh);}
    50%  {transform:translateX(-30%) scale(.95) translateY(-0.425vh);}
    75%  {transform:translateX(-30%) scale(.95) translateY(0.745vh);}
    100% {transform:translateX(-30%) scale(.95) translateY(-1.675vh);}
}

@keyframes rise5{
    0%   {transform:translateX(30%) scale(.95) translateY(0px);}
    25%  {transform:translateX(30%) scale(.95) translateY(-0.05vh);}
    50%  {transform:translateX(30%) scale(.95) translateY(-0.425vh);}
    75%  {transform:translateX(30%) scale(.95) translateY(0.745vh);}
    100% {transform:translateX(30%) scale(.95) translateY(-1.675vh);}
}

@keyframes rise6{
    0%   {transform:translateX(50%) scale(.85) translateY(0px);}
    25%  {transform:translateX(50%) scale(.85) translateY(-0.75vw);}
    50%  {transform:translateX(50%) scale(.85) translateY(-1.025vh);}
    75%  {transform:translateX(50%) scale(.85) translateY(-1.745vh);}
    100% {transform:translateX(50%) scale(.85) translateY(-2.875vh);}
}


@keyframes rise7{
    0%   {transform:translateX(70%) scale(.75) translateY(0px);}
    25%  {transform:translateX(70%) scale(.75) translateY(-0.7vh);}
    50%  {transform:translateX(70%) scale(.75) translateY(-1.625vh);}
    75%  {transform:translateX(70%) scale(.75) translateY(-2.245vh);}
    100% {transform:translateX(70%) scale(.75) translateY(-3.375vh);}
}



@keyframes expand{
    from {transform: scale(1,1);}
    to {transform: scale(1.04,1.04)}
}


@keyframes twitch{
    from {background-color: rgba(255,255,255,0.1);}
    to {background-color: rgba(255,255,255,0.35);}
}

@keyframes swap1{
    0%   {transform: translateX(-30%) scale(.9);}
    25%  {transform: translateX(-25%) scale(.925)}
    50%  {transform: translateX(-20%) scale(.95)}
    75%  {transform: translateX(-15%) scale(.975)}
    100% {transform: translateX(-10%) scale(0.98)}
}

@keyframes swap2{
    0%   {transform: translateX(-50%) scale(.8)}
    25%  {transform: translateX(-40%) scale(.85)}
    50%  {transform: translateX(-30%) scale(.9)}
    75%  {transform: translateX(-20%) scale(.95)}
    100% {transform: translateX(-10%) scale(1)}
}

@keyframes swap3{
    0%   {transform: translateX(-70%) scale(.7)}
    25%  {transform: translateX(-55%) scale(.7)}
    50%  {transform: translateX(-40%) scale(.8)}
    75%  {transform: translateX(-25%) scale(.9)}
    100% {transform: translateX(-5%) scale(1)}
}

@keyframes swap5{
    0%   {transform: translateX(30%) scale(.9);}
    25%  {transform: translateX(25%) scale(.925)}
    50%  {transform: translateX(20%) scale(.95)}
    75%  {transform: translateX(15%) scale(.975)}
    100% {transform: translateX(0%) scale(1)}
}

@keyframes swap6{
    0%   {transform: translateX(50%) scale(.8)}
    25%  {transform: translateX(40%) scale(.85)}
    50%  {transform: translateX(30%) scale(.9)}
    75%  {transform: translateX(20%) scale(.95)}
    100% {transform: translateX(10%) scale(1)}
}

@keyframes swap7{
    0%   {transform: translateX(70%) scale(.7)}
    25%  {transform: translateX(55%) scale(.7)}
    50%  {transform: translateX(40%) scale(.8)}
    75%  {transform: translateX(25%) scale(.9)}
    100% {transform: translateX(5%) scale(1)}
}


@keyframes flip{
    from{
        transform: none;
    }
    to{
        transform: rotateY(180deg);

    }
}

@media (not (orientation:landscape)) and (not (height:100vw)){

    .spreads .spreadWrapper:nth-of-type(7), .spreads .spreadWrapper:first-of-type{
        display: none;
    }

    .titleWrapper{
        margin-top: -5.2vw;
    }
    
    .titleBox{
        width: 20vw;
        height: 10vw;
        font-size: 3vw;
        padding-top: 2vw;
    }
    
    .content{
        padding: 0 3vw;
    }

    .spreads{
        height: 32.24vw;
    }
    
    .spreadWrapper{
        width: 23.5vw;
        height: 31vw;
        border-radius: 1.2vw;
    }

    .prevArrow, .nextArrow{
        font-size: 3.2vw;
        width: 7vw;
        height: 7vw;
    }
    
    .spreadContent{
        padding: 0 1.8vw;
    }
    
    .spreadName{
        font-size: 1.56vw;
        bottom: 1.4vw;
    }
    
    .spreadName.min{
        font-size: 1.42vw;
    }
    
    .spreadDesc{
        font-size: 1.55vw;
    }

    @keyframes rise2{
        0%   {transform:translateX(-50%) scale(.85) translateY(0px);}
        25%  {transform:translateX(-50%) scale(.85) translateY(-0.5vw);}
        50%  {transform:translateX(-50%) scale(.85) translateY(-1.225vw);}
        75%  {transform:translateX(-50%) scale(.85) translateY(-1.945vw);}
        100% {transform:translateX(-50%) scale(.85) translateY(-3.075vw);}
    }
    
    @keyframes rise3{
        0%   {transform:translateX(-30%) scale(.95) translateY(0px);}
        25%  {transform:translateX(-30%) scale(.95) translateY(-0.25vw);}
        50%  {transform:translateX(-30%) scale(.95) translateY(-0.625vw);}
        75%  {transform:translateX(-30%) scale(.95) translateY(0.945vw);}
        100% {transform:translateX(-30%) scale(.95) translateY(-1.875vw);}
    }
    
    @keyframes rise5{
        0%   {transform:translateX(30%) scale(.95) translateY(0px);}
        25%  {transform:translateX(30%) scale(.95) translateY(-0.25vw);}
        50%  {transform:translateX(30%) scale(.95) translateY(-0.625vw);}
        75%  {transform:translateX(30%) scale(.95) translateY(0.945vw);}
        100% {transform:translateX(30%) scale(.95) translateY(-1.875vw);}
    }
    
    @keyframes rise6{
        0%   {transform:translateX(50%) scale(.85) translateY(0px);}
        25%  {transform:translateX(50%) scale(.85) translateY(-0.95vw);}
        50%  {transform:translateX(50%) scale(.85) translateY(-1.225vw);}
        75%  {transform:translateX(50%) scale(.85) translateY(-1.945vw);}
        100% {transform:translateX(50%) scale(.85) translateY(-3.075vw);}
    }
    
}

@keyframes flip{
    from{
        transform: none;
    }
    to{
        transform: rotateY(180deg);

    }
}

 */


 .wrapper{
    position: fixed;
    top: 0;
    left: calc((100% - 120vh) / 2);
    padding: 0.25vh 8.3vh;
    width: 120vh;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100vh;
    scrollbar-width: none;
    z-index: 1000;
    background-color: #000;
}

.box{
    width: 100%;
    height: 100%;
    background-image: url('../media/newBackground.png');
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    color: #fff;
}

.gameTitle{
    position: absolute;
    top: 4.5vh;
    left: 15%;
    width: 11.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuBtn{
    position: absolute;
    top: 4.5vh;
    right: 13%;
    width: 13.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; 
}

.gameTitle, .menuBtn{
    font-family: 'Alegreya', serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-transform: uppercase;
    color: #e4d9c4;
    height: 5.5vh;
}

.menuBtn:hover{
    transform: scale(1.2,1.2);
}


.wrapper::-webkit-scrollbar {
    display: none;
}


.container{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 102.8vh;
    color: #000;
    padding-top: 10.2vh;
}

.wrapper.selecting{
    background-color: rgba(0,0,0,0.2);
}

.spreadWrapper{
    margin-bottom: 1vh;
    margin-right: 2.6vh;
    cursor: pointer;
    transition: all 0.5s ease;
}

.wrapper .spreadWrapper:nth-of-type(4n){
    margin-right: 0;
}

.spreadBox{
    height: 29vh;
    width: 23.57vh;
    min-width: 23.57vh;
    /* transition: 0.6s; */
    transform-style: preserve-3d;
    position: relative;
    transition: transform 0.9s, opacity 1s ease;
    opacity: 1;
}

/* .spreadBox.active{
    transform: scale(1.5) translate(calc(125vh / 2 - (1.5 * 23.75 / 2)),calc(90vh / 2 - 2 * 29vh / 2));
} */


.box.selecting .spreadWrapper:not(.active){
    opacity: 0.2;
}

.spreadWrapper.active{
    position: absolute;
    /* transform: scale(1.5); */
    pointer-events: none;
    transition: top 2s, left 2s,;
    margin-right: 0;
    margin-bottom: 0;
    z-index: 1000;
}

.spreadBox.expanding{
    transform: scale(2);
}

.spreadBox.minimizing{
    transform: scale(1);
}

/* .spreadWrapper.active .spreadBox{
    width: 100%;
    height: 100%;
} */

.spreadWrapper.fade{
    pointer-events: none;
}

.spreadWrapper.fade .spreadBox{
    opacity: 0;
}

.front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
  }
  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }
  
  .back {
    transform: rotateY(180deg);
  }
  
  .spreadWrapper:hover .spreadBox:not(.active){
    transform: rotateY(180deg);
    transition: transform 0.5s;
  }  

  .title{
    margin: 26% auto 0;
    padding: 0 16%;
    text-align: center;
    font-family: 'Eczar', serif;
    font-size: 1.85vh;
    font-weight: 600;
    line-height: 1.3;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.desc{
    margin: 21% auto 0;
    padding: 0 12.8%;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center; 
    font-family: 'Macondo', cursive;
    font-weight: 600;
    font-size: 1.7vh;
    line-height: 1.3;
    color: #a2998d;
    text-align: center;
}


.arrow{
    width: 8.5vh;
    height: 8.5vh;
    background-size: 100% 100%;
    border-radius: 50%;
    position: fixed;
    right: calc((100% - 125vh) / 2 + 3vh);
    bottom: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #944d24;
    font-size: 3.6vh;
    animation:  expand 3s linear 0.3s infinite alternate;
    box-shadow: 0 0 1.85vw 0.43vw rgb(255,255,255,0.42); 
}

.spreadRep{
    height: 13%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'IM Fell English SC', serif;
    font-size: 2.85vh;
    margin-top: 10%;
    font-weight: 600;
}

@keyframes expand{
    from {transform: scale(1,1);}
    to {transform: scale(1.1,1.1)}
}
