.wrapper{
    position: fixed;
    top: 0;
    left: calc((100% - 120vh)/2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120vh;
    height: 100vh;
}

.box{
    background: rgba(255, 255, 255, 0.1);  /* Semi-transparent background */
    border-radius: 10px;  /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);  /* Subtle shadow */
    backdrop-filter: blur(10px);  /* Blurred background */
    /* border: 1px solid rgba(255, 255, 255, 0.15); */
    padding: 4vh;
    width: 95vh;
    height: 55vh;
    display: flex;
    flex-direction: column;
}

.categories{
    display: flex;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    justify-content: center;
    perspective: 1250px;
}

.title{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55vh;
    height: 12vh;
    background-size: 100% 100%;
    color: #000;
    margin-left: calc((100% - 55vh) / 2);
    margin-top: -11vh;
    font-family: 'Eczar', serif;
    font-size: 3.3vh;
    font-weight: 600;
}


.selectionWrapper{
    width:40%;
    color: #fff;
    border-radius: 0.85vh;
    border: 0.5px solid #363535;
    transform: rotateX(8deg);
    perspective: 1050px;
    cursor: pointer;
    height: 47vh;
    cursor: pointer;
    transition: all 0.5s ease;
}

.selectionBox{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .selectionWrapper:hover .selectionBox{
    transform: rotateY(180deg);
  }


/* .selectionBox:hover{
    transform: rotateX(10deg) translateY(2%) scale(1.02);
    transition: .5s all ease;
} */

.categories .selectionWrapper:first-of-type{
    margin-right: 6vh;
}

.imageBox{
    background-size: 100% 100%;
    aspect-ratio: 1.05 / 1;
    width: 100%;
    border-radius: 0.85vh 0.85vh 0 0
}

.btnWrapper{
    padding: 2.15vh;
    background-color: #252424;
    border-radius: 0 0 0.85vh 0.85vh;
}

.btn:hover{
    transform: scale(1.05,1.05);
    background-color: #454545;
}

.btn{
    text-align: center;
    font-family: 'Eczar', serif;
    font-family: 'Caudex', serif;
    font-size: 2.2vh;
    color: #F2E6D0;
    font-weight: 600;
    padding: 1.5vh;
    border-radius:  0.85vh;
    border: 0.2vh solid #a29c91;
}

.front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }
  
  .back {
    transform: rotateY(180deg);
    padding: 2vh 2vh 0;
    color: #F2E6D0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

.desc{
    font-family: 'Almendra', serif;
    text-align: left;
    font-size: 2.1vh;
    line-height: 1.6;
}

.type{
    font-family: 'Caudex', serif;
    font-size: 3.45vh;
    font-weight: 600;
}

.descTitle{
    font-family: 'IM Fell English SC', serif;
    font-size: 2.5vh;
    text-decoration: underline double;
    padding-left: 7%;
    margin-top: 6.5%;
    margin-bottom: -2%;
}
/* .selectionWrapper:hover .selectionBox{
    transform: rotateY(180deg);
    transition: transform 0.5s;
  }   */