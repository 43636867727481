.wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #22272b;
    padding: 30px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color-scheme: dark;
    min-width: 280px;
    overflow-x:hidden;
}

.box{
    width: 100%;
    min-height: 73vh;
    max-width: 1100px;
    display: flex;
    align-items: stretch;
    box-shadow: rgba(112, 128, 144, 0.05) 0px 54px 55px, rgba(112, 128, 144, 0.12) 0px -12px 30px, rgba(112, 128, 144, 0.12) 0px 4px 6px, rgba(112, 128, 144, 0.17) 0px 12px 13px, rgba(112, 128, 144, 0.09) 0px -3px 5px;
    border-radius: 14px;
    background-color: #161616;
}

.leftSection .appTitle{
    display: none;
}

.rightSection{
    width: calc(49% - 30px);
    border-radius:16px;
    background-color: #ebd6b1;
    margin: 15px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}

.imageSection{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
}

.imageSection img{
    display: block;
    width: 82%;
    aspect-ratio: 391 / 479;
}


.leftSection{
    border-radius: 0 14px 14px 0px;
    color: #fff;
    width: 51%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form{
    padding: 60px 40px;
    min-height: 545px;
    box-sizing: border-box;
}

.title{
    font-family: "Sora", sans-serif;
    font-size: 36px;
    letter-spacing: 0.1px;
    font-weight: 600; 
    color: #ebd6b1;
    line-height: 1.1;
}

.instructions{
    font-family: 'Poppins', sans-serif;
    font-size: 13.1px;
    color: #d7bf96;
    letter-spacing: 0.2px;
    margin-top: 13px;
}

.inputContainer{
    margin-top: 26px;
}

.inputContainer label{
    font-size: 13.6px;
    color: #c8c3c3;
    margin-bottom: 4px;
    display: block;
    font-family: 'DM Sans', sans-serif;
}

.inputBox{
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    height: 47px;
}

.inputBox input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    border: 0;
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 12px 0 30px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    border-radius: 7px;
}

.inputBox input::placeholder{
    color: #8c8989;
}

.inputBox.error input{
    border: 1.6px solid #e23535;
    color: #d53d1f;
}

.errorBox{
    font-family: 'Poppins', sans-serif;
    font-size: 12.7px; 
    color: #e23535;
    margin-left: 1.5px;
    margin-top: 1px;
}

.errorBanner{
    background-color: #d53d1f;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10.5px 8px;
    margin: 21px 0 -15px;
    position: relative;
}

.successBanner{
    background-color: #0c923f;
    color: #d9f6e5;
    border-radius: 7px;
    padding: 9px 8px;
    box-sizing: border-box;
    margin: 21px 0 -15px; 
    position: relative;
    display: flex;
}

.receiver{
    color: #fff;
}

.resendBtn{
    color: #fff;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0.2px;
}

.resendBtn:hover{
    font-weight: 400;
}

.errorIcon{
    display: flex;
    align-items: center;
    font-size: 17px;
    margin-top: -1px;
    margin-right: 3px;
}

.successIcon{
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 17px;
    left: 8px;
}
.errorText{
    font-size: 13.2px;
    font-family: 'Poppins', sans-serif;
}

.successText{
    font-size: 12.2px;
    font-family: 'Poppins', sans-serif;
    text-indent: 19.5px;
}

.emailIcon{
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 18px;
    position: absolute;
    left: 8px;
    color: #dedbdb;
}

.emailIcon.placeholder{
    color: #a2a1a1;
}

.warningIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 18px;
    width: 18px;
    position: absolute;
    left: 8px;
    color: #d53d1f;
}

.submitBtn{
    height: 46px;
    border: 0;
    outline: 0;
    background-color: #e8d2ad;
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 16.5px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.submitBtn:disabled{
    opacity: 0.8;
    pointer-events: none;
    cursor: unset;
    z-index: 0;
    position: relative;
}

.loadingBtn{
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8d2ad;
    border-radius: 5px;
    margin-top: 25px;
}

.loader{
    border: 3px solid #000;
    border-radius: 50%;
    border-top: 3px solid transparent;
    width: 31px;
    height: 31px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.linkWrapper{
    margin: 10px auto 0;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    color: #c8c3c3;
    text-align: center;
}

.linkWrapper a{
    font-weight: bold;
    color: #d7bf96;
    font-size: 12.8px;
    margin-left: 1px;
    text-decoration: none;
}

.linkWrapper a:hover{
    text-decoration: underline;
}

.rightSection .appTitle{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: -14px;
}

.logo{
    width: 33px;
    height: 33px;
}

.logo img{
    width: 100%;
    height: 100%;
}

.name{
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-left: 4.5px;
    font-family:'Parisienne', cursive;
    font-size: 27px;
    color: #000;
    font-weight: 600;
}

.leftSection .imageSection{
    display: none;
}

@media (max-width: 1060px){
    .rightSection{
        display: none;
    }

    .leftSection{
        width: 100%;
        display: block;
    }

    .box{
        max-width: 520px;
        width: 100%;
        min-height: unset;
        box-sizing: border-box;
    }

    .leftSection .appTitle{
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-left: 15px;
    }

    .logo{
        width: 25px;
        height: 25px;
    }

    .logo img{
        width: 100%;
        height: 100%;
    }

    .name{
        font-size: 25px;
        color: #d2b98e;
        font-weight: 600;
    }

    .form{
        padding: 5px 28px 40px;
        min-height: unset;
    }
    
    .title{
        font-size: 28px;
        line-height: 1;
        text-align: center;
    }
    
    .instructions{
        font-size: 12.3px;
        margin-top: 9px;
        text-align: center;
    }
    
    .leftSection .imageSection{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imageSection img{
        width: 167px;
    }

    .title span, .title br{
        display: none;
    }

    .inputContainer{
        margin-top: 18px;
    }

    .errorBanner{
        margin: 15px 0 -6px;
        padding: 9px 8px;
    }

    .successBanner{
        margin: 15px 0 -6px;
    }

    .wrapper{
        padding: 20px;
    }

    .linkWrapper{
        margin-top: 15px;
    }
}

@media(max-width: 540px){
    .wrapper{
        padding: 0;
    }

    .box{
        height: 100%;
        box-shadow: unset;
        border-radius: unset;
        max-width: unset;
    }

    .leftSection{
        height: 100%;
        border-radius: unset;
    }

    .imageSection img{
        width: 190px;
    }

    .imageSection{
        margin-top: 8px;
    }

    .logo{
        width: 27px;
        height: 27px;
    }

    .name{
        font-size: 27px;
    }

    .form{
        padding: 5px 20px 30px;
    }
}


@media (max-width: 465px){
    .title{
        font-size: 24px;
    }
    
    .instructions{
        font-size: 11.8px;
    }

    .imageSection img{
        width: 165px;
    }

    .form{
        padding: 7px 12px 20px;
    }
}


@media (max-width: 420px){
    .inputContainer label{
        font-size: 13px;
    }

    .errorIcon{
        font-size: 17px;
    }
    
    .errorText{
        font-size: 12.2px;
    }

    .errorBox{
        font-size: 12.3px;
    }

    .successBanner{
        padding: 6px 8px;
    }

    .successIcon{
        position: absolute;
        display: flex;
        align-items: center;
        font-size: 17px;
        left: 8px;
    }

    .errorText{
        font-size: 12.2px;
    }
    
    .successText{
        font-size: 11.2px;
        text-indent: 19.5px;
    }

    .inputBox{
        height: 45px;
    }

    .inputBox input{
        font-size: 12.6px;
    }

    .emailIcon{
        font-size: 18px;
    }

    .submitBtn{
        height: 44px;
        font-size: 15.5px;
        border-radius: 4px;
    }

    .loadingBtn{
        height: 44px;
        border-radius: 4px;
    }
    
    .loader{
        width: 27.5px;
        height: 27.5px;
    }

    .linkWrapper{
        font-size: 12.5px;
    }

    .linkWrapper{
        font-size: 12.2px;
    }
    
}


@media (max-width: 400px){
    .title{
        font-size: 22px;
    }
    
    .instructions{
        font-size: 11.5px;
        margin-top: 7px;
    }

    .form{
        padding: 7px 10px 20px;
    }

    .errorIcon{
        position: absolute;
        left: 8px;
        top: 9.5px;
        margin-top: 0;
        margin-right: 0;
        font-size: 15px;
    }

    .errorText{
        text-indent: 19px;
    }

    .errorBanner{
        justify-content: unset;
    }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}