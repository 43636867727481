.box{
    position: fixed;
    box-shadow: 0 0 0 1px #424244;
    background-color: #292a2d;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 7px;
    left: -265px;
    width: 260px;
    color: #fff;
    z-index: 3;
    padding-bottom: 5px;
    overflow-y: auto;
}

.calendarHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;;
    border-bottom: 1px solid #4c4a4a;
}

.dayRow{
    display: flex;
    align-items: center;
    padding: 10px 10px 5px
}

.row{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 10px;
    margin-bottom: 3px;
}

.row.min{
    margin-bottom: 1.8px;
}

.month{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 13.5px;
    letter-spacing: 0.25px;
}

.arrowIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 14.5px;
    cursor: pointer;
    border-radius: 4px;
}

.arrowIcon:hover{
    background-color: #606062;
}

.dateText{
    width: calc((100% - 42px) / 7);
    margin-right: 7px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #b5b2b2;
}

.dateBox{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 7px;
    font-family: 'Lato', sans-serif;
    font-size: 12.8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row.min .dateBox{
    width: 23px;
    height: 23px;
    font-size: 12.4px;
}

.dateBox:hover{
    background-color: #414246;
}


.dateBox.blur{
    color: #a39f9f;
}

.dateBox.selected{
    background-color: #e0c8a0;
    color: #000;
    font-weight: 600;
}

@media (max-width: 750px){
    .box{
        left: unset;
        right: 0;
        top: 42px;
    }
}