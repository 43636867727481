.box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.wrapper{
    width: 100%;
    height: 100vh;
}

.header{
    height: 74px;
    min-height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
}

.subHeader{
    height: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    border-top: 0.85px solid #383636;
    border-bottom: 0.85px solid #383636;
}

.title{
    min-width: 148.5px;
    height: 28.5px;
    background-color: #353535;
    border-radius: 4px;
}

.searchBox{
    width: 40%;
    background-color: #353535;
    height: 40px;
    border-radius: 25px;
    min-width: 400px;
}

.profileArea{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #353535;
}

.searchWrapper.min{
    display: none;
}

.lengthBox{
    background-color: #353535;
    width: 126.5px;
    height: 23px;
    border-radius: 3px;
}

.btns{
    display: flex;
    align-items: center;
}

.sortBtn{
    width: 64px;
    height: 35.5px;
    border-radius: 7px;
    background-color: #353535;
    margin-right: 16px;
}

.filterBtn{
    width: 70px;
    height: 35.5px;
    border-radius: 7px;
    background-color: #353535;
    margin-right: 16px;
}

.addBtn{
    width: 114.5px;
    height: 37px;
    border-radius: 4px;
    background-color: #353535;
}

.grid{
    flex-grow: 1;
    overflow-y: hidden;
}

.tarots{
    padding: 20px 18px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.tarotBox{
    width: calc((100% - 60px)/4);
    min-width: calc((100% - 60px)/4);
    margin-right: 20px;
    margin-bottom: 25px;
}

.tarotContainer{
    padding: 10px 10px 0;
    color: #fff;
    box-shadow: 0 0 0 1px #424244;
    background-color: #1f1f1f;
    border-radius: 6px;
    border: 0.5px solid #2b2b2b;
    box-sizing: border-box;
    position: relative;
    width: 100%;
}

.tarots .tarotBox:nth-of-type(4n){
    margin-right: 0;
}

.imageSection{
    width: 100%;
    min-width: 100%;
    aspect-ratio: 2.2 / 1;
    max-height: 165px;
    min-height: 120px;
    background-color: #323233;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 8px;
    box-sizing: border-box;
}

.spreadDiv{
    display: inline-flex;
    align-items: center;
    background-color: #323233;
    color: #c2bfbf;
    width: 138px;
    height: 24px;
    border-radius: 7px;
}

.content{
    padding: 12px 0 8px;
}

.textArea{
    padding: 6px 0 0;
}

.textLine{
    width: 100%;
    height: 13px;
    border-radius: 12px;
    background-color: #353535;
}

.textArea .textLine:first-of-type{
    margin-bottom: 7px;
}

.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.85px solid #414040;
    padding: 10px 0;
    color: #ccc8c8;
}

.dateDiv{
    width: 55px;
    height: 17px;
    border-radius: 5px;
    background-color: #353535;
}
@media (max-width: 1500px){
    .tarotBox{
        width: calc((100% - 45px)/4);
        min-width: calc((100% - 45px)/4);
        margin-right: 15px;
    }
}

@media (max-width: 1320px) {
    .imageSection{
        max-height: 140px;
        min-height: 100px;
    }

    .tarotBox{
        width: calc((100% - 40px)/3);
        min-width: calc((100% - 40px)/3);
        margin-right: 20px;
    }

    .tarots .tarotBox:nth-of-type(4n){
        margin-right: 20px;
    }

    .tarots .tarotBox:nth-of-type(3n){
        margin-right: 0;
    }
}


@media (max-width: 1000px){
    .tarotBox{
        width: calc((100% - 25px)/2);
        min-width: calc((100% - 25px)/2);
        margin-right: 25px;
    }

    .tarots .tarotBox:nth-of-type(4n){
        margin-right: 25px;
    }

    .tarots .tarotBox:nth-of-type(3n){
        margin-right: 25px;
    }

    .tarots .tarotBox:nth-of-type(2n){
        margin-right: 0;
    }
}

@media (max-width: 820px){
    .tarotBox{
        width: calc((100% - 15px)/2);
        min-width: calc((100% - 15px)/2);
        margin-right: 15px;
    }

    .tarots .tarotBox:nth-of-type(4n){
        margin-right: 15px;
    }

    .tarots .tarotBox:nth-of-type(3n){
        margin-right: 15px;
    }

    .tarots .tarotBox:nth-of-type(2n){
        margin-right: 0;
    }
}

@media (max-width: 750px){
    .title{
        min-width: 128.5px;
        height: 23.5px;
    }

    .profileArea{
        width: 34px;
        height: 34px;
    }

    .subHeader{
        height: 48px;
        min-height: 48px;
        border-top: 0.6px solid #3c3a3a;
        padding: 0 6px;
        margin: 0 8px;
    }

    .header{
        height: 58px;
        min-height: 58px;
    }

    .searchBox{
        display: none;
    }

    .sortBtn, .filterBtn{
        width: 31.8px;
        height: 31.8px;
        border-radius: 4.5px;
        margin-right: 13px;
    }

    .addBtn{
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .searchWrapper.min{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border-bottom: 0.6px solid #3c3a3a;
        border-top: 0.7px solid #3c3a3a;
    }

    .min .searchBox{
        display: flex;
        width: 100%;
        border-radius: 7px;
        min-width: unset;
    }
    
}

@media (max-width: 640px){
    .tarotBox{
        width: 100%;
        max-width: 505px;
        margin: 0 auto 22px;
    }

    .tarots .tarotBox:nth-of-type(4n){
        margin-right: auto;
    }

    .tarots .tarotBox:nth-of-type(3n){
        margin-right: auto;
    }

    .tarots .tarotBox:nth-of-type(2n){
        margin-right: auto;
    }
}

@media(max-width: 600px){
    .header{
        padding-left: 48px;
    }
}