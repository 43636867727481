@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Belanosima&display=swap');

.wrapper{
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: clamp(10px, 60px, 6.5vh) 0 clamp(8px, 20px, 3vh);
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    z-index: 10;
    box-sizing: border-box;
}

.box{
    background-color: #252424;
    width: 90%;
    max-width: 540px;
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.resumeBtn{
    width: 28px;
    height: 28px;
    background-color: #565454;
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.4px solid #848384; 
    animation:  expand 2s linear 0.3s infinite alternate;
    cursor: pointer;
    box-shadow: rgba(146, 145, 145, 0.35) 0px 5px 15px;
    z-index: 0;
    margin-right: 15px;
}

.resumeBtn:hover{
    background-color: #656363;
}

.resumeIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
}

.box header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 12px;
    border-bottom: 1px solid #3e3e40;
}

.btns{
    display: flex;
    align-items: center;
}

.menuWrapper{
    position: relative;
}

.closeIcon, .menuIcon{
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    border-radius: 7px;
    cursor: pointer;
    color: #a2a0a2;
}

.menuIcon{
    font-size: 19px;
}

.closeIcon:hover, .menuIcon:hover, .menuIcon.active{
    color: #fff;
    background-color: #424244;
}

.menuIcon{
    margin-right: 8px;
}

.content{
    flex-grow: 1;
    overflow-y: auto;
    padding: 18px 17px 13px;
    position: relative;
}

.dateItem{
    margin: 0 auto;
    width: fit-content;
    padding: 7px 16px;
    text-align: center;
    background-color: #454343;
    border-radius: 10px;
    margin-bottom: 8px;
}

.dateTitle{
    color: #b3b1b1;
    font-family: 'Poppins', sans-serif;
    font-size: 11.8px;
    margin-bottom: 2px;
}

.dateValue{
    font-family: 'Poppins', sans-serif;
    font-size: 12.5px;
    font-weight: 600;
    letter-spacing: 0.2px;
}

.dateWrapper .dateItem:last-of-type{
    border-left: 1.7px solid #515050;
}

.titleBox{
    display: flex;
    align-items: center;
}

.title{
    font-family: 'Belanosima', sans-serif;
    font-weight: 600;
    font-size: 23px;
    letter-spacing: 0.5px;
}

.sectionTitle{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    margin-right: 7.6px;
    margin-bottom: 2px;
}

.icon{
    color:#e8d2ad;
    font-size: 15.5px;
}

.text{
   color: #dcd9d9;
   font-family: 'Poppins', sans-serif;
   font-size: 12.6px;
   line-height: 1.65;
}

.content .section{
    margin-bottom: 10px;
}

.imageBox{
    width: 100%;
    aspect-ratio: 2.15 / 1;
    margin: 7px 0 3px
}

.imageBox img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.questionBox{
    margin-bottom: 16px;
    border-bottom: 0.85px solid #4e4c4c;
    padding: 12px 0;
    margin: 0 -17px;
}

.questions .questionBox:first-of-type{
    padding-top: 7px;
}

.questions .questionBox:last-of-type{
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
}

.question{
    padding: 6.5px 17px;
    border-left: 3px solid #e8d2ad;
    background-color: #3c3b3b;
    font-family: 'DM Sans', sans-serif;
    font-size: 13.7px;
    min-height: 36px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: 1.5;
}

.dateBox{
    margin: 9px 17px 7px;
    display: inline-flex;
    align-items: center;
    background-color: #565454;
    color: #fae8c8;
    padding: 5px 8px;
    box-sizing: border-box;
    border-radius: 12px;
}

.date{
    font-family: 'DM Sans', sans-serif;
    font-size: 12.1px;
}

.timeIcon{
    display: flex;
    align-items: center;
    font-size: 12.5px;
    margin-right: 3px;
}

.answer{
    color: #dcd9d9;
    font-family: 'Poppins', sans-serif;
    font-size: 12.4px;
    line-height: 1.65;
    margin: 0 17px;
}

.arrowIcon{
    float: left;
    color: #e8d2ad;
    font-size: 18px;
    margin-top: -1px;
    height: 15px;
    margin-right: 3px;
}

.spreadArrow{
    float: left;
    font-size: 18px;
    margin-top: -2px;
    height: 16.5px;
    margin-right: 2px;
}

.spreadBox{
    display: flex;
    align-items: center;
    padding-top: 3px;
    color: #f0ecec;
    color: rgb(224, 149, 8);
}

.spreadName{
    font-family: 'DM Sans', sans-serif;
    font-size: 13.8px;
    font-weight: 600;
}

.spreadIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14.8px;
    margin-top: -2px;
    margin-right: 2px;
}

.spreadDesc{
    margin-left: 19px;
    color: #eae7e7;
    font-family: 'Poppins', sans-serif;
    font-size: 12.6px;
    margin-top: 3px;
}

.cards{
    padding-top: 5px;
}

.cardBox{
    display: flex;
    margin-bottom: 16px;
    border: 1px solid #484747;
    border-radius: 7px;
}

.imageWrapper{
    padding: 10px;
    background-color: #434345;
    border-radius: 7px 0 0 7px;
    width: 20%;
    min-width: min(20%, 75px);
    max-width: 75px;
}

.cardImage{
    width: 100%;
    aspect-ratio: 1 / 1.45;
    background-size: 100% 100%;
    border-radius: 7px;
}

.cardName{
    font-family: 'Eczar', serif;
    font-size: 17.5px;
    letter-spacing: 0.2px;
    text-decoration: underline;
    text-decoration-style: double;
    margin-top: -2px;
}

.cardDesc{
    color: #dcd9d9;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 1.6;
    margin-top: -1.5px;
}

.cardContent{
    margin-left: 10px;
    padding: 8px 0;
}

.fetchingLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 50px 0;
}

.spinner{
    border: 3.8px solid #676667;
    border-radius: 50%;
    border-top: 3.8px solid transparent;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.content .section:nth-of-type(n+3){
    border-top: 1.6px solid #3b3939;
    padding-top: 15px;
    padding-bottom: 3px;
}

@keyframes expand{
    from {transform: scale(1,1);}
    to {transform: scale(1.3,1.3)}
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media(max-width: 600px){
    .wrapper{
        padding: 0;
    }

    .box{
        width: 100%;
        height: 100%;
        max-width: unset;
    }

    .content{
        padding: 15px 14px 10px;
    }

    .questionBox{
        margin: 0 -14px;
    }
}

@media(max-width: 520px){
    .cardDesc{
        font-size: 11.7px;
    }

    .cardName{
        font-size: 17px;
    }

    .imageWrapper{
        width: 17%;;
        min-width: max(min(17%, 65px),70px);
        max-width: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text, .spreadDesc{
        font-size: 12.2px;
    }

    .spreadName{
        font-size: 13.4px;
    }

    .spreadIcon{
        font-size: 14.4px;
    }

    .sectionTitle{
        font-size: 13.5px;
    }

    .icon{
        font-size: 15px;
    }

    .answer{
        font-size: 12.2px;
    }

    .question{
        font-size: 13.2px;
        padding: 6.5px 14px;
    }

    .dateBox{
        margin: 9px 14px 7px;
    }

    .timeIcon{
        font-size: 12.5px;
        margin-top: -1px
    }

    .date{
        font-size: 11.75px;
    }

}
    
@media(max-width: 391px){
    .cardBox{
        flex-direction: column;
        width: 100%;
    }

    .imageWrapper{
        width: 100%;
        min-width: unset;
        max-width: unset;
        box-sizing: border-box;
        border-radius: 7px 7px 0 0;
    }

    .cardImage{
        width: 75px;
        height: 120px;
    }

    .dateItem{
        margin-bottom: 14px;
    }
}

@media(max-width: 310px){
    .content{
        padding: 13px 10px 8px;
    }

    .questionBox{
        margin: 0 -10px;
    }
}