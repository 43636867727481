.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(64, 65, 68, 0.55);
}


.box{
    width: 61.5vh;
    height: 61vh;
    background-image: url('../media/frame.png');
    background-size: 100% 100%;
    padding: 2.4% 2.5%;
    box-sizing: border-box;
    position: relative;
}

.closeIcon{
    position: absolute;
    width: 4.8vh;
    height: 4.8vh;
    right: -6vh;
    border-radius: 50%;
    font-size: 3.3vh;
    top: 1.2vh;
    background-image: url('../media/paper.PNG');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.closeIcon:hover{
    transform: scale(1.06,1.06);
}

.box.min{
    width: 59.5vh;
    padding: 2% 2.6%;
}

.titleBox{
    font-family: 'Alegreya', serif;
    font-size: 2.85vh;
    text-decoration: underline;
    text-decoration-style: double;
    text-align: center;
    font-weight: 600;
}

.box.min .titleBox{
    font-size: 2.6vh;
}

.calendarHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.1vh;
    padding: 0 0.9vh
}

.month{
    font-family: 'Eczar', serif;
    font-weight: 600;
    font-size: 2.8vh;
}

.box.min .month{
    font-size: 2.5vh;
}

.arrowIcon{
    background-color: #c27b49;
    border-radius: 50%;
    width: 4.15vh;
    height: 4.15vh;
    border: 0.22vh solid #000;
    box-sizing: border-box;
    padding: 0.33vh;
    box-shadow: #e7a372 0px 0px 0.4vh 0.2vh inset;
    cursor: pointer;
}

.box.min .arrowIcon{
    width: 3.9vh;
    height: 3.9vh;
}

.arrowIcon:hover{
    transform: scale(1.05,1.05);
}

.innerIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 0.22vh solid #000;
    border-radius: 50%;
    font-size: 1.8vh;
    box-shadow: #e7a372 0px 0px 0.4vh 0.2vh inset;
}

.box.min .innerIcon{
    font-size: 1.6vh;
}

.arrowIcon.prev .innerIcon{
    padding-right: 0.25vh;
}

.arrowIcon.next .innerIcon{
    padding-left: 0.25vh;
}

.dayRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7vh 0.3vh;
}

.dateText{
    font-family: 'Alegreya', serif;
    font-size: 2.4vh;
    text-align: center;
    font-weight: 600;
    width: calc((100% - 1vh *6) / 7 );
}

.box.min .dateText{
    font-size: 2.25vh;
    width: calc((100% - 1.2vh *6) / 7 );
}

.row{
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
}

.box.min .row{
    margin-bottom: 0.75vh;
}

.dateBox{
    background-color: #342c2c;
    width: calc((100% - 1vh *6) / 7 );
    margin-right: 1vh;
    border: 0.35vh solid #000;
    aspect-ratio: 1 / 1;
    box-sizing: border-box;
    padding: 0.2vh;
    border-radius: 0.4vh;
    box-shadow: #7c7c7c 0px 0px 0.8vh 0.4vh inset;
    cursor: pointer;
}

.box.min .dateBox{
    width: calc((100% - 1.3vh *6) / 7 );
    margin-right: 1.3vh;
}

.dateBox:hover{
    transform: scale(1.04,1.04);
}

.dateInnerBox{
    border: 0.3vh solid #000;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4vh;
    color: #e8d5ae;
    box-shadow: #7c7c7c 0px 0px 0.4vh 0.2vh inset;
    font-family: "Lora", serif;
    font-weight: 600;
    font-size: 2.35vh;
}

.box.min .dateInnerBox{
    font-size: 2.25vh;
}

.dateBox.selected{
    background-color: #e8d5ae;
    border-color: #342c2c;
    box-shadow: #bba883 0px 0px 0.4vh 0.2vh inset;
    pointer-events: none;
}

.dateBox.selected .dateInnerBox{
    border-color: #342c2c;
    color: #342c2c;
    box-shadow: #807359 0px 0px 0.4vh 0.2vh inset;
    font-weight: 800;
}

.row .dateBox:last-of-type, .box.min .row .dateBox:last-of-type{
    margin-right: 0;
}

.dateBox.blur{
    opacity: 0.76;
    pointer-events: none;
}