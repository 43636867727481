.menuWrapper{
    position: absolute;
    top: 43px;
    right: 0;
    z-index: 3;
}

.menu{
    box-shadow: 0 0 0 1px #424244;
    background-color: #1e1f21;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 10px;
    min-width: 240px;
    width: fit-content;
    max-width: 270px;
    overflow: hidden;
    color: #fff;
}

.header{
    display: flex;
    align-items: center;
    padding: 13px 12px;
    overflow: hidden;
    border-bottom: 2px solid #424244;
}

.content hr{
    border: 1px solid #424244;
}


.imageCircle{
    width: 38px;
    min-width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    border-radius: 12px;  
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    background-color: #e0c8a0;
    color: #000;
    letter-spacing: 0.3px;
    margin-right: 6px;
    background-size: 100% 100%;
}

.userInfo{
    flex-grow: 1;
    overflow: hidden;
}

.menuItem{
    display: flex;
    align-items: center;
    margin: 0 6px;
    padding: 8.5px 7px;
    color:#e3e3e3;
    cursor: pointer;
    border-radius: 7px;
}

.menuItem:hover{
    background-color: #393939;
}

.content .menuItem:nth-of-type(2){
    margin-bottom: 1px;
}

.content .menuItem:last-of-type{
    margin-bottom: 6px;
}


.name{
    font-family: 'Poppins', sans-serif;
    font-size: 14.4px;
    font-weight: 600;
}

.email{
    font-family: 'DM Sans', sans-serif;
    font-size: 12.5px;
    margin-top: -1.5px;
    color:#b9b4b4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.content{
    padding-top: 3.5px;
}

.menuIcon{
    display: flex;
    align-items: center;
}
.menuText{
    font-family: 'DM Sans', sans-serif;
    font-size: 13.5px;
    letter-spacing: 0.2px;
}

.content .menuItem:first-of-type .menuIcon{
    font-size: 19px;
    margin-right: 4px;
}

.content .menuItem:nth-of-type(2) .menuIcon{
    font-size: 18px;
    margin-right: 5px;
}

.content .menuItem:last-of-type .menuIcon{
    font-size: 17px;
    margin-right: 6px;
}