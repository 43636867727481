.box{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 280px;
}

.header{
    height: 62px;
    min-height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
}

.subHeader{
    height: 55px;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    border-top: 0.85px solid #464444;
    border-bottom: 0.85px solid #464444;;
}

.searchBox{
    display: flex;
    align-items: center;
    width: 40%;
    background-color: #353535;
    height: 35px;
    border-radius: 25px;
    padding: 0 10px;
    box-sizing: border-box;
    min-width: 400px;
}

.searchIcon{
    color: #f5e3c5;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin-top: -1.5px;
}

.searchBox input{
    background-color: inherit;
    outline: 0;
    border: 0;
    height: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-size: 13.5px;
    padding-left: 4px;
    border-radius: 0 25px 25px 0;

}


.title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    color: #f2e5cd;
    font-size: 17.5px;
}

.profileArea{
    width: 38px;
}

.lengthBox{
    display: flex;
    align-items: center;
}

.btns{
    display: flex;
    align-items: center;
}

.sortWrapper, .filterWrapper{
    position: relative;
    margin-right: 14px;
}


.filterBtn, .addBtn, .sortBtn{
    display: flex;
    align-items: center;
    cursor: pointer;
}


.filterBtn, .sortBtn{
    padding: 7px 15px;
    border-radius: 15px;
    background-color: #353535;
    font-family: 'Poppins', sans-serif;
    font-size: 12.5px;
    color: #f2e5cd;
}

.sortBtn.active, .sortBtn:hover, .filterBtn.active, .filterBtn:hover{
    background-color: #4e4c4c;
}

.sortBtn.active, .filterBtn.active{
    color: #faf4e8
}

.sortBtn.active .sortIcon, .filterBtn.active .filterIcon{
    color: #faf4e8
}

.filterBtn.selected .sortIcon, .filterBtn.selected .filterIcon{
    background-color: #4c4031;
    color: #d9c69f
}

.filterBtn.selected, .sortBtn.selected{
    background-color: #4c4031;
    color: #d9c69f
}

.filterBtn.selected:hover, .sortBtn.selected:hover{
    background-color: #594a38;
}


.filterIcon{
    color: #f5e3c5;
    display: flex;
    align-items: center;
    margin-right: 3px;
}

.sortIcon{
    display: flex;
    align-items: center;
    margin-right: 2px;
}


.addBtn{
    background-color: #e0c8a0;
    color: #000;
    margin-left: 3px;
    padding: 9px 13px;
    border-radius: 4px;
}

.addText{
    font-family: 'Poppins', sans-serif;
    font-size: 12.7px;
    font-weight: 600;
    margin-left: 4px;
}

.plusIcon{
    display: flex;
    align-items: center;
    font-size: 16px;
}

.lengthText{
    font-family: 'Poppins', sans-serif;
    font-size: 14.5px;
    color: #f5e3c5;
}

.length{
    padding: 3px 6px;
    background-color: #434242;
    color: #f5e3c5;
    border-radius: 3.5px;
    margin-left: 8px;
    font-family: 'Lato', sans-serif;
    font-size: 12.5px;
    font-weight: 600;
}

.dreams{
    padding: 20px 18px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.grid{
    flex-grow: 1;
    overflow-y: auto;
}

.row{
    display: flex;
    align-items: stretch;
    width: 100%;
}

.dreamBox{
    width: calc((100% - 60px)/4);
    min-width: calc((100% - 60px)/4);
    margin-right: 20px;
    margin-bottom: 25px;
}

.dreams .dreamBox:last-of-type{
    margin-right: 0;
}

.searchWrapper.min{
    display: none;
}

.closeIcon{
    display: none;
}

.emptyContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 700px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.dreamsIcon{
    position: relative;
}

.dreamIcon{
    width: 230px;
    height: 230px;
    font-size: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ebd7b6;
    background-color: #3e3c3c;
    border-radius: 50%;
}

.dreamImage{
    width: 410px;
    aspect-ratio: 500 / 324;
    margin-bottom: 10px;
}

.dreamImage img{
    width: 100%;
    height: 100%;
}

.search{
    position: absolute;
    width: 65px;
    height: 65px;
    font-size: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -5px;
    right: 5px;
    color: #ebd7b6;
    background-color: #5b5858;
    border-radius: 50%;
}

.emptyTitle{
    font-family: Century Gothic;
    font-weight: 600;
    font-size: 34px;
    line-height: 1.1;
    margin-top: 30px;
    color: #f2e5cd;
}

.emptyTitle.minMargin{
    margin-top: 2px;
}

.emptyMsg{
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    margin-top: 8px;
    line-height: 1.8;
    color: #f1dcb4;
}

@media (max-width: 1400px){
    .dreamBox{
        width: calc((100% - 45px)/4);
        min-width: calc((100% - 45px)/4);
        margin-right: 15px;
    }
}



@media (max-width: 1320px){
    .dreamBox{
        width: calc((100% - 40px)/3);
        min-width: calc((100% - 40px)/3);
        margin-right: 20px;
    }
}

@media (max-width: 1000px){
    .dreamBox{
        width: calc((100% - 25px)/2);
        min-width: calc((100% - 25px)/2);
        margin-right: 25px;
    }
}


@media (max-width: 850px){
    .searchBox{
        min-width: 320px;
    }

    .header, .subHeader{
        padding: 0 14px
    }

    .dreams{
        padding: 16px 14px;
    }
}

@media (max-width: 820px){
    .dreamBox{
        width: calc((100% - 15px)/2);
        min-width: calc((100% - 15px)/2);
        margin-right: 15px;
    }
}

@media (max-width: 750px){
    .searchBox{
        display: none;
    }

    .searchWrapper.min{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border-bottom: 0.6px solid #3c3a3a;
        border-top: 0.7px solid #3c3a3a;
    }

    .min .searchBox{
        display: flex;
        width: 100%;
        border-radius: 7px;
        min-width: unset;
    }

    .addText, .filterText, .sortText{
        display: none;
    }

    .sortBtn, .filterBtn, .addBtn{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding: 0;
        border-radius: 4.5px;
    }

    .sortWrapper, .filterWrapper{
        margin-right: 13px;
    }

    .sortIcon{
        font-size: 15px;
    }

    .plusIcon{
        font-size: 18px;
    }

    .addBtn{
        border-radius: 50%;
        margin-left: -1px;
    }

    .header{
        height: 58px;
        min-height: 58px;
        padding-right: 8px;
    }

    .profileArea{
        width: 34px;
    }

    .subHeader{
        height: 48px;
        min-height: 48px;
        border-top: 0.6px solid #3c3a3a;
        padding: 0 6px;
        margin: 0 8px;
    }
}

@media (max-width: 720px){
    .dreamBox{
        width: calc((100% - 15px)/2);
        min-width: calc((100% - 15px)/2);
        margin-right: 15px;
    }
}

@media (max-width: 640px){
    .dreamBox{
        width: 100%;
        max-width: 505px;
        margin: 0 auto 22px;
    }

    .dreams .dreamBox:last-of-type{
        margin-right: auto;
    }

    .emptyContent{
        padding: 20px 14px;
    }

    .dreamIcon{
        width: 210px;
        height: 210px;
        font-size: 130px;
    }
    
    .search{
        width: 55px;
        height: 55px;
        font-size: 35px;
        bottom: -5px;
        right: 5px;
    }
    
    .emptyTitle{
        font-size: 30px;
        margin-top: 26px;
    }
    
    .emptyMsg{
        font-size: 14px;
        line-height: 1.5;
    }

    .dreamImage{
        width: 360px;
        max-width: 100%;
    }
}

@media (max-width: 600px) {
    .header{
        padding-left: 48px;
    }
}

@media(max-width: 500px){
    .subHeader{
        margin: 0 5px;
    }

    .searchWrapper.min{
        padding: 10px;
    }

    .emptyTitle span{
        display: none;
    }

}

@media(max-width: 360px){

    .header{
        padding-left: 40px
    }

    .lengthText{
        font-size: 13.8px;
    }

    .length{
        font-size: 12px;
    }

    .filterBtn, .addBtn, .sortBtn{
        width: 28px;
        height: 28px;
    }

    .sortIcon{
        font-size: 13.5px;
    }

    .sortWrapper, .filterWrapper{
        margin-right: 10px;
    }

    .filterIcon{
        font-size: 13px;
    }

    .title{
        font-size: 16px;
    }

    .profileArea{
        width: 30px;
        height: 30px;
    }
}

@media(max-width: 330px){
    .subHeader{
        padding: 0 4px;
        margin: 0 5px;
    }

    .emptyContent{
        padding: 20px 10px;
    }
    .emptyTitle{
        font-size: 27px;
    }
    
    .emptyMsg{
        font-size: 13.7px;
    }
    
    .dreamIcon{
        width: 190px;
        height: 190px;
        font-size: 110px;
    }

    .search{
        width: 50px;
        height: 50px;
        font-size: 30px;
        bottom: 0px;
    }

    .dreamImage{
        width: 265px;
    }
}