.wrapper{
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    z-index: 10;
}

.box, .fetchingLoader{
    background-color: #252424;
    width: 90%;
    max-width: 520px;
    border-radius: 3px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    position: relative;
    padding-top: 7px;
    border-top: 5px solid #e23535;
}

.deleteIcon{
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    top: -40px;
    left: calc(50% - 40px);
    position: absolute;
    color: #e23535;
    border-radius: 50%;
    background-color: #434242;
}

.closeIcon{
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20.5px;
    border-radius: 50%;
    cursor: pointer;
    color: #dbd9db;
    position: absolute;
    top: 5px;
    right: 6px;
}

.closeIcon:hover{
    color: #fff;
    background-color: #424244;
}

.content{
    padding: 42px 15px 20px;
}

.title{
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
}

.text{
    font-family: 'DM Sans', sans-serif;
    font-size: 13.7px;
    line-height: 1.6;
    color: #ccc8c8;
    margin-top: 7px;
}

.text b{
    color: #fff;
}

.inputBox{
    margin-top: 14px;
}

.inputBox label{
    font-size: 13.2px;
    color: #aeaaaa;
    margin-bottom: 6.5px;
    display: block;
    font-family: 'DM Sans', sans-serif;
}

.inputBox input{
    height: 42.5px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    outline: 0;
    border: 1px solid #565557;
    padding: 0 8px;
    background-color: inherit;
    font-size: 12.8px;
    font-family: 'Poppins', sans-serif;
}

.btns{
    height: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    justify-content: center;
    background-color: #3b3b3c;
    border-radius: 0 0 3px 3px;
}

.deleteBtn{
    height: 36.5px;
    padding: 0 12px;
    border: 0;
    outline: 0;
    background-color: #e23535;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 13.2px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}

.loadingBtn{
    width: 124.2px;
    height: 36.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e23535;
    border-radius: 5px;
}

.loader{
    border: 2.5px solid #f6cece;
    border-radius: 50%;
    border-top: 2.5px solid transparent;
    width: 25px;
    height: 25px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.deleteBtn:hover{
    background-color: #f04c4c;
}

.deleteBtn:disabled{
    opacity: 0.6;
    pointer-events: none;
}

.cancelBtn{
    height: 36.5px;
    padding: 0 12px;
    border: 0;
    outline: 0;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 13.2px;
    border-radius: 5px;
    margin-right: 14px;
    cursor: pointer;
    border: 1px solid #6a696a;
    background-color: inherit;
}

.cancelBtn:hover{
    background-color: #4f4d4d;
}

.header{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 18px 12px;
    border-bottom: 1px solid #3e3e40;
}

.closeIcon{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 7px;
    cursor: pointer;
    color: #a2a0a2;
}

.closeIcon:hover{
    color: #fff;
    background-color: #424244;
}

.spinner{
    border: 3.8px solid #676667;
    border-radius: 50%;
    border-top: 3.8px solid transparent;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
    margin: 35px auto;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media(max-width: 570px){
    .wrapper{
        padding: 0;
    }

    .box{
        width: 100%;
        height: 100%;
        max-width: unset;
        border-top: 0;
    }

    .deleteIcon{
        position: unset;
        margin: 20px auto 0;
    }

    .content{
        padding-top: 10px;
    }

    .btns{
        background-color: unset;
        flex-direction: column;
        height: unset;
        margin-top: 10px;
        padding:0 15px
    }

    .cancelBtn{
        width: 100%;
        box-sizing: border-box;
        height: 46px;
        margin-right: 0;
        background-color: #3b3b3c;
        border: 0;
    }

    .deleteBtn{
        width: 100%;
        box-sizing: border-box;
        height: 46px;
        margin-top: 14px;
    }

    .content{
        font-size: 13.5px;
    }
}

@media(max-width: 465px){
    .content{
        padding: 10px 10px 20px;
    }

    .btns{
        padding: 0 10px;
    }
}

@media(max-width: 400px){
    .content{
        font-size: 12.8px;
    }

    .deleteIcon{
        width: 60px;
        height: 60px;
        font-size: 38px
    }

    .title{
        font-size: 17px;
    }
}