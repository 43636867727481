.dreamBox{
    width: 100%;
    position: relative;
    margin-bottom: 1.4vh;
}

.dreamBody{
    background-image: url('../../tarotGame/media/resultsBorder.png');
    background-size: 100% 100%;
    background-color: #000;
    width: 100%;
    border-radius: 1.3vh;
    padding: 3.5vh 2vh 1.2vh;
    box-sizing: border-box;
    overflow: hidden;
}

.dreamContainer{
    padding-top: 4.5vh;
    width: 100%;
}

.dreamBox .imageBox{
    height: 8vh;
    width: 8vh;
    position: absolute;
    left: 5%;
    border: 0.3vh solid #000;
    box-sizing: border-box;
    background-size: 100% 100%;
    border-radius: 1.5vh;
}

.leftContainer{
    width: 42%;
    height: 71.5vh;
    background-size: 100% 100%;
    padding: 0.85% 6.5% 0.8% 2%;
    box-sizing: border-box;
    background-image: url('../media/bookside.png');
    transform: scale(-1,1);
}

.rightContainer{
    width: 42%;
    height: 71.5vh;
    background-size: 100% 100%;
    padding: 1% 6.5% 0.8% 2%;
    box-sizing: border-box;
    background-image: url('../media/bookside.png');
}

.historyContent{
    overflow-y: scroll;  
    max-height: 100%;
    padding: 2.1% 0 0%;
    box-sizing: border-box;
}

.historyContent::-webkit-scrollbar{
    display: none;
}

.leftContainer .historyContent{
    transform: scale(-1,1);
}

.bookArea{
    flex-grow: 1;
    padding: 3vh 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.historyTitle{
    color: #e5d0a5;
    font-family: 'Eczar', serif;
    font-size: 2.2vh;
}

.dreamDateBox{
    display: flex;
    align-items: center;
    color: #e5c27a;
    margin-top: -0.6vh;
}

.dreamtAtDate{
    font-family: 'Poppins', sans-serif;
    font-size: 1.4vh;
}

.moonIcon{
    display: flex;
    align-items: center;
    font-size: 1.65vh;
    margin-right: 0.28vh;
}

.dreamContent{
    font-family: 'Caudex', serif;
    font-size: 1.6vh;
    color: #dfae4d;
    line-height: 1.3;
    margin-top: 0.5vh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.resumeBtnWrapper{
    width: 3.6vh;
    height: 3.6vh;
    background-color: #e5c27a;
    border-radius: 50%;
    font-size: 1.8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 6vh;
    right: 1.5vh;
    position: absolute;
    border: 1.4px solid #886724; 
    animation:  expand 2s linear 0.3s infinite alternate;
    cursor: pointer;
    box-shadow: rgba(146, 145, 145, 0.35) 0px 5px 15px;
    z-index: 0;
}

.resumeBtnWrapper:hover{
    transform: scale(1.1,1.1);
}


.resumeBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
}

.arrowBtn{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.arrowWrapper.left{
    bottom: 12%;
    left: 8.9%;
    border-radius: 0 1.2vh 1.2vh 0;
    border-left-width: 0
}

.arrowWrapper.right{
    border-radius: 1.2vh 0 0 1.2vh;
    bottom: 12%;
    right: 8.9%; 
    border-right-width: 0
}

.arrowIcon{
    width: 2.25vh;
    height: 2.25vh;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.1vh;
    border: 0.24vh solid #d3b883;
    color: #d3b883;
}

.arrowWrapper{
    border: 0.25vh solid #543903;
    box-shadow: inset 2px 3px 5px #000000, 2px 1px 1px #333;
    position: absolute;
    background-color: #000;
    width: 5.4vh;
    height: 4.7vh;
    box-sizing: border-box;
}

@media (max-width: 140vh){
    .leftContainer, .rightContainer{
        width: 47%;
    }

    .arrowWrapper.right{
        right: 4%;
    }

    .arrowWrapper.left{
        left: 4%;
    }
}


@media (max-width: 125vh){
    .leftContainer, .rightContainer{
        width: 48%;
        height: 73vh;
    }

    .bookArea{
        padding-top: 2.5vw;
    }

    .historyTitle{
        font-size: 2vw;
    }
    
    .dreamDateBox{
        margin-top: -0.45vw;
    }
    
    .dreamtAtDate{
        font-size: 1.2vw;
    }
    
    .moonIcon{
        font-size: 1.45vw;
        margin-right: 0.25vw;
    }
    
    .dreamContent{
        font-size: 1.4vw;
        margin-top: 0.5vw
    }
    
    .resumeBtnWrapper{
        width: 3.35vw;
        height: 3.35vw;
        font-size: 1.55vw;
        top: 5vw;
        right: 1.35vw;
    }
}