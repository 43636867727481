.section label, .label{
    display: block;
    font-size: 13.6px;
    font-family: 'DM Sans', sans-serif;
    color: #ccc8c8;
    margin-bottom: 6px;
}

.section.display .label{
    color: #a2a0a2;
    font-size: 13px;
    letter-spacing: -0.15px;
}

.section{
    margin-bottom: 16px;
}

.doubleWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.doubleWrapper .section{
    width: calc((100% - 18px) / 2);
}


.box .section:nth-of-type(2){
    margin-bottom: 20px;
}

.box{
    padding: 16px 18px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
}

.content{
    flex-grow: 1;
}

.dateBoxes{
    display: flex;
    align-items: center;
}

.selectorWrapper{
    position: relative;
    margin-right: 12px;
    width: auto;
}

.selectorInput{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1.5px solid rgb(62, 61, 62);
    padding: 0 6.5px;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
}

.section.display .selectorInput{
    width: 70%;
    max-width: 220px;
    border-radius: 7px;
    border-width: 1.8px;
    height: 38px;
    box-sizing: border-box;
    padding: 0 9px;
}

.section.display .dropDown{
    width: 70%;
    max-width: 220px;
    right:unset;
    left: 0;
}

.dateBoxes .selectorWrapper:first-of-type .selectorInput{
    min-width: 87.5px;
    box-sizing: border-box;
}

.gender .selectorInput{
    justify-content: space-between;
    padding: 0 10px;
    height: 38px;
    box-sizing: border-box;
    border-radius: 6px;
}

.selectorWrapper.gender{
    margin-right: 0;
}


.selectorInput:hover, .selectorInput.active{
    background-color:#303132;
}

.placeholder{
    color: #ccc8c8;
    font-family: 'Poppins', sans-serif;
    font-size: 13.2px;
}

.value{
    color: #ccc8c8;
    font-family: 'Poppins', sans-serif;
    font-size: 13.5px; 
}

.gender .value, .genderText{
    text-transform: capitalize;
}

.doubleWrapper .value{
    font-family: 'Poppins', sans-serif;
    font-size: 12.8px;
}

.checkItem{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.checkSquare{
    width: 12px;
    height: 12px;
    border-radius: 3.5px;
    border: 1.4px solid #545456;
    margin-right: 6px;
    margin-top: -2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9.5px;
}

.checkSquare:hover, .checkSquare.checked{
    background-color: #2e2d2d;
}

.items{
    padding-top: 5px;
}

.items .checkItem:last-of-type{
    margin-bottom: 0;
}

.box .section.display:first-of-type{
    padding-top: 7px;
}

.checkText{
    font-family: 'DM Sans', sans-serif;
    font-size: 13.2px;
    color: #ccc8c8;
}

.box input[type="file"]{
    display: none;
}

.arrow{
    display: flex;
    align-items: center;
    margin-left: 11px;
    color: #dfdddd;
    font-size: 14px;
}

.placeholder{
    text-transform: uppercase;
}

.dropDown{
    position: absolute;
    background-color: #282828;;
    width: 100%;
    right: 0;
    padding: 4px 0;
    top: 42px;
    box-shadow: 0 0 0 1px #424244;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 4px;
    z-index: 3;
    overflow-y: auto;
}

.dropDown.top{
    bottom: 42px;
    top: unset;
}

.dropDown::-webkit-scrollbar {
    width: 6px; 
}

.dropDown::-webkit-scrollbar-track {
    background: #3e3e3e;
    border-radius: 6px;
}

.dropDown::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 6px;
}

.photoWrapper{
    display: flex;
}

.removeWrapper{
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.removeBtn{
    background-color: #282828;
    color: #da4242;
    padding: 7.5px 16px;
    border-radius: 14px;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    font-size: 13.5px;
    letter-spacing: 0.15px;
    margin-top: 33px;
}

.removeBtn:hover{
    background-color: #2e2d2d;
    transform: scale(1.05,1.05);
}

.photoBox label, .photo img{
    width: 68px;
    min-width: 68px;
    height: 68px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 37px;
    color: #625e5e;
    border: 1px dashed #777677;
    background-color: #2f2d2d;
    cursor: pointer;
}

.photo{
    position: relative;
}

.photo img{
    pointer-events: none;
    border-style: solid;
    border-width: 0.6px;
    box-shadow: rgba(187, 185, 185, 0.06) 0px 10px 36px 0px, rgba(187, 185, 185, 0.06) 0px 0px 0px 1px;
}

.photo label{
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1px;
    right: -2.5px;
    font-size: 14px;
    background-color: #58585a;
    border: 1px solid #7a7979;
    color: #d9d3d3;
    cursor: pointer;
}

.photo label:hover{
    transform: scale(1.15,1.15);
}

.content input[type="text"], .content input[type="password"]{
    height: 38px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    outline: 0;
    border:  1.4px solid rgb(62, 61, 62);
    padding: 0 8px;
    background-color: inherit;
    font-size: 12.5px;
    font-family: 'Poppins', sans-serif;
}

.content .errorBox input[type="password"], .content .errorBox [type="text"]{
    border: 1.6px solid #e23535;
    color: #d53d1f;
}

.error{
    font-family: 'Poppins', sans-serif;
    font-size: 12.5px; 
    color: #e23535;
    margin: 2px 0 -8px;
}

.expiryText{
    font-family: 'Poppins', sans-serif;
    font-size: 11.2px; 
    color: #e8d2ad;
    margin: -3px 0 4px; 
    text-indent: 3px; 
}

.expiryText span{
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}

.expiryText.expired, .expiryText.expired span{
    color: #e23535
}

.box.security .content input[type="text"], .box.security .content input[type="password"]{
    width: 75%;
}


.box.security .content input[attr="code"]{
    border-width: 2px;
    width: 120px;
    height: 42px;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 6px;
    font-family: 'Special Elite', system-ui;
    font-size: 20px;
}

.box.security .content input[attr="code"]::placeholder{
    letter-spacing: 1.5px;
    font-size: 16.7px;
}

.content input[type="text"]:disabled{
    color: #8b8a8b;
    background-color: #2b2929;
}

.month, .text{
    display: flex;
    align-items: center;
    padding: 3.5px 0;
    cursor: pointer;
    margin-bottom: 1px;
    font-family: 'Poppins', sans-serif;
    font-size: 12.5px;
    justify-content: center;
    color: #d9d6d6;
}

.languageBox, .genderBox{
    display: flex;
    align-items: center;
    height: 36px;
    cursor: pointer;
}

.languageCheck, .genderCheck{
    width: 36px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15.5px;
}

.languageText, .genderText{
    font-size: 13.7px;
    color: #d9d6d6;
    font-family: 'DM Sans', sans-serif;
}

.day, .year{
    display: flex;
    align-items: center;
    padding: 3.5px 0;
    cursor: pointer;
    margin-bottom: 1.5px;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    justify-content: center;
    letter-spacing: 0.25px;
    color: #d9d6d6;
}

.month:hover, .day:hover, .year:hover, .languageBox:hover, .genderBox:hover{
    background-color: #434242;
}

.month.active, .year.active, .year.active{
    background-color: #565555;
    color: #fff;
}

.box footer{
    display: flex;
    justify-content: flex-end;
    padding: 14.5px 0;
}

.saveBtn, .coloredSaveBtn{
    font-size: 14px;
    height: 36px;
    padding: 0 10px;
    font-family: 'DM Sans', sans-serif;
    border-radius: 5px;
    background-color: #e8d2ad;
    color: #000;
    border: 0;
    outline: 0;
    font-weight: 600;
    cursor: pointer;
}

.saveBtn:hover, .coloredSaveBtn:hover{
    background-color: #dfc69b;
}

.coloredSaveBtn{
    margin-left: 14px;
}


.saveBtn:disabled{
    border: 1px solid #424244;
    color: #8b8a8b;
    pointer-events: none;
    background-color: #2b2929;
}

.coloredSaveBtn:disabled{
    pointer-events: none;
    opacity: 0.6;

}

.loadingBtn{
    width: 113px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8d2ad;
    border-radius: 5px;
    margin-left: 14px;
}

.box.security .loadingBtn{
    width: 135px;
}

.loader{
    border: 2.5px solid #000;
    border-radius: 50%;
    border-top: 2.5px solid transparent;
    width: 25px;
    height: 25px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.fetchingLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.spinner{
    border: 3.8px solid #676667;
    border-radius: 50%;
    border-top: 3.8px solid transparent;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.cancelBtn{
    font-size: 14px;
    height: 36px;
    padding: 0 10px;
    font-family: 'DM Sans', sans-serif;
    border-radius: 5px;
    border: 1px solid #424244;
    color: #b4b2b4;
    background-color: #2b2929;
    outline: 0;
    font-weight: 600;
    cursor: pointer;;
}

.cancelBtn:hover{
    background-color: #3a3939;
}


.container{
    padding: 15px 0;
    margin: 0 6px;
}

.titleBox{
    display: flex;
    align-items: center;
}

.titleText{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
}

.titleIcon{
    display: flex;
    align-items: center;
    font-size: 17.5px;
    margin-right: 5px;
}

.box .container:nth-of-type(2) .titleIcon{
    margin-top: -2px
}

.subtitle{
    color: #dcd9d9;
    font-family: 'Poppins', sans-serif;
    font-size: 12.7px;
    line-height: 1.6;
    margin-top: 8px;
    letter-spacing: 0.1px;
}

.box .container:first-of-type{
    padding-top: 5px;
    margin-bottom: 15px;
}

.box hr{
    border: 1px solid #363535
}

.changeBtn{
    background-color: #3e3d3d;
    color: #e4e4e4;
    border: 0;
    outline: 0;
    height: 35px;
    padding: 0 12px;
    border-radius: 7px;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-top: 12px;
    cursor: pointer;
}

.changeBtn:hover{
    transform: scale(1.05,1.05);
    color: #fff;
}

.container b{
    color: #fff;
}

.inputs{
    padding-top: 20px;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
