.wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #22272b;
    padding: 40px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color-scheme: dark;
    min-width: 280px;
    overflow-x: hidden;
}

.loadingWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.validatingLoader{
    background-color: #ebd6b1;
    height: 260px;
    width: 260px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: load 2s linear infinite;
    animation: load 2s linear infinite;;
    animation-direction: alternate;
    -webkit-animation-direction: alternate;
}

.validatingLoader img{
    width: 95%;
    height: 95%;
    border-radius: 50%;
}

.loadingText{
    font-family: Century Gothic;
    color: #e3cba2;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.3px;
    -webkit-animation: max 2s linear infinite;
    animation: max 2s linear infinite;;
    animation-direction: alternate;
    -webkit-animation-direction: alternate;
}

.box{
    width: 100%;
    min-height: 73vh;
    max-width: 1100px;
    display: flex;
    align-items: stretch;
    box-shadow: rgba(112, 128, 144, 0.05) 0px 54px 55px, rgba(112, 128, 144, 0.12) 0px -12px 30px, rgba(112, 128, 144, 0.12) 0px 4px 6px, rgba(112, 128, 144, 0.17) 0px 12px 13px, rgba(112, 128, 144, 0.09) 0px -3px 5px;
    border-radius: 14px;
    background-color: #161616;
}

.box.crash{
    max-width: 520px;
    background-color: #161616;
    display: block;
    padding: 0 15px 35px;
    box-sizing: border-box;
}

.box.crash .appTitle{
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: -3px;
    margin-bottom: 14px;
}

.box.crash .logo{
    width: 28px;
    height: 28px;
}

.box.crash .logo img{
    width: 100%;
    height: 100%;
}

.box.crash .name{
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-left: 4.5px;
    font-family:'Parisienne', cursive;
    font-size: 28px;
    color: #d2b98e;
    font-weight: 600;
}

.robotImage{
    width: 290px;
    height: 290px;
    margin: 15px auto;
}

.robotImage img{
    width: 100%;
    height: 100%;
}

.box.crash .title{
    font-size: 26.7px;
    text-align: center;
}

.box.crash .submitBtn{
    margin-top: 25px;
}

.leftSection{
    width: calc(47% - 30px);
    border-radius:16px;
    background-color: #ebd6b1;
    margin: 15px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}

.imageSection{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
}

.imageSection img{
    display: block;
    width: 98%;
    aspect-ratio: 1 / 1;
}

.rightSection{
    border-radius: 0 14px 14px 0px;
    color: #fff;
    width: 53%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightSection .appTitle{
    display: none;
}

.leftSection .appTitle{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: -14px;
}

.logo{
    width: 33px;
    height: 33px;
}

.logo img{
    width: 100%;
    height: 100%;
}

.name{
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-left: 4.5px;
    font-family:'Parisienne', cursive;
    font-size: 27px;
    color: #000;
    font-weight: 600;
}

.form{
    padding: 50px 40px;
    box-sizing: border-box;
    width: 100%;
    min-height: 530px;
}

.title{
    font-family: "Sora", sans-serif;
    font-size: 36px;
    letter-spacing: 0.1px;
    font-weight: 600; 
    color: #ebd6b1;
    line-height: 1.1;
}

.instructions, .desc{
    font-family: 'Poppins', sans-serif;
    font-size: 13.1px;
    color: #d7bf96;
    letter-spacing: 0.2px;
    margin-top: 13px;
}

.desc{
    text-align: center;
    font-size: 13.6px;
    margin-top: 8px;
    line-height: 1.5;
}

.inputContainer{
    margin-top: 21px;
}

.inputs{
    padding-top: 5px;
}

.inputContainer label{
    font-size: 13.6px;
    color: #c8c3c3;
    margin-bottom: 4px;
    display: block;
    font-family: 'DM Sans', sans-serif;
}

.inputBox{
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    height: 47px;
}

.inputBox input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    border: 0;
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 12px 0 30px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    border-radius: 7px;
}

.inputBox input::placeholder{
    color: #8c8989;
}

.inputIcon{
    display: flex;
    align-items: center;
    height: 95%;
    font-size: 18px;
    position: absolute;
    left: 8px;
    color: #dedbdb;
}

.inputIcon.placeholder{
    color: #a2a1a1;
}

.errorBox{
    font-family: 'Poppins', sans-serif;
    font-size: 12.7px; 
    color: #e23535;
    margin-left: 1.5px;
    margin-top: 1px;
}

.inputBox.error input{
    border: 1.6px solid #e23535;
    color: #d53d1f;
}

.error .inputIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 17px;
    width: 18px;
    position: absolute;
    left: 8px;
    color: #d53d1f;
    margin-top: -0.7px;
}

.errorBanner, .successBanner{
    background-color: #d53d1f;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10.5px 8px;
    margin: 13px 0 -10px;
}

.successBanner{
    background-color: #0c923f;
    color: #d9f6e5;
}

.errorIcon{
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 3px;
    margin-top: -1px;
}

.successIcon{
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 3px;
    margin-top: -1.5px;
}

.errorText, .successText{
    font-size: 13.2px;
    font-family: 'Poppins', sans-serif;
}

.submitBtn{
    height: 47px;
    border: 0;
    outline: 0;
    background-color: #e8d2ad;
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 16.5px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.loadingBtn{
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8d2ad;
    border-radius: 5px;
    margin-top: 30px;
}

.loader{
    border: 3px solid #000;
    border-radius: 50%;
    border-top: 3px solid transparent;
    width: 31px;
    height: 31px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.submitBtn:disabled{
    opacity: 0.75;
    pointer-events: none;
    cursor: unset;
    z-index: 0;
    position: relative;
}

.linkWrapper{
    margin: 11px auto 0;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    color: #c8c3c3;
    text-align: center;
}

.linkWrapper a{
    font-weight: bold;
    color: #d7bf96;
    font-size: 12.8px;
    margin-left: 1px;
    text-decoration: none;
}

.linkWrapper a:hover{
    text-decoration: underline;
}

.keyIcon{
    display: none;
}

@media (max-width: 1060px){
    .leftSection{
        display: none;
    }

    .rightSection{
        width: 100%;
        display: block;
    }

    .box{
        max-width: 520px;
        width: 100%;
        min-height: unset;
        box-sizing: border-box;
    }

    .rightSection .appTitle{
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-left: 15px;
    }

    .logo{
        width: 25px;
        height: 25px;
    }

    .logo img{
        width: 100%;
        height: 100%;
    }

    .name{
        font-size: 25px;
        color: #d2b98e;
        font-weight: 600;
    }

    .form{
        padding: 5px 28px 46px;
        min-height: unset;
    }
    
    .title{
        font-size: 28.5px;
        line-height: 1;
    }

    .title span{
        display: none;
    }
    
    .instructions{
        font-size: 12.7px;
        margin-top: 6.5px;
    }
    
    .rightSection .imageSection{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .inputContainer{
        margin-top: 18px;
    }

    .wrapper{
        padding: 20px;
    }

    .linkWrapper{
        margin-top: 15px;
    }

    .keyIcon{
        width: 53px;
        height: 53px;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid #747373;
        border-radius: 18px;
        color: #262424;
        margin: 17px 0 15px;
        color: #d7bf96;
    }
}

@media (max-width: 600px){
    .validatingLoader{
        width: 230px;
        height: 230px;
    }

    .loadingText{
        font-size: 17.5px;
    }
}

@media(max-width: 540px){
    .wrapper{
        padding: 0;
    }

    .box, .box.crash{
        height: 100%;
        box-shadow: unset;
        border-radius: unset;
        max-width: unset;
    }

    .box.crash .submitBtn{
        margin-top: 15px;
    }

    .rightSection{
        height: 100%;
        border-radius: unset;
    }

    .logo{
        width: 27px;
        height: 27px;
    }

    .name{
        font-size: 27px;
    }

    .form{
        padding: 5px 20px 30px;
    }
}


@media (max-width: 460px){
    .box.crash .title{
        font-size: 24px;
    }

    .desc{
        font-size: 13.2px;
        margin-top: 6px;
    }

    .box.crash{
        padding: 0 12px 20px
    }
}


@media (max-width: 450px){
    .title{
        font-size: 24px;
    }
    
    .instructions{
        font-size: 11.8px;
    }

    .form{
        padding: 7px 12px 20px;
    }
}


@media (max-width: 420px){
    .inputContainer label{
        font-size: 13px;
    }

    .inputBox{
        height: 45px;
    }

    .inputBox input{
        font-size: 12.6px;
    }

    .inputIcon{
        font-size: 18px;
    }

    .submitBtn{
        height: 44px;
        font-size: 15.5px;
        border-radius: 4px;
        margin-top: 13px;
    }

    .loadingBtn{
        height: 44px;
        border-radius: 4px;
        margin-top: 13px;
    }

    .loader{
        width: 28px;
        height: 28px;
    }

    .linkWrapper{
        font-size: 12.5px;
    }

    .linkWrapper{
        font-size: 12.2px;
    }

    .keyIcon{
        width: 48px;
        height: 48px;
        font-size: 26px;
        border-radius: 12px;
    }

    .instructions br{
        display: none;
    }

    .errorIcon, .successIcon{
        font-size: 17px;
    }
    
    .errorText, .successText{
        font-size: 12.2px;
    }

    .errorBox{
        font-size: 12px;
    }

    .box.crash .title{
        font-size: 21px;
    }

    .desc{
        font-size: 12.7px;
    }

    .robotImage{
        width: 94%;
        aspect-ratio: 1 / 1;
        height: unset;
        max-width: 270px;
        margin: 12px auto;
    }
}

@media (max-width: 370px){
    .box.crash{
        padding: 0 8px 20px
    }

    .desc{
        font-size: 12.4px;
    }

    .successBanner{
        position: relative;
        justify-content: unset;
    }

    .successIcon{
        position: absolute;
        top: 10px;
        margin-top: 0;
    }

    .successText{
        text-indent: 19px;
    }
}

@media (max-width: 340px){
    .form{
        padding: 7px 10px 20px
    }

    .title{
        font-size: 22px;
    }
    
    .instructions{
        font-size: 11.5px;
    }

    .validatingLoader{
        width: 190px;
        height: 190px;
    }

    .loadingText{
        font-size: 16.2px;
    }
}

@-webkit-keyframes load {
    0% { 
        -webkit-transform: scale(1,1);
        background-color: #ebd6b1;
    }

    25% { 
        -webkit-transform: scale(1.05,1.05);
        background-color: #dac39b;
    }

    50% {
        -webkit-transform: scale(1.1,1.1);
        background-color: #d2b075;
    }

    75% { 
        -webkit-transform: scale(1.15,1.15);
        background-color: #dac39b;
    }
    100% {
        -webkit-transform: scale(1.2,1.2);
        background-color: #d2b075;
    }
  }
  
@keyframes load {
    0% { 
        transform: scale(1,1);
        background-color: #ebd6b1;
    }

    25% { 
        transform: scale(1.05,1.05);
        background-color: #dac39b;
    }
    50% {
        transform: scale(1.1,1.1);
        background-color: #d2b075;
    }

    75% { 
        transform: scale(1.15,1.15);
        background-color: #dac39b;
    }
    100% {
        transform: scale(1.2,1.2);
        background-color: #d2b075;
    }
  }


@-webkit-keyframes max {
    0% { 
        margin-top: 10px; 
        transform: scale(1,1);
    }

    25% { 
        margin-top: 15px;
        transform: scale(1.1,1.1);

    }
    50% {
        margin-top: 20px; 
        transform: scale(1.2,1.2);
    }

    75% { 
        margin-top: 25px; 
        transform: scale(1.3,1.3);
    }
    100% {
        margin-top: 30px; 
        transform: scale(1.4,1.4);
    }
}
  
@keyframes max {
    0% { 
        margin-top: 10px; 
        transform: scale(1,1);
    }

    25% { 
        margin-top: 15px;
        transform: scale(1.1,1.1);

    }
    50% {
        margin-top: 20px; 
        transform: scale(1.2,1.2);
    }

    75% { 
        margin-top: 25px; 
        transform: scale(1.3,1.3);
    }
    100% {
        margin-top: 30px; 
        transform: scale(1.4,1.4);
    }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}