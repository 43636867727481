.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 4;
    background-color: #000;
}

.outerWrapper{
    top: 0;
    right: calc((100vw - 125vh)/2);
    position: fixed;
    z-index:5000;

}
.wrapper.active{
    background-color: rgba(64, 65, 68, 0.55);
}

.container{
    padding: 5.4vh 1.8vh 1.8vh;
    border: 0.4vh solid #cc8116;
    border-color: #F2E6D0;
    background-color: #dcd4c4;
    box-shadow: inset 0.32vh 0.64vh 0.88vh #ada699, inset -0.32vh -0.64vh 0.88vh #ada699;
    border-radius: 1vh;
    border-top-right-radius: 6vh;
    border-top-left-radius: 6vh;
    position: relative;
    background: #1c1c1c;
}

.box {
    width: 37vh;
    min-height: 35vh;
    position: relative;
    border-radius: 1vh;
    border-top-left-radius: 3.2vh;
    border-bottom-right-radius: 3.2vh;
    border: 0.5vh solid #e2d0ae;
    border-color: #191919;
    box-shadow: inset 0.32vh 0.64vh 0.88vh #b6aa95, inset -0.32vh -0.64vh 0.88vh #b6aa95;
}


.titleBanner{
    width: 85%;
    position: absolute;
    aspect-ratio: 3.25 / 1;
    background-image: url("../media/menuBanner.png");
    background-size: 100% 100%;
    left: 7.5%;
    top: -13%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title{
    font-family: 'IM Fell English SC', serif;
    font-size: 3vh;
    font-weight: 600;
    font-style: italic;
    transform: rotate(-2deg);
    margin-top: -1.5vh;
    color: #f0dcc0;
    color: #000;
}

.items{
    padding: 2.5vh 0 0.5vh;
}

.menuItem{
    background-image: url("../media/menuItem.png");
    background-size: 100% 100%;
    width: 80%;
    aspect-ratio: 4.5 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 2vh;
    cursor: pointer;
    color: #ead1ae;
    color: #000;
    font-family: 'Almendra', serif;
    font-size: 2.24vh;
    font-weight: 600;
}

.menuItem:hover{
    transform: scale(1.08,1.08);
}

.titleWrapper{
    width: 16vh;
    float: right;
    margin-top: 4.4vh;
    margin-right: 17.5vh;
    height: 4.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuTitle{
    font-family: 'Alegreya', serif;
    font-weight: 600;
    font-size: 3.2vh;
    text-transform: uppercase;
    color: #e4d9c4;
    opacity: 0.95;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper.active .menuTitle{
    opacity: 0.4;
    pointer-events: none;
}

.menuTitle:hover{
    transform: scale(1.1,1.1);
    opacity: 1;
}

@media (max-width: 125vh){
    .titleWrapper{
        width: 13%;
        margin-right: 14%;
    }

    .menuTitle{
        font-size: 3vw;
    }

    .outerWrapper{
        right: 18.5%;
    }
}

@media (not (orientation:landscape)) and (not (height:100vw)){

    .outerWrapper{
        top: calc((100vh - 100vw)/2);
    }
    .menuTitle{
        font-size: 3.4vw;
    }

    .titleWrapper{
        margin-top: 4vh;
        height: 5.5vw;
    }

    .container{
        padding: 5.42vw 1.82vw 1.82vw;
        border-width: 0.43vw;
        box-shadow: inset 0.34vw 0.66vw 0.9vw #ada699, inset -0.34vw -0.66vw 0.9vh #ada699;
        border-radius: 1.02vw;
        border-top-right-radius: 6.03vw;
        border-top-left-radius: 6.03vw;
    }
    
    .box {
        width: 37.5vw;
        min-height: 35.2vw;
        border-radius: 1.1vw;
        border-top-left-radius: 3.22vw;
        border-bottom-right-radius: 3.22vw;
        border-width: 0.54vw;;
        box-shadow: inset 0.34vw 0.66vw 0.9vh #b6aa95, inset -0.34vw -0.66vw 0.9vh #b6aa95;
    }
    
    .title{
        font-size: 3.2vw;
    }
    
    .items{
        padding: 2.52vw 0 0.52vw;
    }
    
    .menuItem{
        margin: 0 auto 2.2vw;
        font-size: 2.26vw;
    }
}