.wrapper{
    top: 0;
    left: 0;
    position: fixed;
    background-color: #000;
    width: 100%;
}

.results{
    position: absolute;
    bottom: 6vh;
    width: 100%;
}

.box{
    aspect-ratio: 1.1 / 1;
    height: 100vh;
    background-image: url('../media/newBackground.png');
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
}

.spread{
    position: absolute;
    top: 11.5vh;
    left: 2.5vh
}

.intention{
    position: absolute;
    top: 11.5vh;
    right: 2.5vh
}

.gameTitle{
    position: absolute;
    top: 4.5vh;
    left: 15%;
    width: 11.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuBtn{
    position: absolute;
    top: 4.5vh;
    right: 13%;
    width: 13.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; 
}

.gameTitle, .menuBtn{
    font-family: 'Alegreya', serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-transform: uppercase;
    color: #e4d9c4;
    height: 5.5vh;
}

.menuBtn:hover{
    transform: scale(1.2,1.2);
}

.selectedCardContainer{
    perspective: 1000px;
    position: absolute;
}

.selectedCard{
    width: 8.46vh;
    height: 12.96vh;
    border-radius: 1vh;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s; /* Smooth flip transition */
}
  
.selectedCardContainer.flipped .selectedCard{
    transform: rotateY(180deg); /* Flip on hover */
}
  
.front, .back{
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
  }
  
.front {
    border: 0.23vh solid #323131;
    border-radius: inherit;
}
  
.back {
    transform: rotateY(180deg); /* Pre-rotate the back side */
    border-radius: inherit;
}

.back img{
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.back.reversed img{
    transform: scaleY(-1);
}

.selectedCards[length="3"] .selectedCard{
    width: 9.46vh;
    height: 14.46vh;
}

.selectedCards .selectedCardContainer:first-of-type{
    top: 38vh;
    left: 40%;
}

.selectedCards[length="3"] .selectedCardContainer:first-of-type{
    top: 37vh;
    left: 45%;
}

.selectedCards[length="3"] .selectedCardContainer:nth-of-type(2){
    top: 53vh;
    left: 38%;
}

.selectedCards[length="3"] .selectedCardContainer:last-of-type{
    top: 53vh;
    left:52%;
}

/* .selectedCards[length="3"] .selectedCard:first-of-type{
    left: 45%;
}

.selectedCards[length="3"] .selectedCard:nth-of-type(2){
    top: 58vh;
    left: 40%;
}

.selectedCards[length="3"] .selectedCard:last-of-type{
    left: 50%;
    top: 58vh;
} */

.selectedCards .selectedCardContainer:nth-of-type(2){
    top: 38vh;
    left: 50%;
}

.selectedCards .selectedCardContainer:nth-of-type(3){
    top: 53vh;
    left: 36%;
}

.selectedCards .selectedCardContainer:nth-of-type(4){
    top: 53vh;
    left: 46%;
}

.selectedCards .selectedCardContainer:last-of-type{
    top: 53vh;
    left: 56%;
}

.cardStackWrapper{
    top: 44vh;
    right: 3%;
    position: absolute;
}

.cardStack {
    position: relative;
    width: 78px;  /* Adjust the width as needed */
    height: 97px; /* Adjust the height as needed */
    perspective: 1200px;
  }
  
  .cardStackItem{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: white; */
    border: 1px solid #e4bb89;;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.3s, width 0.3s, height 0.3s, top 0.3s, left 0.3s;
    transform-style: preserve-3d;
    background-image: url("../media/tarotCard.PNG");
    background-size: 100% 100%;
    transform-origin: center center; /* Adjust origin for rotation */
    /* transform: rotateX(-3deg) translateZ(calc(100px - var(--num) * 1px));; */
    /* transform: translateZ(calc(78px - var(--num))); */
  }


.cardsWrapper{
    left: -5%;
    position: absolute;
    bottom: 15vh;
    width: 100%;
}

.cards{
    position: relative;
    width: 100%;  /* This should be wide enough to contain the fanned-out cards */
    height: 300px; /* This should be tall enough to contain the fanned-out cards */
    /* perspective: 1500px; */
  }
  
  .card {
    position: absolute;
    bottom: 0;
    left: 50%;   /* Start at the center of the stack */
    width: 8.4%; /* Width of a single card */
    aspect-ratio: 100 / 140;
    background-color: white;
    border: 0.17vh solid #e4bb89;;
    border-radius: 0.8vh;
    box-shadow: 0 0.1vh 0.2vh rgba(0,0,0,0.1);
    transition: transform 0.5s, width 0.5s, height 0.5s, top 0.5s, left 0.5s;
    transform-origin: bottom center;
    transform-style: preserve-3d;
    background-image: url("../media/tarotCard.PNG");
    background-size: 100% 100%;
    cursor: pointer;
    /* --i: var(--num) + 1;
    --totalCards: 78;
    --fanSpread: 18;
    --spacing: 85;
    --rotation: calc(((var(--fanSpread) / (var(--totalCards) - 1)) * i) - (var(--fanSpread) / 2));
    --translateX: calc(var(--spacing) * (var(--i) - (var(--totalCards) - 1) / 2)); */
    transform: rotateZ(var(--rotation)) translateX(var(--translateX))
  }


.card:hover{
    transform: rotateZ(0deg) translateX(var(--hoveredTranslate)) translateY(-6%);
}

.card.rising{
    transform: rotateZ(0deg) translateX(var(--translateX)) translateY(-90%) scale(0.8);
    pointer-events: none;
    position: fixed;
    z-index: 4;
} 

.card.moving{
    pointer-events: none;
    position: fixed;
    transform: scale(1);
    z-index: 4;
}