.menu{
    width: 185px;
    min-width: 185px;
    background-color: #282828;
    margin-right: 0;
    border-radius: 10px 0 0 10px;
}

.title{
    padding: 18px 7.5px;
    font-family:-apple-system,system-ui,"Segoe UI",Roboto,Noto,Oxygen-Sans,Ubuntu,Cantrell,"Helvetica Neue",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 600;
    font-size: 21px;
    text-align: center;
    color: #ccc8c8;
}

.menuItems{
    padding: 5px 6px 5px;
}

.menuItem{
    display: flex;
    align-items: center;
    padding: 10px 12px;
    margin-bottom: 5.3px;
    color: #ccc8c8;
    cursor: pointer;
}

.menuItem:hover{
    background-color: #191818;
    border-radius: 10px;
}

.menuItem.active{
    background-color: #353535;
    border-radius: 5px;
    border: 1.7px solid rgb(102, 99, 99);
    box-sizing: border-box;
    color: #fff
}

.menuIcon{
    display: flex;
    align-items: center;
    margin-right: 3px;
    font-size: 15.2px;
}


.menuText{
    font-family: 'DM Sans', sans-serif;
    font-size: 13.8px;
}
