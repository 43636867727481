.wrapper{
    display: flex;
    overflow: hidden;
    height: 100vh;
}

.box{
    height: 100%;
    max-height: 100%;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.box main{
    flex-grow: 1;
    padding: 15px 0 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.box header{
    height: 53px;
    min-height: 53px;
    width: 100%;
    box-sizing: border-box;
    padding:  0 15px;
    background-color: #272525;
    border-bottom:1.1px solid #484848;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:#f5e3c5;
}

.mainHeader{
    display: flex;
    align-items: center;
}

.playerBtn{
    padding: 7px 9px;
    font-family: 'Poppins', sans-serif;
    font-size: 12.2px;
    color: #e0c8a0;
    border-radius: 3px;
    background-color: #3d3b3b;
    cursor: pointer;
}

.playerBtn:hover{
    transform: scale(1.1,1.1);
}

.content{
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

}

.tableContent{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow-x: auto;
}

.skeleton .tableContent{
    overflow-x: hidden;
}

.content::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
	border-radius: 6px;
	background-color: #F5F5F5;
}

.content::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.content::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	-webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.3);
	background-color: #D62929;
}



.table{
    width: 100%;
    margin-top: 3px;
    border-radius: 5px;
    overflow: hidden;
    max-height: calc(100% - 3px);
    display: flex;
    flex-direction: column;
    border: 1px solid #444;
}

.content th, 
.content td {
    padding: 10px;
    text-align: left;
    border: 1px solid #444;
    box-sizing: border-box;
}

.content td{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tableHeader{
    background-color: #363636;
    border-radius: 5px 5px 0 0;
    font-family: 'DM Sans', sans-serif;
    width: 100%;
    display: flex;
    align-items: stretch;
    box-sizing: border-box;
    min-width: 1100px;
}

.transactions .tableHeader, .transactions .tableBody{
    min-width: 1000px;
}

.dreams .tableHeader, .dreams .tableBody, .tarots .tableHeader, .tarots .tableBody{
    min-width: 1150px;
}

.pricing .tableHeader, .pricing .tableBody, .promo .tableHeader, .promo .tableBody{
    min-width: 1220px;
}

.tableBody{
    overflow-y: auto;
    flex-grow: 1;
    width: 100%;
    min-width: 1100px;
}

.skeleton .tableBody{
    overflow-y: hidden;
}

.row{
    display: flex;
}

.headerCol{
    font-size: 12.7px;
    color: #d1cece;
    font-weight: normal;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px;
    text-align: left;
    border-right: 1px solid #444;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.tableHeader .headerCol:last-of-type{
    border: 0;
}

.bodyCol{
    font-family: 'Poppins', sans-serif;
    font-size: 11.8px;
    color: #edebeb;
    padding: 10px;
    text-align: left;
    border-right: 1px solid #444;
    box-sizing: border-box;
}

.row .bodyCol:last-of-type{
    border: 0;
}

.headerCol.status, .headerCol.role, .headerCol.balance, .headerCol.num_dreams, .headerCol.num_tarots, .headerCol.num_questions, .headerCol.updated_at, .headerCol.created_at, .headerCol.diamonds_spent, .headerCol.num_diamonds, .headerCol.effective_from, .headerCol.effective_till,
.bodyCol.status, .bodyCol.role, .bodyCol.balance, .bodyCol.num_dreams, .bodyCol.num_tarots, .bodyCol.num_questions, .bodyCol.updated_at, .bodyCol.created_at, .bodyCol.diamonds_spent, .bodyCol.num_diamonds, .bodyCol.effective_from, .bodyCol.effective_till,
.bodyCol.exchange_rate, .headerCol.exchange_rate, .bodyCol.type, .headerCol.type, .bodyCol.amount, .headerCol.amount, .headerCol.timestamp, .bodyCol.timestamp{
    text-align: center;
    justify-content: center;
}

.content td{
    font-family: 'Poppins', sans-serif;
    font-size: 11.8px;
    color: #edebeb
}

.content tbody{
    overflow-y: auto;
    display: block;
    height: calc(100% - 90px);
}

.content tr{
    display: table;
    width: 100%;
}

.content thead tr{
    border-radius: 5px 5px 0 0;
}

.content thead th:first-of-type{
    border-top-left-radius: 5px;
}

.tableBody .row:nth-of-type(even) {
    background-color: #363636;
}

.tableBody .row:nth-of-type(odd)  {
    background-color: #1b1b1b;
}


.content tbody tr:hover {
    background-color: #2c2b2b;
}

.title{
    font-family: 'Poppins', sans-serif;
    font-size: 15.5px;
    margin-left: 7px;
}

.userIcon{
    display: flex;
    align-items: center;
    font-size: 20.5px;
}

.dreamIcon{
    display: flex;
    align-items: center;
    font-size: 17.5px;
    margin-top: -2px;
}

.tarotIcon{
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-top: -5px;
}

.categories{
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    border-bottom:1.1px solid #484848;
    padding: 0 0 10px;
    margin: 0 15px;
}

.category{
    display: flex;
    align-items: center;
    margin-right: 14px;
    padding-bottom: 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.searching.category{
    pointer-events: none;
    min-width: 18px;
    min-height: 18px;
}

.category.selected, .category:hover{
    border-bottom: 1.4px solid #e8d2ad;
    padding-bottom: 3.6px;
}

.categories .category:last-of-type{
    margin-right: 0;
}

.categoryTitle{
    font-family: 'DM Sans', sans-serif;
    font-size: 13.5px;
    color:#e8d2ad;
}

.categoryLength{
    margin-left: 4.5px;
    background-color: #424141;
    font-size: 11px;
    padding: 2.5px 5px;
    border-radius: 3px;
    font-family: 'Lato', sans-serif;
    color:#f5e3c5;
}

.searching .categoryLength{
    min-width: 22px;
    min-height: 17.8px;
    box-sizing: border-box;
}

.lengthBox{
    margin-left: 4.5px;
    background-color: #424141;
    font-size: 13.5px;
    padding: 4px 7px;
    border-radius: 4px;
    font-family: 'Lato', sans-serif;
    color:#f5e3c5;
    margin-left: 8px;
    font-weight: 600;
}

.searchWrapper{
    padding: 15px;
    display: flex;
    align-items: center;
}

.btns{
    display: flex;
    align-items: center;
}

.searchWrapper.noTopPadding{
    padding-top: 5px;
}

.searchWrapper.noTopPadding .searchBox{
    margin-right: 10px;
}

.searchBox{
    position: relative;
    height: 36px;
    width: 40%;
    max-width: 450px;
    margin-right: 25px;
    min-width: 310px;
}

.searchIcon{
    position: absolute;
    left: 8px;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
}

.searchBox input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    border: 1.65px solid #353535;
    padding-left: 25px;
    padding-right: 8px;
    box-sizing: border-box;
    font-size: 12.1px;
    font-family: 'Poppins', sans-serif;
    border-radius: 6px;
    background-color:rgba(255, 255, 255, 0.1);
}

.sortBtnWrapper{
    position: relative;
    outline: 0;
    border: 0;
}

.sortIcon{
    display: none;
}

.sortMenu{
    position: absolute;
    top: 35px;
    left: 0;
    width: 225px;
    box-shadow: 0 0 0 1px #424244;
    background-color: #1e1f21;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 7px;
    padding: 4px 0;
}

.sortItem{
    font-family: 'Poppins', sans-serif;
    font-size: 11.9px;
    padding: 5px 6px;
    cursor: pointer;
}

.sortItem:hover{
    background-color: #363333;
}

.sortItem span{
    font-size: 11.3px;
    color: #a19d9d
}

.sortBtn{
    display: flex;
    align-items: center;
    background-color: #363333;
    height: 31px;
    padding: 0 6px;
    margin-right: 8px;
    border-radius: 6px;
    cursor: pointer;
}

.addBtn{
    display: flex;
    align-items: center;
    margin-left: 10px; 
    background-color: #e0c8a0;
    color: #000;
    padding: 0 10px;
    height: 31px;
    margin-left: 3px;
    border-radius: 3px;
    cursor: pointer;
}

.addText{
    font-family: 'Poppins', sans-serif;
    font-size: 12.5px;
    font-weight: 600;
    margin-left: 4px;
}

.sortBtn .text{
    font-size: 12.3px;
    margin-right: 4.5px;
    font-family: 'Poppins', sans-serif;
}

.arrow{
    display: flex;
    align-items: center;
    font-size: 14.5px;   
}

.addIcon{
    display: flex;
    align-items: center;
    font-size: 15.5px;
    margin-top: -4px;
}

.currencyIcon{
    font-size: 17.5px;
    margin-top: 1px;
    display: flex;
    align-items: center;
}

.statusBox, .spreadBox, .codeStatusBox, .countryBox{
    padding: 4px 6.5px;
    border-radius: 12px;
    background-color: #4a4747;
    display: inline-flex;
    font-size: 11.5px;
    color: #fff;
    width: fit-content;
}

.countryBox{
    font-size: 11px;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    max-width: 100%;
}

.countryFlag{
    width: 17.5px;
    margin-top: -1px;
    margin-right: 2.5px;
}

.countryName{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.codeStatusBox{
    border-radius: 5px;
}

.spreadIcon{
    display: flex;
    align-items: center;
    font-size: 12.5px;
    margin-right: 5px;
}

.statusBox.admin{
    background-color: #e0c8a0;
    color: #000;
    font-weight: 600;
}

.statusBox.active, .codeStatusBox.active{
    color: #e0c8a0;
    background-color: #4e4638;
}

.content tr td:nth-of-type(4),
.content tr th:nth-of-type(4),
.content tr td:nth-of-type(5),
.content tr th:nth-of-type(5),
.content tr td:nth-of-type(6),
.content tr th:nth-of-type(6){
    text-align: center;
}

.content tr td.id_number{
    text-align: center;
}

.bodyCol.num_tarots, .headerCol.num_tarots{
    width: 137px;
    min-width: 137px;
}

.bodyCol.country, .headerCol.country{
    width: 240px;
    overflow: hidden;
}

.bodyCol.invoice, .headerCol.invoice{
    width: 290px;
    overflow: hidden;
    min-width: unset;
}

.bodyCol.type, .headerCol.type{
    width: 120px;
    min-width: unset;
}

.bodyCol.amount, .headerCol.amount{
    width: 100px;
}

.bodyCol.spread, .headerCol.spread{
    min-width: 230px;
    width: calc(0.4 * (100% - 720px));
}

.bodyCol.code, .headerCol.code{
    width: calc(100% - 1035px);
    min-width: calc(100% - 1035px);
    word-break: break-all;
}

.content tr td.created_at,.content tr th.created_at,.content tr td.start_date,.content tr th.start_date,.content tr td.end_date,.content tr th.end_date{
    min-width: 136px;
    max-width: 140px;
}


.bodyCol.updated_at,.headerCol.updated_at, .bodyCol.created_at, .headerCol.created_at {
    width: 150px;
}

.bodyCol.num_diamonds,.headerCol.num_diamonds{
    width: 90px;
}

.bodyCol.user,.headerCol.user{
    width: calc(100% - 780px);
    min-width: calc(100% - 780px);
}

.bodyCol.effective_from, .headerCol.effective_from, .bodyCol.effective_till, .headerCol.effective_till{
    width: 160px;

}

.bodyCol.timestamp, .headerCol.timestamp{
    width: 180px;

}

.bodyCol.game_id, .headerCol.game_id,.bodyCol.code_id, .headerCol.code_id,.bodyCol.pricing_id, .headerCol.pricing_id {
    width: 195px;
    min-width: 195px;
}

.bodyCol.exchange_rate, .headerCol.exchange_rate {
    width: 100px;
    min-width: 100px;
}

.bodyCol.diamonds_spent, .headerCol.diamonds_spent{
    width: 105px;
    min-width: 105px;
}

.bodyCol.num_questions, .headerCol.num_questions{
    width: 120px;
}

.bodyCol.num_dreams, .headerCol.num_dreams{
    width: 140px;
    min-width: 140px;
}

.bodyCol.balance, .headerCol.balance{
    width: 90px;
    min-width: 90px;
}

.bodyCol.status, .headerCol.status{
    width: 110px;
    min-width: 110px;
}

.users .bodyCol.status, .users .headerCol.status{
    width: 100px;
    min-width: 100px;
}

.bodyCol.role, .headerCol.role{
    width: 100px;
    min-width: 100px;
}


.bodyCol.name, .headerCol.name{
    width: calc((100% - 657px) / 2);
}

.bodyCol.gameName, .headerCol.gameName, .bodyCol.player, .headerCol.player{
    width: calc((100% - 720px) / 2);
}

.tarots .bodyCol.player, .tarots .headerCol.player{
    width: calc(0.6 * (100% - 720px));
    min-width: calc(0.6 * (100% - 720px));
}

.pricing .bodyCol.country, .pricing .headerCol.country{
    width: calc(0.65 * (100% - 815px));
    min-width: calc(0.65 * (100% - 815px));
}

.bodyCol.currency, .headerCol.currency{
    width: calc(0.35 * (100% - 815px));
    min-width: calc(0.35 * (100% - 815px));
}

.bodyCol.email, .headerCol.email{
    width: calc((100% - 557px) / 2);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bodyCol.actions, .headerCol.actions{
    width: 80px
}

.tableFooter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 11.8px;
    color: #edebeb;
    border-top: 1px solid #444;
    padding: 10px;
    box-sizing: border-box;
    border-radius:  0 0 5px 5px;
}

.pager{
    display: flex;
    align-items: center;
    height: 29px;
    border: 1.65px solid #353535;
    border-radius: 4.5px;
    box-sizing: border-box;
}

.pageArrow{
    height: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d1cece;
    font-size: 17.5px;
}

.pageArrow.prev{
    border-right: 1.65px solid #353535;
}

.pageArrow.next{
    border-left: 1.65px solid #353535;
}

.pager input{
    outline: 0;
    border:0;
    padding: 0 4px;
    width: 24px;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 11.8px;
    color: #edebeb;
}

.pager input:focus{
    border: 1.3px solid #585757;
    margin-left: 4px;
    border-radius: 5px;
}

.pagerBox{
    padding-right: 6px;
}

.displayedLength{
    font-size: 11.5px;
}

.btns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.editBtn, .deleteBtn{
    width: 21px;
    height: 21px;
    border: 1.65px solid #545252;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.5px;
    border-radius: 4.5px;
    cursor: pointer;
}

.editBtn:hover, .deleteBtn:hover{
    transform: scale(1.1,1.1);
}

.editBtn{
    margin-right: 8px;
}

.content tbody tr:nth-of-type(even) .editBtn, .content tbody tr:nth-of-type(even) .deleteBtn{
    border-color:#636161
}

.status .itemWrapper, .balance .itemWrapper, .num_dreams .itemWrapper, .num_tarots .itemWrapper, .timestamp .itemWrapper, .type .itemWrapper, .amount .itemWrapper, .role .itemWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemWrapper{
    width: 100%;
}

.item{
    height: 23px;
    width: 60px;
    border-radius: 22px;
    background-color: #424141;
}

.skeletonLength{
    background-color: #424141;
    width: 126px;
    height: 17.4px;
    border-radius: 12px;
}

.skeleton .editBtn, .skeleton .deleteBtn{
    background-color: #424141;
}

.content tbody tr:nth-of-type(even) .item, .skeleton.content tbody tr:nth-of-type(even) .editBtn, .skeleton.content tbody tr:nth-of-type(even) .deleteBtn{
    background-color: #4e4c4c;
}

.name .item, .email .item, .player .item, .gameName .item, .code .item, .user .item{
    width: 80%;
}

.game_id .item, .pricing .country .item, .invoice .item{
    width: 60%;
}

.content tbody tr:nth-of-type(odd):hover .item, .skeleton.content tbody tr:nth-of-type(odd):hover .editBtn, .skeleton.content tbody tr:nth-of-type(odd):hover .deleteBtn{
    background-color: #1b1b1b;
}

.content tbody tr:nth-of-type(even):hover, .skeleton.content tbody tr:nth-of-type(even):hover .editBtn, .skeleton.content tbody tr:nth-of-type(even):hover .deleteBtn{
    background-color: #242323;
}

.skeletonCategory{
    width: 45px;
    height: 23px;
    margin-right: 14px;
    background-color: #363636;
    border-radius: 6px;
}

.skeleton .searchBox{
    background-color: #363636;
    border-radius: 6px;
}

.skeletonSortBtn{
    width: 74px;
    height: 31px;
    border-radius: 6px;
    margin-right: 8px;
    background-color: #363636;
}

.skeletonAddBtn{
    width: 97px;
    height: 31px;
    border-radius: 3px;
    background-color: #363636;
}

@media(max-width: 1440px){
    .bodyCol.spread, .headerCol.spread{
        width: 230px;
    }

    .tarots .bodyCol.player, .tarots .headerCol.player{
        width: calc(100% - 950px);
        min-width: calc(100% - 950px);
    }

    .bodyCol.gameName, .headerCol.gameName, .bodyCol.player, .headerCol.player{
        width: calc((100% - 705px) / 2);
    }

    .pricing .bodyCol.country, .pricing .headerCol.country{
        width: calc(0.65 * (100% - 730px));
        min-width: calc(0.65 * (100% - 730px));
    }
    
    .bodyCol.currency, .headerCol.currency{
        width: calc(0.35 * (100% - 730px));
        min-width: calc(0.35 * (100% - 730px));
    }

}

@media(min-width: 1340px){
    .bodyCol.invoice, .headerCol.invoice, .bodyCol.user, .headerCol.user{
        width: calc(0.4* (100% - 370px));
        min-width: calc(0.4* (100% - 370px));
    }

    .bodyCol.type, .headerCol.type{
        width: calc(0.2* (100% - 370px));
        min-width: calc(0.2* (100% - 370px));
        text-align: left;
        justify-content: start;
    }

    .type .itemWrapper{
        justify-content: start;
    }
}


@media(max-width: 700px){

    .row{
        display: block;
    }

    .tableHeader{
        display: none;
    }

    .tableBody, .dreams .tableBody, .tarots .tableBody, .promo .tableBody, .pricing .tableBody, .transactions .tableBody{
        min-width: unset;
    }

    .bodyCol{
        text-align: right;
        position: relative;
        border: 0;
        border-bottom: 1px solid #444;
    }

    .tableBody .row:last-of-type .bodyCol:last-of-type{
        border: 0;
    }


    .bodyCol::before {
        content: attr(data-label);
        position: absolute;
        left: 10px;
        width: 150px;
        padding-left: 0px;
        font-weight: bold;
        text-align: left;
        height: 100%;
        border-right: 1px solid #444;
        top: 0;
        display: flex;
        align-items: center;
        text-transform: capitalize;
    }

    .promo .bodyCol::before, .pricing .bodyCol::before, .transactions .bodyCol::before{
        width: 105px;
    }

    .bodyCol.num_dreams, .bodyCol.balance, .bodyCol.status, .users .bodyCol.status, .bodyCol.role, .bodyCol.name, .bodyCol.email, .bodyCol.actions, .bodyCol.num_tarots,
    .bodyCol.game_id, .bodyCol.gameName, .bodyCol.player, .bodyCol.num_questions, .bodyCol.created_at, .bodyCol.updated_at, .bodyCol.diamonds_spent,
    .tarots .bodyCol.player, .bodyCol.spread,
    .bodyCol.exchange_rate, .pricing .bodyCol.country, .bodyCol.currency, .bodyCol.pricing_id
    {
        width: 100%;
        max-width: unset;
        min-width: unset;
        padding-left: 168px;
    }

    .bodyCol.code_id,.bodyCol.code, .bodyCol.effective_from, .bodyCol.effective_till, .bodyCol.num_diamonds, .promo .bodyCol.status, .bodyCol.country,
    .bodyCol.pricing_id, .bodyCol.exchange_rate, .pricing .bodyCol.country,
    .bodyCol.invoice, .bodyCol.timestamp, .bodyCol.type, .bodyCol.amount, .transactions .bodyCol.num_diamonds, .bodyCol.user
    {
        padding-left: 123px;
        width: 100%;
        max-width: unset;
        min-width: unset;
    }

    .bodyCol.status, .users .bodyCol.status, .bodyCol.role, .bodyCol.balance, .bodyCol.num_dreams, .bodyCol.num_tarots, .bodyCol.num_questions, .bodyCol.updated_at, .bodyCol.created_at, .bodyCol.diamonds_spent,
    .bodyCol.num_diamonds, .bodyCol.effective_from, .bodyCol.effective_till, .bodyCol.exchange_rate, .bodyCol.type{
        text-align: right;
    }

    .tableBody .row:nth-of-type(even) {
        background-color: #333131;
    }

    .itemWrapper{
        display: flex;
        justify-content: flex-end;
    }

    .status .itemWrapper,  .role .itemWrapper, .balance .itemWrapper, .num_dreams .itemWrapper, .num_tarots .itemWrapper, .timestamp .itemWrapper, .amount .itemWrapper, .type .itemWrapper{
        display: flex;
        justify-content: flex-end;
    }
}

@media(max-width: 650px){
    .box header{
        padding-left: 47px;
    }
}

@media(max-width: 600px){
    .addText{
        display: none;
    }

    .addBtn{
        padding: 0;
        width: 28px;
        min-width: 28px;
        height: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .currencyIcon{
        margin-top: 0;
    }

    .skeletonAddBtn{
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }

    .addIcon{
        font-size: 14px;
    }

    .sortBtn .text, .sortBtn .arrow{
        display: none;
    }

    .sortIcon{
        display: flex;
        align-items: center;
        font-size: 13px;
    }

    .sortBtn{
        padding: 0;
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
    }

    .skeletonSortBtn{
        height: 28px;
        width: 28px;
        margin-right: 4px;
    }

    .searchWrapper{
        justify-content: space-between;
    }

    .searchBox{
        width: calc(100% - 40px);
    }

    .users .searchBox{
        width: calc(100% - 90px);
    }

}

@media(max-width: 450px){
    .bodyCol::before {
        left: 5px;
        width: 130px;
    }

    .bodyCol.num_dreams, .bodyCol.balance, .bodyCol.status, .bodyCol.role .bodyCol.name, .bodyCol.email, .bodyCol.actions, .bodyCol.num_tarots,
    .bodyCol.game_id, .bodyCol.gameName, .bodyCol.player, .bodyCol.num_questions, .bodyCol.created_at, .bodyCol.updated_at, .bodyCol.diamonds_spent,
    .tarots .bodyCol.player, .bodyCol.spread{
        padding-left: 140px;
    }

    .bodyCol{
        font-size: 11.4px;
    }

    .promo .bodyCol::before, .pricing .bodyCol::before, .transactions .bodyCol::before{
        width: 90px;
    }

    .bodyCol.code_id,.bodyCol.code, .bodyCol.effective_from, .bodyCol.effective_till, .bodyCol.num_diamonds, .promo .bodyCol.status, .bodyCol.country,
    .bodyCol.pricing_id, .bodyCol.exchange_rate, .pricing .bodyCol.country,
    .bodyCol.invoice, .bodyCol.timestamp, .bodyCol.type, .bodyCol.amount, .transactions .bodyCol.num_diamonds, .bodyCol.user{
        padding-left: 108px;
    }

    .mainHeader{
        top: 55px;
        left: 0;
        position: fixed;
        padding: 10px 15px;
        border-bottom: 1.1px solid #484848;
        width: 100%;
    }

    .wrapper main{
        padding-top: 55px;
    }

    .wrapper header{
        justify-content: flex-end;
    }

    .category, .skeletonCategory{
        margin-right: 10px
    }
}

@media(max-width: 420px){
    .users .searchWrapper, .promo .searchWrapper, .pricing .searchWrapper, .transactions .searchWrapper{
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }

    .users .btns, .promo .btns, .pricing .btns, .transactions .btns{
        order: 1;
        justify-content: flex-end;
    }

    .users .searchBox, .promo .searchBox, .pricing .searchBox, .transactions .searchBox{
        order: 2;
        width: 100%;
        margin-top: 10px;
    }

    .content{
        padding: 0 8px;
    }

    .searchWrapper{
        padding: 10px 8px;
    }

    .categories{
        width: calc(100% - 16px);
        margin: 0 8px;
    
    }

    .title{
        font-size: 14px;
    }

    .lengthBox{
        font-size: 12px;
    }

    .userIcon{
        font-size: 19px;
    }

    .dreamIcon{
        font-size: 16px;
    }

    .giftIcon{
        font-size: 15px;
    }

    .playerBtn{
        padding: 6px 8px;
        font-size: 11.5px;
    }

    .displayedLength{
        font-size: 11.2px;
    }

    .pager{
        height: 27px;
    }

    .pageArrow{
        font-size: 16.5px;
    }

    .pager input{
        font-size: 11.4px;
    }

    .categoryTitle{
        font-size: 12.5px;
    }

    .skeletonCategory{
        height: 22px;
        width: 43px;
    }

    .categoryLength{
        font-size: 10.5px;
    }

    .item{
        height: 22px;
    }

    .mainHeader{
        top: 52px;
        padding: 10px 8px;
    }

    .dreams main{
        padding-top: 52px;
    }

    .searchBox{
        min-width: unset;
    }

    .spreadBox{
        align-items: start;
        font-size: 11px;
        text-align: left;
    }

    .spreadIcon{
        font-size: 11.5px;
        margin-top: 2px;
    }


}