.box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.wrapper{
    width: 100%;
    height: 100vh;
}

.header{
    height: 62px;
    min-height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
}

.subHeader{
    height: 55px;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    border-top: 0.85px solid #383636;
    border-bottom: 0.85px solid #383636;
}

.title{
    min-width: 148.5px;
    height: 28.5px;
    background-color: #353535;
    border-radius: 4px;
}

.searchBox{
    width: 40%;
    background-color: #353535;
    height: 40px;
    border-radius: 25px;
    min-width: 400px;
}

.profileArea{
    width: 35px;
    height: 35px;
}

.searchWrapper.min{
    display: none;
}

.lengthBox{
    background-color: #353535;
    width: 126.5px;
    height: 23px;
    border-radius: 3px;
}

.btns{
    display: flex;
    align-items: center;
}

.sortBtn{
    width: 69px;
    height: 33px;
    border-radius: 15px;
    background-color: #353535;
    margin-right: 14px;
}

.filterBtn{
    width: 74.9px;
    height: 33px;
    border-radius: 15px;
    background-color: #353535;
    margin-right: 14px;
}

.addBtn{
    width: 119.85px;
    height: 37px;
    border-radius: 4px;
    background-color: #353535;
}

.grid{
    flex-grow: 1;
    overflow-y: hidden;
}

.dreams{
    padding: 20px 18px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.dreamBox{
    width: calc((100% - 60px)/4);
    min-width: calc((100% - 60px)/4);
    margin-right: 20px;
    margin-bottom: 25px;
}

.dreamContainer{
    box-shadow: 0 0 0 1px #424244;
    background-color: #1f1f1f;
    border-radius: 6px;
    border: 0.5px solid #363535;
    box-sizing: border-box;
    padding: 8.5px 10px 0 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.imageSection{
    width: 100%;
    min-width: 100%;
    aspect-ratio: 2.8 / 1;
    max-height: 155px;
    min-height: 100px;
    background-color: #323233;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content{
    padding: 10px 0;
}

.dateBox{
    width: 208px;
    height: 22px;
    border-radius: 7px;
    background-color: #353535;
}

.dreamContent{
    margin-top: 3.5px;
}

.dreams .dreamBox:nth-of-type(4n){
    margin-right: 0;
}

.titleBox{
    margin: 10px 0 8px;
    height: 19px;
    background-color: #353535;
    width: 80%;
    border-radius: 4px;
}

.dreamLine{
    width: 100%;
    height: 12px;
    border-radius: 12px;
    background-color: #353535;
}

.dreamContent .dreamLine:nth-of-type(2){
    margin-bottom: 5.5px;
}

.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.85px solid #414040;
    padding: 10px 0;
    color: #ccc8c8;
}

.dateDiv{
    width: 55px;
    height: 17px;
    border-radius: 5px;
    background-color: #353535;
}


@media (max-width: 1400px){
    .dreamBox{
        width: calc((100% - 45px)/4);
        min-width: calc((100% - 45px)/4);
        margin-right: 15px;
    }
}


@media (max-width: 1320px){
    .dreamBox{
        width: calc((100% - 40px)/3);
        min-width: calc((100% - 40px)/3);
        margin-right: 20px;
    }

    .dreams .dreamBox:nth-of-type(4n){
        margin-right: 20px;
    }

    .dreams .dreamBox:nth-of-type(3n){
        margin-right: 0px;
    }
}

@media (max-width: 1000px){
    .dreamBox{
        width: calc((100% - 25px)/2);
        min-width: calc((100% - 25px)/2);
        margin-right: 25px;
    }

    .dreams .dreamBox:nth-of-type(4n){
        margin-right: 25px;
    }

    .dreams .dreamBox:nth-of-type(3n){
        margin-right: 25px;
    }

    .dreams .dreamBox:nth-of-type(2n){
        margin-right: 0;
    }
}

@media (max-width: 850px){
    .dreams{
        padding: 16px 14px;
    }
}

@media (max-width: 820px){
    .dreamBox{
        width: calc((100% - 15px)/2);
        min-width: calc((100% - 15px)/2);
        margin-right: 15px;
    }

    .dreams .dreamBox:nth-of-type(4n){
        margin-right: 15px;
    }

    .dreams .dreamBox:nth-of-type(3n){
        margin-right: 15px;
    }

    .dreams .dreamBox:nth-of-type(2n){
        margin-right: 0;
    }
}

@media (max-width: 750px){
    .title{
        min-width: 128.5px;
        height: 23.5px;
    }

    .profileArea{
        width: 34px;
        height: 34px;
    }

    .subHeader{
        height: 48px;
        min-height: 48px;
        border-top: 0.6px solid #3c3a3a;
        padding: 0 6px;
        margin: 0 8px;
    }

    .header{
        height: 58px;
        min-height: 58px;
    }

    .searchBox{
        display: none;
    }

    .sortBtn, .filterBtn{
        width: 30px;
        height: 30px;
        border-radius: 4.5px;
        margin-right: 13px;
    }

    .addBtn{
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .searchWrapper.min{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border-bottom: 0.6px solid #3c3a3a;
        border-top: 0.7px solid #3c3a3a;
    }

    .min .searchBox{
        display: flex;
        width: 100%;
        border-radius: 7px;
        min-width: unset;
    }   
}

@media (max-width: 640px){
    .dreamBox{
        width: 100%;
        margin: 0 auto 22px;
        max-width: 505px;
    }

    .dreams .dreamBox:nth-of-type(4n){
        margin-right: auto;
    }

    .dreams .dreamBox:nth-of-type(3n){
        margin-right: auto;
    }

    .dreams .dreamBox:nth-of-type(2n){
        margin-right: auto;
    }
}

@media (max-width: 600px) {
    .header{
        padding-left: 48px;
    }
}

@media(max-width: 360px){

    .header{
        padding-left: 40px
    }

    .lengthBox{
        width: 115px;
        height: 21.5px;
    }

    .filterBtn, .addBtn, .sortBtn{
        width: 28px;
        height: 28px;
    }

    .sortBtn, .filterWBtn{
        margin-right: 10px;
    }

    .filterIcon{
        font-size: 13px;
    }

    .profileArea{
        width: 30px;
        height: 30px;
    }
}

@media(max-width: 330px){
    .dateBox{
        width: 200px;
        height: 20px;
        border-radius: 7px;
    }

    .titleBox{
        height: 18px;
    }

    .dreamLine{
        height: 11.5px;
    }

    .dateDiv{
        height: 16px;
    }
}