.wrapper{
    width: 100%;
    height: 100vh;
    background-color: #030c12;
    display: flex;
    align-items: center;
    justify-content: center;

}


.container{
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.top{
    width: 100%;
    height: 6vh;
    background-image: url('../media/topBorder.png');
    background-size: 100% 100%;
}


.blur{
    width: 100%;
    position: absolute;
    bottom: 0px;
    top: 0px;
    box-shadow: 0 0 5px 5px #030c12 inset;
    box-sizing: border-box;
}


.smallScreen{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 125vh;
    max-width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: calc((100% - 125vh)/2);
}

.chat .smallScreen{
    padding-left: calc(100vh / 1.7);
    width: 90vh;
}


.layoutWrapper{
    flex-grow: 1;
    position: relative;
}


.layout{
    height: calc(100% + 2vh);
    width: 100%;
    background-size: 100% 100%;
    background-image: url('../media/tarotLayout2.png');
    margin-top: -2vh;
}

.flame{
    position: absolute;
    background-image: url("../media/flame.PNG");
    background-size: 100% 100%;
    width: 16vh;
    height: 23vh;
    bottom: 6vh;
}

.candles .flame:last-of-type{
    right: 15vh
}


@media (max-width: 125vh){
    .smallScreen{
        left: 0;
    }

    .flame{
        width: 13.5%;
        aspect-ratio: 1 / 1.58;
        height: unset;
    }
    
    .candles .flame:last-of-type{
        right: 11.5%;
    }
}

@media (max-width: 111vh){
    .candles .flame:last-of-type{
        right: 10%;
        width: 15%;
    }
}

@media (not (orientation:landscape)) and (not (height:100vw)){
    .smallScreen{
        height: 100vw;
        position: fixed;
        top: calc(0.5*(100vh - 100vw));
        left: 0;
    }
}