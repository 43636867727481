.wrapper{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 1.1vh;
    height: 100%;
}

.wrapper.shuffling{
    pointer-events: none;
}

.titleWrapper{
    width: 100%;
}


.cardsWrapper{
    padding-top: 3.5vh;
    margin: 0 auto;
    width: 94.5%;
}

.cards{
    position: relative;
    height: 20.4vh;
}

.cardWrapper{
    height: 9vh;
    aspect-ratio: 1 / 1.48;
    position: absolute;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 6px -1px, rgba(0, 0, 0, 0.3) 0px 1.5px 3.5px -1.5px;
    box-sizing: border-box;
    cursor: pointer;
    outline: 0;
    border-radius: 0.75vh;
    border: 0.135vh solid #e4bb89;
    border-right-width: 0;
    border-bottom-width: 0.12vh;
    transform: none;
}

.cardWrapper.selected{
    pointer-events: none;
    border-bottom-width: 0.17vh;
    border-right-width: 0.17vh;
    border-width: 0.17vh;
    border-color:#f5ba72;
    background-color: #1A1D24;
}

.card{
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    border-radius: 0.75vh;
}

.cardWrapper.selected .card{
    background-image: none !important;
}

.shuffleBtn{
    color: #3c1c09;
    width: 5.5vh;
    height: 5.5vh;
    border-radius: 50%;
    background-size: 100% 100%;
    font-size: 2.6vh;
    position: absolute;
    left: calc(50% - 2.25vh);
    bottom: -2.7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;
}

.shuffleBtn.disabled{
    pointer-events: none;
}

.shuffleBtn:hover{
    transform: scale(1.1,1.1);
}

.cardWrapper:not(.selected):not(.dragging):hover{
    animation: animate 0.15s forwards;
}

.cardWrapper.rising{
    animation: select 0.1s forwards;
    z-index: 400;
    pointer-events: none;
    cursor: unset;
    position: fixed;
}

.cardWrapper.dragging{
    z-index: 100;
    position: fixed;
    box-shadow: unset;
    border: 0;
    height: unset;
    width: unset;
    perspective: 660px;
    pointer-events: none;
    transition: top 1s ease-out, left 1s ease-out;
}

.cardWrapper.dragging .card{
    box-sizing: border-box;
    border: 2px solid #F2E6D0;
    border-top-width: 4px;
    border-bottom-width: 4px;
    box-sizing: border-box;
}

.tarotCard{
    border: 0.405vh solid #dacfb9;
    border-top-width: 0.81vh;
    border-bottom-width: 0.81vh;
    border-radius: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
}

/* .tarotCard.completed{
    border: 0;
} */

.tarotCard img{
    width: 100%;
    height: 100%;
    border-radius: 1vh;
}

.tarotCard.completed{
    border-color: #fff;
}

.tarotCard.reversed{
    transform: scaleY(-1);
}

.wrapper.shuffling .cardWrapper{
    animation: shuffle 0.06s linear calc(var(--data-order)*0.06s) 1 backwards;
}

.gameWrapper{
    width: 125vh;
    max-width: 100vw;
    height: 100vh;
    position: fixed;
    top: 4.5vh;
    left: calc((100vw - 125vh)/2)
}

.gameContainer{
    height: 17vh;
    width: 65%;
    margin: 39vh auto 0;
    position: relative;
}


.cardsArea{
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    pointer-events: none;
}


.cardsArea:hover .tarotCard{
    box-shadow: 0 0 1.85vw 0.43vw rgba(185, 184, 184, 0.24); 
    box-sizing: border-box;

}

.cardsArea[num-tarots="5"] .tarotCardWrapper:first-of-type{
    position: absolute;
    top: -30%;
    left: 5.44%;
    perspective: 600px;
    perspective: 75.08vh;
}

.cardsArea[num-tarots="5"] .tarotCardWrapper:first-of-type .tarotCard{
    transform: rotateX(55deg) rotateZ(25deg) rotateY(-8deg);
    height: 23vh;
    aspect-ratio: 1 / 1.9;
}


.cardsArea[num-tarots="3"] .tarotCardWrapper:first-of-type, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(2){
    position: absolute;
    top: -7%;
    left: 25.44%;
    perspective: 400px;
    perspective: 50.05vh;
}

.cardsArea[num-tarots="3"] .tarotCardWrapper:first-of-type .tarotCard, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(2) .tarotCard{
    transform: rotateX(48deg) rotateZ(12.2deg) rotateY(-11deg);
    height: 18.5vh;
    aspect-ratio: 1 / 1.58;
}


.cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(2), .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(3){
    position: absolute;
    top: -12.3%;
    left: 42.2%;
    perspective: 330px;
    perspective: 41.3vh;
}

.cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(2) .tarotCard, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(3) .tarotCard{
    transform: rotateX(54deg);
    height: 20vh;
    aspect-ratio: 1 / 1.71;
}

.cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(3), .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(4){
    position: absolute;
    top: -6.5%;
    left: 58.5%;
    perspective: 400px;
    perspective: 50.05vh;
}

.cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(3) .tarotCard, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(4) .tarotCard{
    transform: rotateX(48deg) rotateZ(-12.2deg) rotateY(10.5deg);
    height: 18.5vh;
    aspect-ratio: 1 / 1.58;
}

.cardsArea[num-tarots="5"] .tarotCardWrapper:last-of-type{
    position: absolute;
    top: -30%;
    right: 6.8%;
    perspective: 600px;
    perspective: 75.08vh;
}

.cardsArea[num-tarots="5"] .tarotCardWrapper:last-of-type .tarotCard{
    transform: rotateX(55deg) rotateZ(-25deg) rotateY(8deg);
    height: 23vh;
    aspect-ratio: 1 / 1.9;
}

.question{
    font-size: 10vh;
    color: #f5ba72;
    margin-top: 3vh;
}

.titleWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75vh 2vh;
    box-sizing: border-box;
    border-top: 0.11vh solid #D98C4A;
    border-bottom: 0.11vh solid #D98C4A
}


.titleBox{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: 'IM Fell English SC', serif;
    font-size: 4.3vh;
    letter-spacing: -0.15vh;
}

.leftDec, .rightDec{
    height: 4vh;
    width: 3.4vh;
    background-size: 100% 100%;
    margin-top: 0.4vh;
}

@media (max-width: 125vh){
    .gameWrapper{
        left: 0;
    }
    
    .cardsArea[num-tarots="3"] .tarotCardWrapper:first-of-type .tarotCard, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(2) .tarotCard{
        width: 9.62vw;
        aspect-ratio: unset;
    }
    
    .cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(2) .tarotCard, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(3) .tarotCard{
        width: 9.6vw;
        aspect-ratio: unset;
    }
    
    .cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(3) .tarotCard, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(4) .tarotCard{
        aspect-ratio: unset;
        width: 9.54vw;
    }
}

@media (max-width: 115vh){
    .cardsArea[num-tarots="5"] .tarotCardWrapper:first-of-type{
        top: -26%;
        left: 5.48%;
    }
    
    .cardsArea[num-tarots="5"] .tarotCardWrapper:first-of-type .tarotCard{
        width: 10vw;
        height: 22.5vh;
        aspect-ratio: unset;
        transform: rotateX(55deg) rotateZ(23deg) rotateY(-8deg);
    }

    .cardsArea[num-tarots="5"] .tarotCardWrapper:last-of-type{
        top: -21%;
        right: 6.5%;
    }
    
    .cardsArea[num-tarots="5"] .tarotCardWrapper:last-of-type .tarotCard{
        width: 10.2vw;
        height: 21vh;
        aspect-ratio: unset;
    }
}

@media (max-width: 110vh){
    .question{
        font-size: 9vh;
    }

    .cardWrapper{
        height: 8.2vh;
        aspect-ratio: 1 / 1.55;
    }

    .cardsWrapper{
        padding-top: 4vh;
        width: 91%;
    }
}

@media (max-width: 109vh){
    .cardsWrapper{
        width: 98%;
    }

    .cardWrapper{
        height: 8vh;
        aspect-ratio: 1 / 1.7;
    }
}

@media (not (orientation:landscape)) and (not (height:100vw)){

    .gameWrapper{
        height: 100vw;
        position: fixed;
        top: calc(0.5*(100vh - 100vw));
        left: 0;
    }

    .gameContainer{
        margin-top: 44vw;
        height: 17.5vw;
    }

    .cardsWrapper{
        width: 89%;
        padding-top: 1.4vh;
    }

    .cardWrapper{
        height: 7.5vw;
        aspect-ratio: 1 / 1.3;
        border-radius: 0.8vh;
        border-width: 0.145vw;
        border-bottom-width: 0.13vw;
    }
    
    .cardWrapper.selected{
        border-bottom-width: 0.185vw;
        border-right-width: 0.185vw;
        border-width: 0.185vw;
    }
    
    .card{
        border-radius: 0.8vw;
    }

    .shuffleBtn{
        width: 5vw;
        height: 5vw;
        font-size: 1.8vw;
        left: calc(50% - 2.5vw);
        bottom: -2.5vw;
    }

    .titleWrapper{
        padding: 0.65vw 1.95vw;
        border-width: 0.11vw;
    }
    
    
    .titleBox{
        font-size: 4.3vw;
        letter-spacing: -0.15vw;
    }
    
    .leftDec, .rightDec{
        height: 4vw;
        width: 3.4vw;
        margin-top: 0.4vw;
    }
    
    .cardsArea[num-tarots="3"] .tarotCardWrapper:first-of-type, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(2){
        top: -8%;
        perspective: 48vw;
    }
    
    .cardsArea[num-tarots="3"] .tarotCardWrapper:first-of-type .tarotCard, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(2) .tarotCard{
        transform: rotateX(48deg) rotateZ(9.8deg) rotateY(-8deg);
        height: 18.3vw;
        width: 9.3vw;
    }

    .cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(2), .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(3){
        top: -14.5%;
        left: 42.22%;
        perspective: 40vw;
    }
    
    .cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(2) .tarotCard, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(3) .tarotCard{
        height: 19.8vw;
        width: 9.2vw;
    }
    
    .cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(3), .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(4){
        top: -8%;
        left: 58.4%;
        perspective: 46vw;
    }
    
    .cardsArea[num-tarots="3"] .tarotCardWrapper:nth-of-type(3) .tarotCard, .cardsArea[num-tarots="5"] .tarotCardWrapper:nth-of-type(4) .tarotCard{
        transform: rotateX(47deg) rotateZ(-12deg) rotateY(10deg);
        height: 18.1vw;
        width: 9.4vw;
    }

    .cardsArea[num-tarots="5"] .tarotCardWrapper:first-of-type{
        top: -23%;
        left: 5.2%;
        perspective: 85vw;
    }

    .cardsArea[num-tarots="5"] .tarotCardWrapper:first-of-type .tarotCard{
        height: 21.5vw;
        width: 10vw;
    }

    .cardsArea[num-tarots="5"] .tarotCardWrapper:last-of-type{
        top: -23%;
        right: 6.25%;
        perspective: 85vw;
    }
    
    .cardsArea[num-tarots="5"] .tarotCardWrapper:last-of-type .tarotCard{
        height: 21.5vw;
        width: 10vw;
        transform: rotateX(55deg) rotateZ(-23deg) rotateY(8deg);
    }

    .question{
        font-size: 9.5vw;
        margin-top: 3.2vw;
    }

}


@keyframes animate{
    0%   {transform:translateY(0px);}
    25%  {transform:translateY(-0.73vh);}
    50%  {transform:translateY(-0.9125vh);}
    75%  {transform:translateY(-1.01vh);}
    100% {transform:translateY(-1.27vh);}
}

@keyframes shuffle{
    0%   {transform:translateY(0px);}
    25%  {transform:translateY(-0.965vh);}
    50%  {transform:translateY(-1.06vh);}
    75%  {transform:translateY(-1.205vh);}
    100% {transform:translateY(-1.335vh);}
}

@keyframes select{
    0%   {transform:translateY(0px);}
    25%  {transform:translateY(-15%);}
    50%  {transform:translateY(-30%);}
    75%  {transform:translateY(-45%);}
    100% {transform:translateY(-60%);}
}