.titleWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vh 15vh 0;
}

.titleBox{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: 'IM Fell English SC', serif;
    font-size: 4.7vh;
    letter-spacing: -0.15vh;
}

.title{
    padding-top: 2.5vh;
}

.leftDec, .rightDec{
    height: 4vh;
    width: 3.4vh;
    background-size: 100% 100%;
    margin-top: 0.4vh;
}

.leftDec{
    background-image: url("../media/left_butterfly.png");
}

.rightDec{
    background-image: url("../media/right_butterfly.png");
}


.icon{
    height: 5.5vh;;
    width: 5vh;
}

.icon img{
    width: 100%;
    height: 100%;
}


.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
}

.innerContainer{
    aspect-ratio: 1.1 / 1;
    height: 100vh;
    background-image: url('../media/newBackground.png');
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 6vh;
}

.gameTitle{
    position: absolute;
    top: 4.5vh;
    left: 15%;
    width: 11.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuBtn{
    position: absolute;
    top: 4.5vh;
    right: 13%;
    width: 13.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; 
}

.gameTitle, .menuBtn{
    font-family: 'Alegreya', serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-transform: uppercase;
    color: #e4d9c4;
    height: 5.5vh;
}

.menuBtn:hover{
    transform: scale(1.2,1.2);
}


.box{
    height: 55.5vh;
    width: 92vh;
    padding: 0 3vh;
    box-sizing: border-box;
    background-size: 100% 100%;
    background-image: url('../media/intentionFrame.PNG');
    background-color: #000;
    border-radius: 3.5vh;
    position: relative;
}

.container{
    /* height: calc(100% + 2.95vh); */
    width: 100%;
    /* margin-top: -2.95vh; */
    height: 100%;
    border-radius: 3.5vh;
}


.textContainer{
    padding: 1vh 2.3vh 1.9vh;
    margin: 0.6vh 1.4vh 0;
    height: 34vh;
    display: flex;
    align-items: center;
    border: 0.25vh solid #d1b26d;
    border-radius: 0.32vh;
    box-sizing: border-box;
}

.textContainer.shake{
    position: relative;
    animation: shake .15s linear;
    animation-iteration-count: 5;
}


.textContainer textarea{
    background-color: inherit;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    resize: none;
    font-family: 'Caudex', serif;
    font-size: 1.85vh;
    line-height: 3.3vh;
    background-size: 100% 3.3vh;
    font-weight: 600;
    background-image: -webkit-linear-gradient(transparent, transparent 3.1vh, #d2ac5a 3.1vh, #d2ac5a 3.3vh, transparent 3.3vh);
    background-image: -moz-linear-gradient(transparent, transparent 3.1vh, #d2ac5a 3.1vh, #d2ac5a 3.3vh, transparent 3.3vh);
    background-image: -ms-linear-gradient(transparent, transparent 3.1vh, #d2ac5a 3.1vh, #d2ac5a 3.3vh, transparent 3.3vh);
    background-image: -o-linear-gradient(transparent, transparent 3.1vh, #d2ac5a 3.1vh, #d2ac5a 3.3vh, transparent 3.3vh);
    background-image: linear-gradient(transparent, transparent 3.1vh, #d2ac5a 3.1vh, #d2ac5a 3.3vh, transparent 3.3vh);
    color: #000
}

.textContainer textarea::placeholder{
    color: #c7a354
}

.stone{
    width: 6.5vh;
    height: 6.5vh;
    border-radius: 50%;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0.5vh;
    left: calc((100% - 5.9vh)/2);
    z-index: 2;
    background-image: url("../media/stone.PNG");
    margin: -2.95vh auto 0;
}

.characterLength{
    display: flex;
    justify-content: flex-end;
    margin-right: 1.4vh;
    font-family: 'Eczar', serif;
    font-weight: 600;
    font-size: 1.7vh;
    letter-spacing: 0.02vh;
    color: #ae8b41
}

.interpertBtn{
    position: absolute;
    bottom: 0.5vh;
    left: calc((100% - 28vh) / 2);
    background-image: url("../media/interpertBtn2.png");
    width: 28vh;
    aspect-ratio: 3.5 / 1;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btns{
    display: flex;
    justify-content: space-between;
    margin-top: 1.4vh;
    margin-left: 3%;
}

.sendBtn{
    border: 0.15vh solid #c8b38e;
    padding: 0.3vh;
    outline: 0;
    background-color: unset;
    border-radius: 2.2vh;
    background-color: #000;
    color: #ebd6b1;
    font-family: 'Eczar', serif;
    font-size: 1.9vh;
    cursor: pointer;
}

.sendBtn:hover{
    transform: scale(1.03,1.03);
}

.innerBtn{
    border: 0.15vh solid #ebd6b1;
    padding: 0.15vh 2vh;
    border-radius: 1.9vh;
}

@keyframes shake {
    0% { left: -0.5vh; }
    100% { right: -0.5vh; }
}

@media (not (orientation:landscape)) and (not (height:100vw)){
    .wrapper{
        padding: 0 1.2vw;
    }

    .titleWrapper{
        padding: 3.8vw 2.2vw 0;
    }
    
    .titleBox{
        font-size: 4.9vw;
        letter-spacing: -0.15vw;
    }
    
    .title{
        padding-top: 2.3vw;
    }
    
    .leftDec, .rightDec{
        height: 4.1vw;
        width: 3.32vw;
        margin-top: 0.52vw;
    } 
    
    .icon{
        height: 5.4vw;
        width: 4.9vw;
    }

    .stone{
        width: 6vw;
        height: 6vw;
        left: calc((100% - 6vw)/2);
        margin: -3vw auto 0;
    }

    .container{
        height: calc(100% + 3vw);
        margin-top: -3vw
    }

    .textContainer{
        padding: 1vw 2.3vw 1.9vw;
        margin: 0.6vw 1.4vw 0;
        height: 25.5vw;
        border-width: 0.25vw;
        border-radius: 0.32vw;
    }

    .textContainer textarea{
        font-size: 1.87vw;
        line-height: 3.4vw;
        background-size: 100% 3.4vw;
        background-image: -webkit-linear-gradient(transparent, transparent 3.2vw, #d2ac5a 3.2vw, #d2ac5a 3.4vw, transparent 3.4vw);
        background-image: -moz-linear-gradient(transparent, transparent 3.2vw, #d2ac5a 3.2vw, #d2ac5a 3.4vw, transparent 3.4vw);
        background-image: -ms-linear-gradient(transparent, transparent 3.2vw, #d2ac5a 3.2vw, #d2ac5a 3.4vw, transparent 3.4vw);
        background-image: -o-linear-gradient(transparent, transparent 3.2vw, #d2ac5a 3.2vw, #d2ac5a 3.4vw, transparent 3.4vw);
        background-image: linear-gradient(transparent, transparent 3.2vw, #d2ac5a 3.2vw, #d2ac5a 3.4vw, transparent 3.4vw);
    }

    .characterLength{
        margin-right: 1.4vw;
        margin-top: 0.4vw;
        font-size: 1.78vw;
        letter-spacing: 0.03vw;
    }

    @keyframes shake {
        0% { left: -0.53vw; }
        100% { right: -0.53vw; }
    }
    
}