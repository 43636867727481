.box{
    color: #fff;
    width: 100%;
    box-shadow: 0 0 0 1px #424244;
    cursor: pointer;
    background-color: #1f1f1f;
    border-radius: 6px;
    border: 0.5px solid #363535;
    box-sizing: border-box;
    position: relative;
    padding: 8.5px 10px 0 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.box:not(.openMenu):hover{
    transform: scale(1.04,1.04);
}

.dreamTitle{
    font-family: 'Alegreya', serif;
    font-family: "Tilt Warp", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 2px;
    color: #fff;
}

.dream{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 1.4;
    color: #fbeacb;
    color: #ccc8c8;
}


.ellipsisBtn:hover, .ellipsisBtn.active{
    background-color: #565555;
    border-radius: 3.5px;
}

.dreamFooter{
    padding: 8px 10px;
    margin-top: 8px;
    border-top: 0.85px solid #565555;
    color: #fcefd8;
    color: #a49f9f;
}

.dateBox{
    display: flex;
    align-items: center;
}

.dateText{
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
}

.calendarIcon{
    display: flex;
    align-items: center;
    font-size: 14.5px;
    margin-right: 4px;
}

.dreamFooter .dateBox:first-of-type{
    margin-bottom: 3px;
}

.menuBox{
    position: relative;
}

.imageSection{
    width: 100%;
    min-width: 100%;
    aspect-ratio: 2.8 / 1;
    max-height: 155px;
    min-height: 100px;
    background-color: #323233;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.imageBox{
    aspect-ratio: 1 / 1;
    height: 85%;
    background-size: 100% 100%;
    border-radius: 5px;
}

.titleWrapper{
   display: flex;
   justify-content: space-between;
}

.titleBox{
    font-family: "Tilt Warp", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 3px 0 4.5px;
    color: #fff;
    letter-spacing: 0.2px;
    padding-right: 24px;
}

.ellipsisBtn{
    position: absolute;
    right: -1px;
    top: 32px;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-size: 19px;
}

.dateBox{
    display: inline-flex;
    align-items: center;
    background-color: #424243;
    color: #c2bfbf;
    padding: 5px 6px;
    border-radius: 7px;
}

.calendarIcon{
    display: flex;
    align-items: center;
    font-size: 12.8px;
}

.dateText{
    font-size: 12.3px;
}

.content{
    padding: 10px 0 7px;
    flex-grow: 1;
}

.dreamContent{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-top: 3.5px;
}

.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.85px solid #414040;
    padding: 8px 0;
    color: #ccc8c8;
}

.btns{
    display: flex;
    align-items: center;
}

.resumeBtn{
    margin-left: 1.5px;
    margin-top: 1.5px;
}

.resumeWrapper{
    font-size: 6.5px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.3px solid #ccc8c8;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}

.resumeWrapper:hover{
    background-color: #565555;
    border-color: transparent;
}

.questionBox{
    display: flex;
    align-items: center;
    margin-right: 12px;
}

.questionIcon{
    font-size: 18.5px;
    display: flex;
    align-items: center;
}

.questionNum{
    font-family: 'Poppins', sans-serif;
    font-size: 12.3px;
    margin-left: 2px;
}

.likeButton, .likeBtnFilled{
    font-size: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.likeBtnFilled{
    color: #ea4e4e;
}

.unbookmarked .likeBtnFilled, .unbookmarked:hover .likeButton{
    display: none;
}

.unbookmarked:hover .likeBtnFilled{
    display: flex;
}

.bookmarked .likeBtnFilled, .bookmarked:hover .likeButton{
    display: flex;
}

.bookmarked .likeButton, .bookmarked:hover .likeBtnFilled{
    display: none;
}

.dateWrapper{
    display: flex;
    align-items: center;
}

.dateWrapper .calendarIcon{
    font-size: 15px;
    margin-top: -1px;
}

.date{
    font-size: 12.6px;
    font-family: 'DM Sans', sans-serif;
}

.toastSuccess, .toastError{
    background-color: #424141 !important;
    color: #fff !important;
}

.toastBody .button{
    color: #fff !important;
}

.toastBody{
    font-family: 'Poppins', sans-serif;
    font-size: 13.5px;
}

.toastError .toastProgress{
    background-color: #e44d45 !important;
}

.toastSuccess .toastProgress{
    background-color: #5F8575	!important;
}

@media(max-width: 330px){
    .box{
        padding: 8.5px 7px 0 10px;
    }

    .dateText{
        font-size: 11.8px;
    }

    .calendarIcon{
        font-size: 12px;
    }

    .date{
        font-size: 12.2px;
    }

    .dateWrapper .calendarIcon{
        font-size: 14.5px;
    }

    .questionBox, .resumeWrapper{
        margin-right: 9px;
    }

    .questionIcon{
        font-size: 17px;
    }

    .questionNum{
        font-size: 11.8px;
    }

    .resumeWrapper{
        font-size: 5px;
        width: 16.5px;
        height: 16.5px;
    }

    .likeBtnFilled, .likeButton{
        font-size: 16px;
    }

    .titleBox{
        font-size: 14.5px;
    }

    .ellipsisBtn{
        top: 32px;
        width: 19px;
        height: 19px;
        font-size: 17px;
    }

    .dream{
        font-size: 11.7px;
    }
}