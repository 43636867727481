.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(64, 65, 68, 0.65);
    z-index: 6000;
}

.box{
    background-image: url('../media/exitBackground4.png');
    background-size: 100% 100%;
    width: 65vh;
    height: 60vh;
    position: relative;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
    background-color: #000;
    border-radius: 1vh; /* Change as needed */
    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 16px 0px;
}

.boxWrapper{
    position: relative;
}

.title{
    font-family: 'IM Fell English SC', serif;
    font-size: 3.3vh;
    font-weight: 600;
    position: absolute;
    top: 40.4%;
    height: 7.7%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 25%;
    width: 50%;
    color: #000;
}

.content{
    position: absolute;
    text-align: center;
    top: 52.5%;
    color: #f7e6c5;
    padding: 0 25%;
    font-family: 'Caudex', serif;
    font-size: 2.05vh;
    line-height: 1.37;
    text-transform: uppercase;
    font-weight: 600;
}

.buttons{
    top: 66.5%;
    position: absolute;
    left: 17.5%;
    width: 65%;
    display: flex;
    justify-content: space-between;
    height: 17.5%;
    align-items: center;
    padding: 0 7%;
    box-sizing: border-box;
}

.buttons button{
    width: 9vh;
    height: 9vh;;
    background-image: url('../media/exitBtn.PNG');
    background-size: 100% 100%;
    border: 0;
    outline: 0;
    background-color: unset;
    font-family: 'IM Fell English SC', serif;
    font-size: 2.6vh;
    font-weight: 800;
    cursor: pointer;
    border-radius: 50%;
    text-transform: uppercase;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

}

.buttons button:hover{
    transform: scale(1.1,1.1);
}

.closeIcon{
    width: 5.5vh;
    height: 5.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: -6vh;
    position: absolute;
    background-color: #000;
    color: #e8d5ae;
    border-radius: 50%;
    font-size: 4vh;
    cursor: pointer;
}

.closeIcon:hover{
    background-color: #111111;
    transform: scale(1.05,1.05);
}

.loadingDots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    height: 1.4vh;
    width: 1.4vh;
}
    
.loadingDots .dot:nth-child(2) {
    animation-delay: .25s;
    margin-left: 0.7vh;
}
    
.loadingDots .dot:nth-child(3) {
    animation-delay: 0.5s;
    margin-left: 0.7vh;
}

.buttons.loading{
    pointer-events: none;
}

.loader{
    border: 0.4vh solid #000;
    border-radius: 50%;
    border-top: 0.4vh solid transparent;
    width: 4.8vh;
    height: 4.8vh;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;
}

@media (max-width: 113vh){
    .box{
        width: 58vw;
        height: 53vw;
        border-radius: 1.2vw;
    }

    .title{
        font-size: 2.8vw;
    }
    
    .content{
        font-size: 1.9vw;
    }

    .buttons button{
        width: 8vw;
        height: 8vw;
        font-size: 2.4vw
    
    }
    .closeIcon{
        width: 5.3vw;
        height: 5.3vw;
        right: -5.8vw;
        font-size: 3.8vw;
        cursor: pointer;
    }

    .dot {
        height: 1.36vw;
        width: 1.36vw;
    }
        
    .loadingDots .dot:nth-child(2) {
        margin-left: 0.67vw;
    }
        
    .loadingDots .dot:nth-child(3) {
        margin-left: 0.66vw;
    }
    
    .loader{
        border-width: 0.36vw;
        width: 4.7vw;
        height: 4.7vw;
    }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@keyframes dot-keyframes {
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }
  
    50% {
      opacity: 1;
      transform: scale(1.1, 1.1);
    }
  
    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
}
