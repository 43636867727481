.wrapper{
    display: flex;
}

.box{
    height: 100vh;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.box header{
    height: 68px;
    min-height: 68px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    background-color: #272525;
    border-bottom:1.1px solid #484848;
    color:#f7f7f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainHeader{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.playerBtn{
    padding: 7px 9px;
    font-family: 'Poppins', sans-serif;
    font-size: 12.2px;
    color: #e0c8a0;
    border-radius: 3px;
    background-color: #3d3b3b;
    cursor: pointer;
}

.playerBtn:hover{
    transform: scale(1.1,1.1);
}

.mainTitle{
    display: flex;
    align-items: center;
    font-family: "Outfit", sans-serif;
    font-size: 18.7px;
    margin-right: 10px;
    font-weight: 600;
}

.subtitle{
    font-size: 12.6px;
    color: #aeaaaa;
    font-family: 'Poppins', sans-serif;
}

.waveIcon{
    font-size: 18.5px;
    display: flex;
    align-items: center;
    margin-top: -7px;
}

.box main{
    flex-grow: 1;
    overflow-y: auto;
    padding: 25px 20px;
}

.boxes{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
}

.stats{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.statBox{
    width: calc((100% - 60px) / 4);
    box-shadow: 0 0 0 1px #424244;
    background-color: #181818;
    border-radius: 4px;
    border: 0.4px solid #363535;
    box-sizing: border-box;
    padding: 8px 10px;
}

.skeleton .statBox{
    min-height: 108.33px;
}

.stats .statBox:nth-of-type(n+2){
    cursor: pointer;
}

.stats .statBox:nth-of-type(n+2):hover{
    transform: scale(1.05,1.05);
}

.iconBox{
    width: 40px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: #e0c8a0;
    background-color: #4e4638;
    margin-bottom: 7px;
}

.skeleton .iconBox{
    background-color: #363636;
    color: grey;
}

.num{
    font-family: "Outfit", sans-serif;
    font-size: 19px;
    font-weight: 600;
}

.skeletonNum{
    height: 18px;
    margin-top: 4px;
    width: 50px;
    background-color: #363636;
    border-radius: 15px;
}

.statName{
    font-family: Century Gothic;
    font-size: 16.5px;
    font-weight: 600;
    color: #ccc;
}

.skeletonStatName{
    margin-top: 7px;
    height: 16px;
    width: 80px;
    background-color: #363636;
    border-radius: 20px;
}

.revenueOverview, .invoices{
    width: calc(60% - 10px);
    box-shadow: 0 0 0 1px #424244;
    background-color: #181818;
    border-radius: 4px;
    border: 0.4px solid #363535;
    box-sizing: border-box;
    padding: 8px 10px 30px;
    margin-top: 20px;
    margin-right: 20px;
}

.invoices{
    padding-bottom: 13px;
}

.gameOverview{
    width: calc(40% - 10px);
    box-shadow: 0 0 0 1px #424244;
    background-color: #181818;
    border-radius: 4px;
    border: 0.4px solid #363535;
    box-sizing: border-box;
    padding: 8px 10px 10px;
    margin-top: 20px;
}

.promoCodeBox{
    width: calc(40% - 10px);
    border-radius: 7px;
    background-color: #dcc7a3;
    margin-top: 20px;
    color: #000;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}

.skeleton .promoCodeBox{
    background-color: #363636;
}

.graph{
    width: 100%;
    position: relative;
}

.axisY{
    position: absolute;
    left: 0;
    bottom: 10px;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 11.7px;
    color: #beb9b9;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  .bars {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: 230px;
    border-left: 1.9px solid #585757;
    border-bottom: 1.9px solid #585757;
    padding: 5px 3px;
    margin-left: 32px;
  }
  
.barContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    height: 230px;
    width: calc((100% - (11 * 10px))/ 12);
}

.bar{
    width: 100%;
    max-width: 29px;
    margin: 0 auto;
    height: 100%;
    background-color: #252424;
    background: #252424;
    border-radius: 7px 7px 2.5px 2.5px;
    display: flex;
    align-items: flex-end;
}

.innerBar{
    width: 100%;
    background-color: #e0c8a0;
    background: radial-gradient(circle, rgba(232,210,173,1) 0%, rgba(212,172,103,1) 91%);
    border-radius: 0px 0px 2.5px 2.5px;
}

.innerBar.completed{
    border-radius: 7px 7px 2.5px 2.5px;
}

.skeleton .bar{
    background-color: #363636;
    background: #363636;
}

.skeleton .labelX, .skeleton .labelY{
    visibility: hidden;
}

.labelX{
    bottom: -24px;
    position: absolute;
    font-size: 10.3px;
    color: #beb9b9;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.labelY{
    font-size: 11px;
}

.graphTitle{
    font-family: "Outfit", sans-serif;
    font-size: 17.2px;
    font-weight: 600;
    margin-bottom: 12px;
    text-align: center;
}

.titleSkeleton{
    height: 19px;
    margin: 3px auto 12px;
    width: 150px;
    background-color: #363636;
    border-radius: 10px;
}

.promoTitle{
    font-family: "Outfit", sans-serif;
    font-size: 27px;
    font-weight: 600; 
    line-height: 1;
}

.promoSubtitle{
    font-size: 12.5px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    width: 60%;
    margin: 7px 0 10px;
}

.promoCodeBox button{
    background-color: #000;
    color: #e0c8a0;
    font-size: 13.5px;
    font-family: 'Poppins', sans-serif;
    padding:10px 16px;
    border-radius: 21px;
    outline:0;
    border:0;
    cursor: pointer;
}

.promoBtn{
    height: 40.5px;
    width: 101px;
    background-color: rgb(95, 94, 94);
    border-radius: 21px;
}


.promoCodeBox button:hover{
    transform: scale(1.1,1.1);
}

.line{
    background-color: rgb(95, 94, 94);
}

.promoTitleSkeleton .line{
    height: 23px;
    border-radius: 20px;
}

.promoSubtitleSkeleton .line{
    height: 15px;
    border-radius: 10px;
}

.promoTitleSkeleton .line:first-of-type{
    width: 40%;
    margin: 3px 0 7px;
}

.promoTitleSkeleton .line:last-of-type{
    width: 25%;
}

.promoSubtitleSkeleton{
    margin: 12px 0 13px
}

.promoSubtitleSkeleton .line:first-of-type{
    width: 60%;
    margin-bottom: 6px;
}

.promoSubtitleSkeleton .line:last-of-type{
    width: 48%;
}

.image{
    position:absolute;
    right: 8px;
    bottom: 2px;
    font-size: 125px;
    display: flex;
    align-items: flex-end;
}

.skeleton .image{
    color: rgb(95, 94, 94);
}

.invoiceTitle{
    font-family: "Outfit", sans-serif;
    font-size: 17.2px;
    font-weight: 600;
    margin-bottom: 8px;
}

.pieChart{
    position: relative;
    width: 210px;
    height: 210px;
    margin: 0 auto;
    font-family: "Outfit", sans-serif;
    font-size: 26px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chartContent{
    position: absolute;
    z-index: 10;
    text-align: center;
}

.pieChart:before{
    content: "";
    position: absolute;
    border-radius: 50%;
    inset: 0;
    background: conic-gradient(#dcc7a3 0 calc(var(--p)*1%),#8f6d33 0 100%)
}

.skeleton .pieChart::before{
    background: conic-gradient(#363636 0 calc(var(--p)*1%),rgb(95, 94, 94) 0 100%)
}

.pieChart:after{
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 87%;
    height: 87%;
    inset: 0;
    background: #181818;
    top: 6.5%;
    left: 6.5%
}

.labels{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 290px;
    margin: 10px auto 0;
    justify-content: space-between;
}

.labelBox{
    width: calc(50% - 8px);
    display: flex;
    align-items: center;
}

.labelIcon{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7.5px;
}

.labelIcon.great{
    background-color: #dcc7a3;
    color: #000;
}

.skeleton .labelIcon{
    background-color: #363636;
    color: grey;
}

.labelIcon{
    color: #eadcc3;
    background-color: #8f6d33
}

.labelNum{
    font-family: "Outfit", sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.labelNumSkeleton{
    height: 16px;
    width: 40px;
    background-color: #363636;
    border-radius: 7px;
    margin-bottom: 5px;
}

.labelName{
    font-size: 12.3px;
    color: #aeaaaa;
    font-family: 'Poppins', sans-serif;
    font-family: 600;
}

.labelNameSkeleton{
    height: 14px;
    width: 50px;
    background-color: #363636;
    border-radius: 7px;
}

.invoices table, .skeletonTable{
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 5px;
}

.skeletonTable{
    border: 1.3px solid #555454;
    width: 100%;
    border-radius: 5px;
}

.row{
    display: flex;
    width: 100%;
    height: 36.9px;
}

.col{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    border: 1.3px solid #555454;
    border-left: 0;
    border-top: 0;
}

.skeletonHeader .col{
    border-top: 0;
}

.row .col:last-of-type{
    border-right: 0
}

.tableContent .row:last-of-type .col{
    border-bottom: 0;
}

.tableContent{
    width: 100%;
}

.colContent{
    width: 40%;
    height: 21px;
    border-radius: 11px;
    background-color: #363636;
}

.invoices th, 
.invoices td {
    padding: 10px;
    text-align: center;
    border: 1.3px solid #555454;
    box-sizing: border-box;
}


.invoices th{
    font-size: 12.2px;
    color: #d1cece;
    font-weight: normal;
    text-transform: uppercase;
    font-weight: 500;
}

.invoices td{
    font-family: 'Poppins', sans-serif;
    font-size: 11.3px;
    color: #edebeb
}

.invoices tbody tr:last-of-type, .invoices tbody{
    border-radius: 0 0 5px 5px;
}

.invoices tbody tr:last-of-type td:first-of-type{
    border-bottom-left-radius: 5px;
}

.invoices tbody tr:last-of-type td:last-of-type{
    border-bottom-right-radius: 5px;
}
.invoices thead{
    background-color: #363636;
    border-radius: 5px 5px 0 0;
    font-family: 'DM Sans', sans-serif;
}

.skeletonHeader{
    background-color: #363636;
    border-radius: 5px 5px 0 0;
    height: 36.98px;
}

@media(min-width: 1450px){
    .revenueOverview, .invoices{
        width: calc(60% - 15px);
        margin-right: 30px;
    }

    .gameOverview, .promoCodeBox{
        width: calc(40% - 15px);
    }

    .box main{
        padding: 25px 30px;
    }
}

@media(min-height: 750px){
    .revenueOverview, .promoCodeBox, .gameOverview, .invoices{
        margin-top: 25px;
    }
}
@media (max-width:960px){
    .revenueOverview, .invoices{
        width: 100%;
        margin-right: 0;
    }

    .invoices{
        order: 3;
    }

    .gameOverview{
        order: 4;
        width: calc(55% - 10px);
        margin-right: 20px;
    }

    .promoCodeBox{
        order: 5;
        width: calc(45% - 10px);
        padding: 20px;
    }

    .pieChart{
        width: 140px;
        height: 140px;
        font-size: 17.2px;
    }

    .labelBox{
        width: fit-content;
    }

    .labelIcon{
        width: 32px;
        height: 32px;
        font-size: 18.5px;
        margin-right: 5px;
    }
    

    .labelNum{
        font-size: 14.2px;
    }
    
    .labelName{
        font-size: 11.5px;
    }

    .labelNumSkeleton{
        height: 15px;
        width: 37px;
        border-radius: 6.5px;
    }
    
    .labelNameSkeleton{
        height: 13px;
        width: 47px;
        border-radius: 6.5px;
    }

    .labels{
        justify-content: space-evenly;
    }
}

@media (max-width: 800px){
    .stats{
        flex-wrap: wrap;
    }

    .statBox{
        width: calc((100% - 20px) / 2);
    }   
    
    .stats .statBox:nth-of-type(n+3){
        margin-top: 20px;
    }
}

@media (max-width: 750px){
    .gameOverview{
        width: 100%;
        margin-right: 0;
    }

    .promoCodeBox{
        display: none;
    }
}

@media(max-width: 670px){
    .subtitle{
        display: none;
    }

    .mainTitle{
        font-size: 21.2px;
    }
    
    .waveIcon{
        font-size: 21px;
    }

    .box header{
        padding: 0 10px
    }

    .box main{
        padding: 15px 12px;
    }
}