@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.wrapper{
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
}

.box{
    background-color: #1e1e1e;
    width: 95%;
    max-width: 750px;
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    display: flex;
    min-height: 370px;
    min-height: 498px;
}

.content{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 12px;
    border-bottom: 1.7px solid #2f2f2f;
}

.title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.25px;
    color: #ededed;
}

.closeIcon{
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20.5px;
    border-radius: 50%;
    cursor: pointer;
    color: #c9c8c9;
}

.closeIcon:hover{
    color: #fff;
    background-color: #424244;
}
