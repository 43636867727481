.wrapper{
    width: calc(0.28*125vh);
    position: fixed;
    left: calc((100vw - 125vh)/2);
    bottom: 4.5vh;
}

.cards{
    position: relative;
}

.card{
    z-index: 1500;
    background-size: 100% 100%;
    /* background-image: url("../media/tarotCard2.PNG"); */
    position: absolute;
    cursor: pointer;
}

.card img{
    width: 100%;
    height: 100%;
    border-radius: 1vh;
}

.card.reversed img{
    transform: scaleY(-1);
}


.cards[length="3"] .card{
    width: 45%;
    aspect-ratio: 1 / 1.68;
    bottom: 0;
    left: 14%;
}

.cards[length="5"] .card{
    width: 41%;
    aspect-ratio: 1 / 1.85;
    bottom: 0;
    left: 13%;
}

.cards[length="3"] .card:first-of-type{
    transform: rotate(-9deg);
}

.cards[length="5"] .card:first-of-type{
    transform: rotate(-12deg) translateY(-3%);
}

.cards[length="5"] .card:first-of-type:hover{
    transform: rotate(-12deg) translateY(-8%) scale(1.05,1.05);
}

.cards[length="3"] .card:first-of-type:hover{
    transform: rotate(-9deg) translateY(-5%) scale(1.05,1.05);
}

.cards[length="3"] .card:last-of-type{
    transform: rotate(8.5deg) translateX(80%) translateY(-7%);
}

.cards[length="5"] .card:last-of-type{
    transform: rotate(10.5deg) translateX(95%) translateY(-12%);
}

.cards[length="5"] .card:last-of-type:hover{
    transform: rotate(10.5deg) translateX(95%) translateY(-17%) scale(1.05,1.05);
}

.cards[length="3"] .card:last-of-type:hover{
    transform: rotate(8.5deg) translateX(80%) translateY(-13%) scale(1.05,1.05);
}

.cards[length="3"] .card:nth-of-type(2){
    transform: translateX(45%) translateY(-2.5%) scale(1.02,1.02);
}

.cards[length="5"] .card:nth-of-type(2){
    transform: rotate(-6deg) translateX(25%) translateY(-2.5%);
}

.cards[length="5"] .card:nth-of-type(2):hover{
    transform: rotate(-6deg) translateX(25%) translateY(-7.5%) scale(1.05,1.05);
}

.cards[length="3"] .card:nth-of-type(2):hover{
    transform: translateX(45%) translateY(-7.5%) scale(1.07,1.07);
}

.cards[length="5"] .card:nth-of-type(3){
    transform: translateX(50%) translateY(-4.5%) scale(1.02,1.02);
}

.cards[length="5"] .card:nth-of-type(3):hover{
    transform: translateX(50%) translateY(-9.5%) scale(1.07,1.07);
}

.cards[length="5"] .card:nth-of-type(4){
    transform: rotate(6deg) translateX(75%) translateY(-8%);
}

.cards[length="5"] .card:nth-of-type(4):hover{
    transform: rotate(6deg) translateX(75%) translateY(-13%) scale(1.05,1.05);
}

.card:hover{
    z-index: 2000;
}

@media (max-width: 125vh){
    .wrapper{
        width: 28vw;
        left: 0;
    }
}

@media (max-width: 121vh){
    .cards[length="3"] .card{
        width: 46%;
        aspect-ratio: 1 / 1.9;
        left: 12%;
    }

    .cards[length="5"] .card{
        width: 42%;
        left: 7%;
        aspect-ratio: 1 / 1.94;
    }
}

@media (not (orientation:landscape)) and (not (height:100vw)){
    .wrapper{
        bottom: calc((100vh - 100vw) / 2 + 4.5vw);
    }

    .cards[length="3"] .card{
        width: 44%;
        aspect-ratio: 1 / 1.9;
        left: 7%;
    }

    .cards[length="5"] .card{
        width: 40.5%;
        left: 7%;
        aspect-ratio: 1 / 2.05;
    }
}
