.wrapper{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    /* color-scheme: light; */
    background-color: #000;
    color:#000;
}

.container{
    width: 125vh;
    max-width: 100%;
    height: 100%;
    position: relative;
}

.frame{
    position: absolute;
    bottom: 1vh;
    left: calc((100% - 90vh)/2);
    background-size: 100% 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    box-sizing: border-box;
    height: 39.5vh;
    width: 90vh;
    padding: 0 3.4vh 2vh 2.8vh;
    border-radius: 3vh;
}

.frame.third{
    height: 45vh;
    width: 93vh;
    padding: 0;
    bottom: 1.5vh;
    left: calc((100% - 93vh)/2);
}

.frame.cardResults{
    transform: translateY(-6%) scale(1.01,1.14);
}


.stacks{
    position: absolute;
    right: 1.8vh;
    padding: 1vh 1%;
    top: 42vh;
    border-radius: 4.5vh;
    box-sizing: border-box;
    box-shadow: 0 0 1.85vw 0.43vw rgb(255,255,255,0.35); 
    animation:  expand 3s linear 0.3s infinite alternate;
    cursor: pointer;
}

.stacks:hover{
    transform: scale(1.1,1.1);
    animation: none;
    box-shadow: 0 0 1.85vw 0.43vw rgb(255,255,255,0.42); 
}

.stacks img{
    height: 17.5vh;
    aspect-ratio: 1 / 1.3;
}

@keyframes expand{
    from {transform: scale(1,1);}
    to {transform: scale(1.1,1.1)}
}

@media (min-height: 99.9vw){
    .wrapper{
        height: 100vw;
    }

    .container{
        height: 100vw;
    }
}

@media (max-width: 109vh){
    .frame{
        left: calc((100% - 84vh)/2);
        width: 84vh;
    }
}

@media (not (orientation:landscape)) and (not (height:100vw)){
    .frame, .frame.third{
        width: 80vw;
        left: 10vw;
        height: unset;
        aspect-ratio: 2 / 1;
        bottom: 0.5vw;
        padding: 0 3.42vw 2.02vw 2.82vw;
        border-radius: 3.2vw;
    }

    .frame.cardResults{
        transform: translateY(-10%) scale(1.01,1.12);
    }

    .stacks{
        right: 1vw;
        padding: 1vw;
        top: 45vw;
        border-radius: 4.5vw;
    }

    
    .stacks img{
        height: 15vw;
        aspect-ratio: 1 / 1.3;
    }
}