.wrapper{
    width: 100%;
    height: 100vh;
    background-color: #000;
    overflow-y: hidden;
    position: fixed;
    top: 0;
    left:0;
    z-index: 10000;
}

.box{
    width: 140vh;
    height: 100vh;
    margin: 0 auto;
    padding: 6.15vh 3.1vh 0;
    border-left: 0.54vh solid #dfae4d;
    border-right: 0.54vh solid #dfae4d;
    border-image: radial-gradient(circle, #e8d5ae, #e5d0a5, #e2cb9b, #dfc692, #dcc189, #dcbf85, #dbbe80, #dbbc7c, #ddbe7c, #e0bf7b, #e2c17b, #e5c27a) 1;
    box-sizing: border-box;
}

.content{
    border: 0.48vh solid #e5c27a;
    border-image: radial-gradient(circle, #e8d5ae, #e5d0a5, #e2cb9b, #dfc692, #dcc189, #dcbf85, #dbbe80, #dbbc7c, #ddbe7c, #e0bf7b, #e2c17b, #e5c27a) 1;
    border-bottom: 0;
    height: 100%;
    box-sizing: border-box;
    border-radius: 0.4vh;
    position: relative;
    display: flex;
    flex-direction: column;
}

.titleRibbon{
    position: absolute;
    top: -5.9vh;
    width: 62%;
    height: 9.5vh;
    left: 19%;
    background-size: 100% 100%;
    padding: 0.68% 5.7%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title{
    font-family: "Parisienne", cursive;
    font-weight: 800;
    font-size: 4.7vh;
    letter-spacing: 0.05vh;
}


.bookArea{
    flex-grow: 1;
    padding: 3vh 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.leftSide, .rightSide{
    width: 42%;
    height: 65vh;
    background-size: 100% 100%;
}

.bookArea.expanded .leftSide, .bookArea.expanded .leftContainer, .bookArea.expanded .rightSide, .bookArea.expanded .rightContainer{
    height: 71.5vh;
}

.rightSide{
    position: relative;
}

.leftSide, .leftContainer{
    transform: scale(-1,1);
}

.footer{
    height: 20.5vh;
    border-top: 0.34vh solid #dfae4d;
    border-image: radial-gradient(circle, #e8d5ae, #e5d0a5, #e2cb9b, #dfc692, #dcc189, #dcbf85, #dbbe80, #dbbc7c, #ddbe7c, #e0bf7b, #e2c17b, #ac8433) 1;
    display: flex;
}

.footer.expanded{
    height: 14vh;
    padding: 0.3vh 0.5vh;
    box-sizing: border-box;
    justify-content: space-between;
}


.menuArea, .inputsArea{
    width: 36%;
    height: 100%;
    border: 0.22vh solid #2a2a2a;
    padding: 0.35vh;
    box-shadow: rgb(84, 84, 84) 0px 0px 0.35vh 0.35vh inset;
    box-sizing: border-box;
    position: relative;
}

.interpertArea{
    width: 28%;
    height: 100%;
    background-color: #e8d5ae;
    padding: 0.06vh 0.4vh 0.4vh;
    box-sizing: border-box;
}

.footer.expanded .menuBox{
    width: calc(25% - 3 * 0.15vh);
    height: 100%;
    background-color: #e8d5ae;
    padding: 0.06vh 0.4vh 0.4vh;
    box-sizing: border-box;
    padding-top: 0.4vh;
}

.btnWrapper{
    box-shadow: rgb(0,0,0) 0px 0px 0.4vh 0.2vh inset;
    height: 100%;
    box-sizing: border-box;
    border: 0.27vh solid #000;
    border-radius: 0.2vh;
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer.expanded .menuBox .btnWrapper{
    justify-content: center;
}

.line{
    width: 100%;
    height: 3.2vh;
    background-size: 100% 100%;
}

.interpertBtn{
    width: 100%;
    min-height: 7.55vh;
    background-size: 100% 100%;
    background-color: unset;
    outline: 0;
    border: 0;
    font-family: "IM Fell English SC", serif;
    font-weight: 600;
    font-size: 3vh;
    color: #e8d5ae;
    text-transform: uppercase;
    letter-spacing: 0.08vh;
    cursor: pointer;
}

.interpertBtn:hover{
    transform: scale(1.05,1.05);
}

.interpertBtn:disabled{
    opacity: 0.7;
    cursor: unset;
    position: relative;
}

.interpertBtn:disabled:hover{
    transform: unset;
}

.tooltip{
    display: none;
    position: absolute;
    color: #e8d5ae;
    background-color: #976248;
    padding: 1vh 0;
    bottom: 8vh;
    font-weight: 600;
    font-family: 'Caudex', serif;
    font-size: 1.6vh;
    text-transform: none;
    border-radius: 2vh;
    width: 27vh;
    text-align: center;
    left: 10.5%
}
.btn{
    position: relative;
}

.btn.disabled:hover .tooltip{
    display: block;
}

.footer.expanded .menuBtn, .footer.expanded .loadingHistoryBtn{
    width: 100%;
    min-height: 7.55vh;
    background-size: 100% 100%;
    background-color: unset;
    outline: 0;
    border: 0;
    font-weight: 600;
    font-size: 2.4vh;
    color: #e8d5ae;
    text-transform: uppercase;
    letter-spacing: 0.08vh;
    font-family: 'Alegreya', serif;
}

.btnsWrapper{
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    border: 0.22vh solid #2a2a2a;
}

.menuArea .btnsWrapper{
    padding: 1.6vh 0.6vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menuRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menuBtn, .loadingHistoryBtn{
    width: calc(50% - 0.4vh);
    background-size: 100% 100%;
    height: 6.7vh;
    padding: 2.7vh 2.8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: #af785d;
    font-weight: 600;
    font-family: 'Caudex', serif;
    font-size: 2.3vh;
    letter-spacing: 0.05vh;
    cursor: pointer;
}

.loadingHistoryBtn{
    cursor: unset;
}

.menuBtn:hover{
    transform: scale(1.06,1.06);
}

.menuRow .menuBtn:first-of-type{
    margin-right: 0.4vh;
}

.nameBox, .dateBox{
    width: 91%;
    height: 9.3vh;
    background-size: 100% 100%;
    margin: 0 auto;
    padding: 1.7vh 4vh;
    box-sizing: border-box;
}

.nameBox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.35vh;
    position: relative;
}

.characterCount{
    position: absolute;
    right: 4.5vh;
    top: -0.8vh;
    color: #e8d5ae;
    font-family: 'Lato', sans-serif;
    font-size: 1.5vh;
    font-weight: 600;
    letter-spacing: 0.02vh;
}

.nameBox textarea{
    width: 100%;
    box-sizing: border-box;
    background-color: inherit;
    border: 0;
    outline: 0;
    text-align: center;
    padding: 0.25vh 0.65vh 0;
    font-family: 'Eczar', serif;
    font-weight: 600;
    font-size: 2.12vh;
    resize: none;
    line-height: 1;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    max-height: 100%;
    min-height: 2.62vh;
    color: #000;
    box-sizing: border-box;
}

.nameBox textarea::placeholder, .dateBox.placeholder{
    color: #a9745a;
}

.dateBox.placeholder .dateValue{
    font-weight: normal;
}

.dateBox{
    margin-top: -0.37vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dateBox:hover{
    transform: scale(1.05,1.05);
}

.calendarIcon{
    display: flex;
    align-items: center;
    font-size: 2vh;
    margin-right: 0.55vh;
    margin-top: 0.17vh;
}

.dateValue{
    font-family: "Bree Serif", serif;
    font-weight: 600;
    font-size: 2.25vh;
}


.textWrapper, .contentWrapper{
    padding: 2.4vh 7.2vh 1.5vh 1.8vh;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.responseArea{
    transform: scale(-1,1);
    padding: 2.4vh 1.8vh 1.5vh 7.2vh;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.imageBox{
    width: 50%;
    aspect-ratio: 1 / 1;
    background-size: 100% 100%;
    margin: 0 auto;
    border-radius: 1.2vh;
}


.titleWrapper{
    text-align: center;
}

.sectionTitle{
    font-family: "Alice", serif;
    text-transform: uppercase;
    font-size: 2.3vh;
    margin-bottom: 0.15vh;
}

.seperator{
    width: 100%;
    height: 2vh;
    background-size: 100% 100%;
    transform: rotate(-0.15deg);
}

.textBox{
    width: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}

.textBox::-webkit-scrollbar {
    display: none;
}

.textBox textarea{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-right: 2%;
    padding-top: 0.5%;
    background-color: inherit;
    outline: 0;
    border: 0;
    resize: none;
    color: #000;
    font-family: 'Caudex', serif;
    font-size: 1.65vh;
    line-height: 2.93vh;
    background-size: 100% 2.93vh;
    font-weight: 600;
    background-image: -webkit-linear-gradient(transparent, transparent 2.8vh, #ac8433 2.8vh, #ac8433 2.93vh, transparent 2.93vh);
    background-image: -moz-linear-gradient(transparent, transparent 2.8vh, #ac8433 2.8vh, #ac8433 2.93vh, transparent 2.93vh);
    background-image: -ms-linear-gradient(transparent, transparent 2.8vh, #ac8433 2.8vh, #ac8433 2.93vh, transparent 2.93vh);
    background-image: -o-linear-gradient(transparent, transparent 2.8vh, #ac8433 2.8vh, #ac8433 2.93vh, transparent 2.93vh);
    background-image: linear-gradient(transparent, transparent 2.8vh, #ac8433 2.8vh, #ac8433 2.93vh, transparent 2.93vh);
    transform: rotate(-0.05deg);
    overflow-y: hidden;
}

.textBox textarea::placeholder{
    color:#c7932a
}


.arrowBtn{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.arrowWrapper.left{
    bottom: 12%;
    left: 8.9%;
    border-radius: 0 1.2vh 1.2vh 0;
    border-left-width: 0
}

.arrowWrapper.right{
    border-radius: 1.2vh 0 0 1.2vh;
    bottom: 12%;
    right: 8.9%; 
    border-right-width: 0
}

.arrowIcon{
    width: 2.25vh;
    height: 2.25vh;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.1vh;
    border: 0.24vh solid #d3b883;
    color: #d3b883;
}

.arrowWrapper{
    border: 0.25vh solid #543903;
    box-shadow: inset 2px 3px 5px #000000, 2px 1px 1px #333;
    position: absolute;
    background-color: #000;
    width: 5.4vh;
    height: 4.7vh;
    box-sizing: border-box;
}


.chatContent{
    flex-grow: 1;
    padding-top: 2%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.chatContent::-webkit-scrollbar {
    display: none;
}

.typerWrapper{
    display: flex;
    margin: 0 0.5vh .5vh -0.5vh;
    padding: 0.7vh;
    border-radius: 2vh;
    box-sizing: border-box;
    align-items: center;
    background-color: rgba(0,0,0,0.1)
}

.typerWrapper.multiline{
    align-items: flex-start;
}


.chatSeperator{
    margin-bottom: 2.5%;
    background-color:  rgba(0,0,0, 0.15);
    height: 0.23vh;
}

.textarea{
    flex-grow: 1;
}

.characterLength{
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 1.42vh;
    color: #000;
    font-weight: 600;
    margin-right: 1.3vh;
    margin-bottom: 0.4vh;
}

.typerWrapper textarea{
    background-color: inherit;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    resize: none;
    outline: 0;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.33vh;
    letter-spacing: 0.05vh;
    display:inline-block;
    vertical-align:middle;
    line-height: 1.7;
    overflow-y: hidden;
    color: #000;
    font-weight: 600;
}

.typerWrapper textarea::placeholder{
    color: #686868;
}

.text{
    font-size: 1.4vh;
    font-family: 'Poppins', sans-serif;
    font-family: "Cormorant Upright", serif;
    font-family: "Inknut Antiqua", serif;
    line-height: 1.34;
    font-weight: 500;
    font-family: "Cabin", sans-serif;
}

.appBox .text{
    font-weight: 400;
    line-height: 1.32;
}


.sendBtn{
    width: 7.5%;
    min-width: 7.5%;;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75vh;
    cursor: pointer;
    color: #F2E6D0;
    background-color:#333
}

.typerWrapper.multiline .sendBtn{
    margin-top: 1%;
}

.userWrapper{
    display: flex;
    justify-content: flex-end;
    margin: 0.05vh 0;
}

.userBox{
    padding: 0.7% 3% 2%;
    max-width: 75%;
    min-width: 15%;
    box-sizing: border-box;
    border-radius: 1.6vh;
    border-bottom-right-radius: 0.5vh;
    color: #F2E6D0;
    background-color: #895439;
}

.appBox{
    padding: 0.7% 3% 2%;
    max-width: 75%;
    min-width: 28%;
    box-sizing: border-box;
    border-radius: 1.6vh;
    border-bottom-left-radius: 0.5vh;
    background-color: rgba(0,0,0,0.08);
    color: #000;
}

.appWrapper{
    display: flex;
    justify-content: flex-start;
    margin: 0.7vh 0;
}

.timestamp{
    text-align: right;
    font-size: 1.1vh;
    margin-bottom: 0.2vh;
    font-family: 'Lato', sans-serif;
}

.dreamTitle{
    font-family: "Cookie", cursive;
    font-weight: 600;
    font-size: 4vh;
    letter-spacing: 0.12vh;
    text-align: center;
}

.response_text{
    font-family: "Inknut Antiqua", serif;
    font-size: 1.5vh;
    line-height: 1.65;
    letter-spacing: 0.02vh;
    padding: 0 0.4vh 0 0.7vh;

    font-family: "Cormorant Upright", serif;
    line-height: 1.3;
    font-weight: 600;
    font-family: 'Caudex', serif;
}

.dreamDate{
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.2vh;
}

.dreamDateIcon{
    display: flex;
    align-items: center;
    font-size: 1.9vh;
    margin-right: 0.3vh;
    margin-top: -0.15vh;
}

.dreamDateVal{
    font-size: 1.6vh;
    font-family: 'Eczar', serif;
    font-weight: 600;
}

.loadingDots{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F2E6D0;
    padding: 2.3% 5.5%;
    box-sizing: border-box;
    border-radius: 1.6vh;
    margin: 0.35vh 0;
    width: fit-content;
}

.loadingBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #6d6b66;
    border-radius: 50%;
    display: inline-block;
    height: 1.1vh;
    width: 1.1vh;
}

.loadingBtn .dot{
    height: 1.7vh;
    width: 1.7vh;
    background-color: #af785d
}

.loadingHistoryBtn .dot{
    height: 1.7vh;
    width: 1.7vh;
    background-color: #af785d
}

.loadingDots .dot:nth-child(2){
    animation-delay: .25s;
    margin-left: 0.55vh;
}

.loadingHistoryBtn .dot:nth-child(2){
    animation-delay: 0.25s;
    margin-left: 0.7vh;
}
    
.loadingBtn .dot:nth-child(2){
    animation-delay: .25s;
    margin-left: 1.3vh;
}

.loadingDots .dot:nth-child(3){
    animation-delay: 0.5s;
    margin-left: 0.55vh;
}

.loadingHistoryBtn .dot:nth-child(3){
    animation-delay: 0.5s;
    margin-left: 0.7vh;
}

.loadingBtn .dot:nth-child(3){
    animation-delay: 0.5s;
    margin-left: 1.3vh;
}

.dreamRep{
    position: fixed;
    top: 0vh;
    height: 6.5vh;
    left: calc((100vw - 138.92vh) / 2);
    width: 138.92vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    box-sizing: border-box;
}

.moonImage{
    width: 17.4%;
    height: 4.6vh;
    background-size: 100% 100%;
}


.stone{
    width: 6vh;
    height: 6vh;
    background-size: 100% 100%;
    position: absolute;
    top: -3.8vh;
    left: calc(50% - 3vh);
}

.square{
    height: 3.2vh;
    width: 3.2vh;
    border: 0.34vh solid #d39b2a;
    border-radius: 0.6vh;
    transform: rotate(-45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    position: fixed;
    box-sizing: border-box;
}


.circle{
    height: 3vh;
    width: 3vh;
    border: 0.28vh solid #d39b2a;
    transform: rotate(-45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    position: fixed;
    box-sizing: border-box;
    border-radius: 50%;
}

.innerCircle{
    height: 1.8vh;
    width: 1.8vh;
    border-radius: 50%;
    box-shadow: #e8d5ae 0px 0.2vh 0.6vh;
}

.squares .square:first-of-type{
    top: calc((15 * 79.5vh / 100) - 1.6vh + 6.5vh);
    left: calc((100vw - 140vh)/2 - 1.5vh + 3.64vh)
}

.squares .square:nth-of-type(2){
    bottom: calc((15 * 79.5vh / 100) - 1.6vh + 14vh);
    left: calc((100vw - 140vh)/2 - 1.5vh + 3.64vh)
}

.squares .square:nth-of-type(3){
    top: calc(15% - 1.6vh);
    top: calc((15 * 79.5vh / 100) - 1.6vh + 6.5vh);
    right: calc((100vw - 140vh)/2 - 1.5vh + 3.64vh)

}

.squares .square:last-of-type{
    bottom: calc((15 * 79.5vh / 100) - 1.6vh + 14vh);
    right: calc((100vw - 140vh)/2 - 1.5vh + 3.64vh)
}

.circles .circle:first-of-type{
    top: calc((30 * 79.5vh / 100) - 1.5vh + 6.5vh);
    left: calc((100vw - 140vh)/2 - 1.4vh + 3.64vh)
}

.circles .circle:nth-of-type(2){
    bottom: calc((30 * 79.5vh / 100) - 1.5vh + 14vh);
    left: calc((100vw - 140vh)/2 - 1.4vh + 3.64vh)
}

.circles .circle:nth-of-type(3){
    top: calc((30 * 79.5vh / 100) - 1.5vh + 6.5vh);
    right: calc((100vw - 140vh)/2 - 1.4vh + 3.64vh)
}

.circles .circle:last-of-type{
    bottom: calc((30 * 79.5vh / 100) - 1.5vh + 14vh);
    right: calc((100vw - 140vh)/2 - 1.4vh + 3.64vh)
}

.innerSquare{
    width: 1.9vh;
    height: 1.9vh;
    border-radius: 0.33vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: #e8d5ae 0px 0.15vh 0.45vh;

}

.thirdSquare{
    width: 1.65vh;
    height: 1.65vh;
    background-color: #e5d0a5;
    border-radius: 0.33vh;
    box-shadow: #000 0px 0px 0.15vh 0.15vh inset;
}

.decoratedCircle{
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    background-size: 100% 100%;
    position: fixed;
    background-color: #000;
}

.decoratedCircles .decoratedCircle:first-of-type{
    top: calc((50 * 79.5vh / 100) - 1.5vh + 6.5vh);
    left: calc((100vw - 140vh)/2 - 2.28vh + 3.64vh)
}

.decoratedCircles .decoratedCircle:last-of-type{
    bottom: calc((50 * 79.5vh / 100) - 1.5vh + 14vh);
    right: calc((100vw - 140vh)/2 - 2.28vh + 3.64vh)
}

.decorationBox:not(.expanded) .squares .square:first-of-type, .decorationBox:not(.expanded) .squares .square:nth-of-type(3){
    top: calc((15 * 73.34vh / 100) - 1.6vh + 6.5vh)
}

.decorationBox:not(.expanded) .squares .square:nth-of-type(2), .decorationBox:not(.expanded) .squares .square:last-of-type{
    bottom: calc((15 * 73.34vh / 100) - 1.6vh + 20.84vh)
}

.decorationBox:not(.expanded) .circles .circle:first-of-type, .decorationBox:not(.expanded) .circles .circle:nth-of-type(3){
    top: calc((30 * 73.34vh / 100) - 1.5vh + 6.5vh);
}

.decorationBox:not(.expanded) .circles .circle:nth-of-type(2), .decorationBox:not(.expanded) .circles .circle:last-of-type{
    bottom: calc((30 * 73.34vh / 100) - 1.5vh + 20.84vh);
}

.decorationBox:not(.expanded) .decoratedCircles .decoratedCircle:first-of-type{
    top: unset;
    bottom: calc((50 * 73.34vh / 100) - 1.5vh + 20.84vh);
}

.decorationBox:not(.expanded) .decoratedCircles .decoratedCircle:last-of-type{
    bottom: calc((50 * 73.34vh / 100) - 1.5vh + 20.84vh);
}

@keyframes shake {
    0% { left: -0.5vh; }
    100% { right: -0.5vh; }
}

@keyframes dot-keyframes {
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }
  
    50% {
      opacity: 1;
      transform: scale(1.1, 1.1);
    }
  
    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
}

@media (max-width: 140vh){
    .box{
        width: 95vw;
        padding: 5.7vw 2.85vw 0
    }

    .squares .square:first-of-type{
        top: calc((15 * 79.5vh / 100) - 1.6vh + 5.7vw);
        left: calc(2.5vw - 1.5vh + 2.85vw + 0.54vh)
    }
    
    .squares .square:nth-of-type(2){
        left: calc(2.5vw - 1.5vh + 2.85vw + 0.54vh)
    }
    
    .squares .square:nth-of-type(3){
        top: calc((15 * 79.5vh / 100) - 1.6vh + 5.7vw);
        right: calc(2.5vw - 1.5vh + 2.85vw + 0.54vh)
    
    }
    
    .squares .square:last-of-type{
        right: calc(2.5vw - 1.5vh + 2.85vw + 0.54vh)
    }
    
    .circles .circle:first-of-type{
        top: calc((30 * 79.5vh / 100) - 1.5vh + 5.7vw);
        left: calc(2.5vw - 1.4vh + 2.85vw + 0.54vh)
    }
    
    .circles .circle:nth-of-type(2){
        left: calc(2.5vw - 1.4vh + 2.85vw + 0.54vh)
    }
    
    .circles .circle:nth-of-type(3){
        top: calc((30 * 79.5vh / 100) - 1.5vh + 5.7vw);
        right: calc(2.5vw - 1.4vh + 2.85vw + 0.54vh)
    }
    
    .circles .circle:last-of-type{
        right: calc(2.5vw - 1.4vh + 2.85vw + 0.54vh)
    }

    .decoratedCircles .decoratedCircle:first-of-type{
        top: calc((50 * 79.5vh / 100) - 2.28vh + 5.7vw);
        left: calc(2.5vw - 2.28vh + 2.85vw + 0.54vh)
    }
    
    .decoratedCircles .decoratedCircle:last-of-type{
        bottom: calc((50 * 79.5vh / 100) - 2.28vh + 14vh);
        right: calc(2.5vw - 2.28vh + 2.85vw + 0.54vh)
    }
    
    .decorationBox:not(.expanded) .squares .square:first-of-type, .decorationBox:not(.expanded) .squares .square:nth-of-type(3){
        top: calc((15 * 73.34vh / 100) - 1.6vh + 5.7vw)
    }
    
    
    .decorationBox:not(.expanded) .circles .circle:first-of-type, .decorationBox:not(.expanded) .circles .circle:nth-of-type(3){
        top: calc((30 * 73.34vh / 100) - 1.5vh + 5.7vw);
    }
    
    .decorationBox:not(.expanded) .decoratedCircles .decoratedCircle:first-of-type, .decorationBox:not(.expanded) .decoratedCircles .decoratedCircle:last-of-type{
        top: calc((47 * 73.34vh / 100) - 1.5vh + 5.7vw);
        bottom: unset;
    }

    .dreamRep{
        height: 5.7vw;
        left: 2.5vw;
        width: 95vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2%;
    }

    .moonImage{
        width: 18.4%;
        aspect-ratio: 4.5 / 1;
        height: unset;
    }

    .leftSide, .rightSide{
        width: 47%;
    }

    .arrowWrapper.right{
        right: 4%;
    }

    .arrowWrapper.left{
        left: 4%;
    }

    .bookArea:not(.expanded){
        padding-top: 1.6vh;
    }

    .bookArea:not(.expanded) .leftSide, .bookArea:not(.expanded) .rightSide{
        height: calc(63vh - 0.8vw);
    }

    .tooltip{
        left: calc((7.55vh - 4.4vh)/2)
    }
}

@media (max-width: 125vh){
    .box{
        width: 100vw;
        border: 0;
        padding: 5.9vw 2.1vh 0;
    }

    .squares .square:first-of-type{
        top: calc((15 * 79.5vh / 100) - 1.6vh + 5.9vw);
        left: calc(2.5vw - 1.6vh - 0.6vh)
    }
    
    .squares .square:nth-of-type(2){
        left: calc(2.5vw - 1.6vh - 0.6vh);
        bottom: calc((15 * 79.5vh / 100) - 1.6vh + 12.8vh);
    }
    
    .squares .square:nth-of-type(3){
        top: calc((15 * 79.5vh / 100) - 1.6vh + 5.9vw);
        right: calc(2.5vw - 1.6vh - 0.6vh)
    
    }
    
    .squares .square:last-of-type{
        right: calc(2.5vw - 1.6vh  - 0.6vh);
        bottom: calc((15 * 79.5vh / 100) - 1.6vh + 12.8vw);

    }
    
    .circles .circle:first-of-type{
        top: calc((30 * 79.5vh / 100) - 1.5vh + 5.9vw);
        left: calc(2.5vw - 1.5vh - 0.6vh)
    }
    
    .circles .circle:nth-of-type(2){
        left: calc(2.5vw - 1.5vh - 0.6vh);
        bottom: calc((30 * 79.5vh / 100) - 1.5vh + 12.8vw);
    }
    
    .circles .circle:nth-of-type(3){
        top: calc((30 * 79.5vh / 100) - 1.5vh + 5.9vw);
        right: calc(2.5vw - 1.5vh - 0.6vh)
    }
    
    .circles .circle:last-of-type{
        right: calc(2.5vw - 1.5vh - 0.6vh);
        bottom: calc((30 * 79.5vh / 100) - 1.5vh + 12.8vw);
    }

    .decoratedCircles .decoratedCircle:first-of-type{
        top: calc(50 / 100 * (100vh - 18.7vw) - 2.28vh + 5.7vw);
        left: calc(2.5vw - 2.28vh - 0.7vh)
    }
    
    .decoratedCircles .decoratedCircle:last-of-type{
        bottom: unset;
        top: calc(50 / 100 * (100vh - 18.7vw) - 2.28vh + 5.7vw);
        right: calc(2.5vw - 2.28vh  - 0.7vh)
    }
    

    .decorationBox:not(.expanded) .squares .square:first-of-type, .decorationBox:not(.expanded) .squares .square:nth-of-type(3){
        top: calc(15 / 100 * (100vh - 23.1vw) - 1.6vh + 5.9vw)
    }
    
    .decorationBox:not(.expanded) .squares .square:nth-of-type(2), .decorationBox:not(.expanded) .squares .square:last-of-type{
        bottom: calc(15 / 100 * (100vh - 23.1vw) - 1.6vh + 17.2vw)

    }
    
    .decorationBox:not(.expanded) .circles .circle:first-of-type, .decorationBox:not(.expanded) .circles .circle:nth-of-type(3){
        top: calc(30 / 100 * (100vh - 23.1vw) - 1.5vh + 5.9vw)
    }
    
    .decorationBox:not(.expanded) .circles .circle:nth-of-type(2), .decorationBox:not(.expanded) .circles .circle:last-of-type{
        bottom: calc(30 / 100 * (100vh - 23.1vw) - 1.5vh + 17.2vw)

    }
    
    .decorationBox:not(.expanded) .decoratedCircles .decoratedCircle:first-of-type{
        bottom: calc(50 / 100 * (100vh - 23.1vw) - 1.5vh + 17.2vw)
    }
    
    .decorationBox:not(.expanded) .decoratedCircles .decoratedCircle:last-of-type{
        bottom: calc(50 / 100 * (100vh - 23.1vw) - 1.5vh + 17.2vw)
    }


    .response_text{
        font-size: 1.34vw;
    }

    .dreamTitle{
        font-size: 3.62vw;
        letter-spacing: 0.1vw;
    }

    .imageBox{
        border-radius: 1vw;
    }

    .dreamDate{
        margin-bottom: 0.2vw;
    }

    .dreamDateIcon{
        font-size: 1.75vw;
        margin-right: 0.15vw;
        margin-top: -0.15vw;
    }

    .dreamDateVal{
        font-size: 1.45vw;
    }

    .responseArea{
        padding: 2.5% 2.6% 2.1% 13.5%;
    }

    .contentWrapper{
        padding: 3.1% 13.5% 2.1% 2.6%;   
    }

    .chatContent{
        padding-top: 1.4%;
    }

    .appWrapper{
        margin: 0.65vw 0;
    }

    .appBox{
        border-radius: 1.45vw;
        border-bottom-left-radius: 0.35vw
    }

    .userBox{
        border-radius: 1.45vw;
        border-bottom-right-radius: 0.35vw;
    }

    .timestamp{
        font-size: 1vw;
        margin-bottom: 0.15vw;
    }

    .text{
        font-size: 1.28vw;
    }

    .menuBtn{
        height: 6.2vw;
        padding: 1.5%;
        font-size: 1.85vw;
    }

    .footer.expanded .menuBtn{
        padding: 0.2vw 0.4vw;
        min-height:  7.1vw;;
    }

    .footer.expanded{
        height: 12.8vw;
    }

    .footer{
        height: 17.2vw;
    }

    .menuArea, .inputsArea{
        border-width: 0.17vw;
        padding: 0.28vw;
    }

    .menuArea .btnsWrapper{
        padding: 1.38vw 0.45vw;
    }

    .footer.expanded .menuBox{
        width: calc(25% - 3* 0.11vw);
        padding: 0.04vw 0.3vw 0.3vw;
        padding-top: 0.3vw;
    }

    .footer.expanded .menuBtn{
        font-size: 2vw;
    }

    .btnWrapper{
        border-width: 0.22vw;
        border-radius: 0.2vw;
    }

    .bookArea:not(.expanded) .leftSide, .bookArea:not(.expanded) .rightSide{
        height: 63vh;
        min-height: 51.5vw;
    }

    .bookArea.expanded .leftSide, .bookArea.expanded .rightSide, .bookArea.expanded .rightSide, .bookArea.expanded .rightContainer{
        height: 73vh;
    }

    .chatSeperator{
        height: 0.2vw;
    }

    .typerWrapper{
        margin: 0 0.35vw .7vw -0.35vw;
        padding: 0.45vw;
        border-radius: 1.85vw;
    }

    .typerWrapper textarea{
        font-size: 1.2vw;
        letter-spacing: 0.04vw;
    }

    .sendBtn{
        font-size: 1.5vw;
        width: 8%;
        min-width: 8%;
    }

    .bookArea{
        padding-top: 2.5vw;
    }

    .titleRibbon{
        position: absolute;
        top: -5.8vw;
        width: 62%;
        height: unset;
        aspect-ratio: 6.5 / 1;
    }

    .dreamRep{
        padding: 0 0.25%;
    }

    .title{
        font-size: 4.28vw;
        letter-spacing: 0.033vw;
    }

    .leftSide, .rightSide{
        width: 48%;
    }

    .arrowWrapper.right{
        right: 3.5%;
    }

    .arrowWrapper.left{
        left: 3.5%;
    }

    .content{
        border-width: 0.38vw;
        border-radius: 0.35vw;
    }

    .interpertBtn{
        min-height: 7.1vw;
        font-size: 2.15vw;
        letter-spacing: 0.06vw;
    }

    .tooltip{
        left: 0.67vw;
        padding: 0.8vw 0;
        bottom: 7.5vw;
        font-size: 1.34vw;
        border-radius: 2vw;
        width: 23vw;
    }

    .line{
        height: 2.6vw;
    }

    .btnWrapper{
        border-width: 0.23vw;;
        padding: 3.7% 3%
    }

    .interpertArea{
        padding: 0.048vw 0.32vw 0.32vw;
    }

    .nameBox{
        width: 94%;
        height: unset;
        aspect-ratio: 3.8 / 1;
        padding: 1.5vw 3.8vw;
    }

    .nameBox textarea{
        padding: 0.12vw 0.5vw 0;
        font-size: 1.92vw;
        min-height: 2.15vw;
    }

    .dateValue{
        font-size: 2vw;
    }

    .calendarIcon{
        font-size: 1.55vw;
        margin-top: 0;
        margin-right: 0.5vw;
    }

    .dateBox{
        margin-top: -0.42vw;
    }

    .stone{
        width: 5.2vw;
        height: 5.2vw;
        top: -3vw;
        left: calc(50% - 2.6vw);
    }

    .sectionTitle{
        font-size: 2.12vw;
        margin-bottom: 0.08vw;
    }

    .seperator{
        height: 1.6vw;
    }

    .textBox textarea{
        font-size: 1.45vw;
        line-height: 2.73vw;
        background-size: 100% 2.73vw;
        background-image: linear-gradient(transparent, transparent 2.6vw, #ac8433 2.6vw, #ac8433 2.73vw, transparent 2.73vw);
    }

    .dot {
        height: 1vw;
        width: 1vw;
    }
    
    .loadingBtn .dot{
        height: 1.6vw;
        width: 1.6vw;
    }
    
    .loadingHistoryBtn .dot{
        height: 1.6vw;
        width: 1.6vw;
    }
    
    .loadingDots .dot:nth-child(2){
        margin-left: 0.45vw;
    }
    
    .loadingHistoryBtn .dot:nth-child(2){
        margin-left: 0.6vw;
    }
        
    .loadingBtn .dot:nth-child(2){
        margin-left: 1.2vw;
    }
    
    .loadingDots .dot:nth-child(3){
        margin-left: 0.45vw;
    }
    
    .loadingHistoryBtn .dot:nth-child(3){
        margin-left: 0.6vw;
    }
    
    .loadingBtn .dot:nth-child(3){
        margin-left: 1.2vw;
    }

    .btnsWrapper{
        border-width: 0.2vw;
    }

    .nameBox{
        margin-top: 0.22vw;
    }

    .dateBox{
        margin-top: -0.57vw;
    }

}

@media (max-width: 108vh){
    .decorationBox{
        display: none;
    }

    .box{
        padding: 5.8vw 1.2vh 0;
    }

    .bookArea:not(.expanded) .leftSide, .bookArea:not(.expanded) .rightSide{
        height: 68vh;
    }
}