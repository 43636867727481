.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.8vh;
    border: 0.7vh solid #F2E6D0;
    position: relative;
    box-shadow: inset 3px 5px 7px #000000, -3px -5px 7px #333;
    clip-path: inset(0 round 0.8vh);
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.gameTitle{
    position: absolute;
    top: 4vh;
    left: 18%;
    width: 12.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuBtn{
    position: absolute;
    top: 4vh;
    right: 12%;
    width: 13.2%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; 
}

.gameTitle, .menuBtn{
    font-family: 'Alegreya', serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-transform: uppercase;
    color: #e4d9c4;
    height: 5.5vh;
}

.menuBtn:hover{
    transform: scale(1.2,1.2);
}

.wrapper{
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: #000;
}

.box{
    width: calc(100vh * 720 / 456);
    height: 100vh;
    margin: 0 auto;
    background-image: url('../media/wide_background.png');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
}

.leftArea{
    width: 33%;
    height: 100vh;
    padding: 2vh 0 3vh;
    box-sizing: border-box;
}

.rightArea{
    flex-grow: 1;
    height: 100vh;
    position: relative;
}

.spread{
    position: absolute;
    top: 11.7vh;
    left: 7.8%;
}

.intention{
    position: absolute;
    top: 11.7vh;
    right: 4.5%;
}

.chatBox{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 0.3vh solid #F2E6D0;
    box-shadow: inset 2px 3px 5px #000000, -2px -3px 1px #333;
    margin: 1vh;
    /* position: relative; */
    max-width: 100%;
    height: 100%;
    padding: 0.5vh;
}

.innerBox{
    background-color: #F2E6D0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chatContent{
    flex-grow: 1;
    padding: 3vh 1vh 1vh;
    box-sizing: border-box;
    overflow-y: auto;
}

/* .wrapper{ */
    /* position: fixed;
    top: 7vh;
    left: calc((100vw - 125vh) / 2);
    position: fixed;
    height: 90vh;
    z-index: 2000;
} */

.userWrapper{
    display: flex;
    justify-content: flex-end;
    margin: 0.75vh 0;
}

.userBox{
    padding: 0.7% 3% 2%;
    max-width: 70%;
    box-sizing: border-box;
    border-radius: 1.6vh;
    border-bottom-right-radius: 0.5vh;
    color: #F2E6D0;
    background-color: #404040;
}

.appBox{
    padding: 0.7% 3% 2%;
    max-width: 70%;
    box-sizing: border-box;
    border-radius: 1.6vh;
    border-bottom-left-radius: 0.5vh;
    margin: 0.35vh 0;
    background-color: #F2E6D0;
    color: #000;
}

.appWrapper{
    display: flex;
    justify-content: flex-start
}

.typerWrapper{
    display: flex;
    margin: 0 1vh 1.5vh;
    padding: 0.7vh;
    border-radius: 2vh;
    box-sizing: border-box;
    align-items: center;
    background-color: #ead7b4;
}

.typerWrapper.multiline{
    align-items: flex-start;
}


.seperator{
    margin: 0 4.5% 3%;
    background-color:  rgba(112, 110, 110, 0.8);
    height: 0.25vh;
    width: 93.5%;
}

.textarea{
    flex-grow: 1;
}

.characterLength{
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 1.42vh;
    color: #F2E6D0;
    font-weight: 600;
    margin-right: 1.3vh;
    margin-bottom: 0.4vh;
}

.typerWrapper textarea{
    background-color: inherit;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    resize: none;
    outline: 0;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.33vh;
    letter-spacing: 0.05vh;
    display:inline-block;
    vertical-align:middle;
    line-height: 1.7;
    overflow-y: hidden;
    color: #fff;
    font-weight: 600;
}

.typerWrapper textarea::placeholder{
    color: #878686;
}

.text{
    font-family: 'Almendra', serif;
    letter-spacing: 0.035vh;
    font-size: 1.35vh;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
}

.sendBtn{
    width: 7.5%;
    min-width: 7.5%;;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75vh;
    cursor: pointer;
    color: #ead7b4;
    background-color:#333
}

.typerWrapper.multiline .sendBtn{
    margin-top: 1%;
}

.loadingDots {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F2E6D0;
    padding: 2.3% 5.5%;
    box-sizing: border-box;
    border-radius: 1.6vh;
    margin: 0.35vh 0;
    width: fit-content;
}

.dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #6d6b66;
    border-radius: 50%;
    display: inline-block;
    height: 1.1vh;
    width: 1.1vh;
}
    
.loadingDots .dot:nth-child(2) {
    animation-delay: .25s;
    margin-left: 0.55vh;
}
    
.loadingDots .dot:nth-child(3) {
    animation-delay: 0.5s;
    margin-left: 0.55vh;
}

.timestamp{
    text-align: right;
    font-size: 1.2vh;
    margin-bottom: 0.2vh;
    font-family: 'Lato', sans-serif;
}

.titleWrapper{
    width: 20vh;
    height: 6vh;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;;
    box-sizing: border-box;
    margin: 0 auto;
    font-family: 'IM Fell English SC', serif;
    font-size: 2.7vh;
    font-weight: 600;
    font-style: italic;
    position: fixed;
    top: 0.7vh;
    z-index: 10;
    --width: calc(100vh * 720 / 456);
    --chat_width: calc(34 * var(--width) / 100);
    --offset: calc((var(--chat_width) - 20vh) / 2);
    left: calc((100vw - var(--width)) / 2 + var(--offset) );
}


.chatContent::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 0.75vw rgba(0,0,0,0.3);
	background-color: rgba(112, 110, 110, 0.4)
}

.chatContent::-webkit-scrollbar
{
	width: 1vh;
	background-color: #F2E6D0;
}

.chatContent::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 0.75vw rgba(0,0,0,.3);
	background-color: #7a7468;
}


.typerWrapper.shake{
    position: relative;
    animation: shake .15s linear;
    animation-iteration-count: 5;
}

.cards{
    position: relative;
}

.card{
    width: 12.8vh;
    /* height: 16.5vh; */
    aspect-ratio: 13 / 19.5;
    border-radius: 1vh;
    position: absolute;

}

.card img{
    width: 100%;
    height: 100%;
    border-radius: inherit;
    cursor: pointer;
}

.card img:hover{
    transform: scale(1.1,1.1);
}

.card.reversed img{
    transform: scaleY(-1);
}

.card.reversed img:hover{
    transform: scale(1.1,1.1) scaleY(-1);
}

.cards[length="3"] .card{
    width: 14vh;
    aspect-ratio: 13 / 19.5;
}

.cards .card:first-of-type{
    top: 38.5vh;
    left: 36%;
}

.cards[length="3"] .card:first-of-type{
    top: 37vh;
    left: 47%;
}

.cards[length="3"] .card:nth-of-type(2){
    top: 66vh;
    left: 36%;
}

.cards[length="3"] .card:last-of-type{
    top: 66vh;
    left:59%;
}

.cardName{
    width: 130%;
    text-align: center;
    margin-left: -15%;
    background-color: #3c3b3b;
    color: #F2E6D0;
    padding: 0.5vh 0;
    border-radius: 1.5vh;
    font-family: 'Macondo', cursive;
    font-size: 1.95vh;
    margin-top: 1vh;
}

/* .cards[length="3"] .card:first-of-type{
    left: 45%;
}

.cards[length="3"] .card:nth-of-type(2){
    top: 58vh;
    left: 40%;
}

.cards[length="3"] .card:last-of-type{
    left: 50%;
    top: 58vh;
} */

.cards .card:nth-of-type(2){
    top: 38.5vh;
    left: 54%;
}

.cards .card:nth-of-type(3){
    top: 65vh;
    left: 24%;
}

.cards .card:nth-of-type(4){
    top: 65vh;
    left: 44%;
}

.cards .card:last-of-type{
    top: 65vh;
    left: 64%;
}

.cards[length="5"] .cardName{
    font-size: 1.8vh;
    padding: 0.6vh 0;
    margin-top: 0.8vh;
}

@keyframes shake {
    0% { left: -0.5vh; }
    100% { right: -0.5vh; }
}

@keyframes dot-keyframes {
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }
  
    50% {
      opacity: 1;
      transform: scale(1.1, 1.1);
    }
  
    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
}

@media (max-width: 125vh){
    .container{
        aspect-ratio: 1 / 1.85;
        height: 100%;
    }

    .wrapper{
        left: calc((100vw - 90vh / 1.85) * 0.5);
    }
}

@media (not (orientation: landscape)) and (not (height: 100vw)){
    .wrapper {
        height: 90vw;
        top: calc(0.5*(100vh - 100vw) + 10vw);
        left: calc((100vw - 91.5vw / 1.85) * 0.5);
    }

    .titleWrapper{
        top: calc(0.5*(100vh - 100vw) + 7vw);
        width: 25vw;
        height: 8vw;
        font-size: 2.9vw;
        left: calc((100% - 25vw)/2);
    }

    .container{
        aspect-ratio: 1 / 1.85;
        max-height: calc(48vw * 1.85);
        overflow: hidden;
        height: unset;
        width: 48vw;
        border-radius: 0.9vw;
        border-width: 0.82vw;
        clip-path: inset(0 round 0.9vw);
    }

    .box{
        border-width: 0.4vw;
        margin: 1.1vw;
        overflow: hidden;
    }

    .chatContent{
        padding: 3.1vw 1.1vw 1.1vw;
    }

    .userWrapper{
        margin: 0.77vw 0;
    }

    .userBox{
        border-radius: 1.8vw;
        border-bottom-right-radius: 0.4vw;;
    }

    .appBox{
        border-radius: 1.8vw;
        border-bottom-left-radius: 0.4vw;
        margin: 0.36vw 0;
    }

    .timestamp{
        font-size: 1.23vw;
        margin-bottom: 0.22vw;
    }

    .text{
        letter-spacing: 0.045vw;
        font-size: 1.37vw;
    }

    .typerWrapper{
        margin: 0 1.05vw 1.45vw;
        padding: 0.72vw;
        border-radius: 2.2vw;
    }

    .typerWrapper textarea{
        font-size: 1.35vw;
        letter-spacing: 0.05vw
    }

    .sendBtn{
        font-size: 1.77vw;
    }

    .loadingDots {
        border-radius: 1.62vw;
        margin: 0.37vw 0;
    }
    
    .dot {
        height: 1.12vw;
        width: 1.12vw;
    }
        
    .loadingDots .dot:nth-child(2) {
        margin-left: 0.57vw;
    }
        
    .loadingDots .dot:nth-child(3) {
        margin-left: 0.57vw;
    }

    .seperator{
        height: 0.26vw;
    }

    .chatContent::-webkit-scrollbar
    {
        width: 1.2vw;
    }

    .characterLength{
        font-size: 1.43vw;
        margin-right: 1.32vw;
        margin-bottom: 0.42vw;
    }
    

    @keyframes shake {
        0% { left: -0.52vw; }
        100% { right: -0.52vw; }
    }
}
