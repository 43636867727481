@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Belanosima&display=swap');

.wrapper{
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    z-index: 10;
}

.box{
    background-color: #252424;
    width: 95%;
    max-width: 540px;
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    max-height: calc(100vh - 50px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.box header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11.5px 12px;
    border-bottom: 1px solid #3e3e40;
}

.menuWrapper{
    position: relative;
}

.closeIcon, .menuIcon{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 7px;
    cursor: pointer;
    color: #a2a0a2;
}

.closeIcon:hover, .menuIcon:hover, .menuIcon.active{
    color: #fff;
    background-color: #424244;
}

.content{
    flex-grow: 1;
    overflow-y: auto;
    padding: 13px 17px;
}

.dateWrapper{
    display: flex;
    border-radius: 10px;
    margin: 0 auto 6.5px;
    justify-content: center;
}

.dateWrapper .dateItem:first-of-type{
    border-radius: 10px 0 0 10px;
    background-color: #454343;
}

.dateWrapper .dateItem:last-of-type{
    border-radius: 0 10px 10px 0;
    background-color: #454343;
}

.dateItem{
    padding: 7px 11px;
    text-align: center;
}

.dateTitle{
    color: #b3b1b1;
    font-family: 'Poppins', sans-serif;
    font-size: 11.6px;
    margin-bottom: 2px;
}

.dateValue{
    font-family: 'Poppins', sans-serif;
    font-size: 12.1px;
    letter-spacing: 0.2px;
}

.dateWrapper .dateItem:last-of-type{
    border-left: 1.7px solid #515050;
}

.titleBox{
    display: flex;
    align-items: center;
}

.title{
    font-family: 'Belanosima', sans-serif;
    font-weight: 600;
    font-size: 23px;
    letter-spacing: 0.5px;
}

.sectionTitle{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 13.5px;
    letter-spacing: 0.15px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.icon{
    color:#e8d2ad;
    font-size: 14.5px;
    display: flex;
    align-items: center;
}

.text{
   color: #dcd9d9;
   font-family: 'Poppins', sans-serif;
   font-size: 12.5px;
   line-height: 1.65;
}

.content .section{
    margin-bottom: 10px;
}

.imageBox{
    width: 100%;
    aspect-ratio: 2.15 / 1;
    margin: 7px 0 5px;
    background-color: #323233;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.imageBox img{
    height: 85%;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
}

.questionBox{
    margin-bottom: 16px;
    border-bottom: 0.85px solid #4e4c4c;
    padding: 12px 0;
    margin: 0 -17px;
}

.questions .questionBox:first-of-type{
    padding-top: 7px;
}

.questions .questionBox:last-of-type{
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
}

.question{
    padding: 6.5px 17px;
    border-left: 3px solid #e8d2ad;
    background-color: #3c3b3b;
    font-family: 'DM Sans', sans-serif;
    font-size: 13.7px;
    min-height: 36px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: 1.5;
}

.dateBox{
    margin: 9px 17px 7px;
    display: inline-flex;
    align-items: center;
    background-color: #565454;
    color: #fae8c8;
    padding: 5px 8px;
    box-sizing: border-box;
    border-radius: 12px;
}

.date{
    font-family: 'DM Sans', sans-serif;
    font-size: 12.1px;
}

.timeIcon{
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-right: 3px;
    margin-top: -1px;
}

.answer{
    color: #dcd9d9;
    font-family: 'Poppins', sans-serif;
    font-size: 12.4px;
    line-height: 1.65;
    margin: 0 17px;
}

.arrowIcon{
    float: left;
    color: #e8d2ad;
    font-size: 17px;
    margin-top: -1px;
    height: 15px;
    margin-right: 3px;
}

.fetchingLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 50px 0;
}

.spinner{
    border: 3.8px solid #676667;
    border-radius: 50%;
    border-top: 3.8px solid transparent;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media(max-width: 550px){
    .wrapper{
        display: block;
    }

    .box{
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
    }
}

@media(max-width: 450px){
    .text{
        font-size: 12.2px;
    }

    .content{
        padding: 13px 14px;
    }

    .question{
        padding: 6.5px 14px;
        font-size: 13.2px;
    }

    .dateBox{
        padding: 9px 14px 7px;
    }

    .answer{
        margin: 0 14px;
        font-size: 12px;
    }

    .questionBox{
        margin: 0 -14px;
    }

    .date{
        font-size: 11.8px;
    }

    .dateValue{
        font-size: 11.8px;
    }

    .dateTitle{
        font-size: 11.3px;
    }
}

@media(max-width: 350px){
    .content{
        padding: 13px 10px;
    }

    .question{
        padding: 6.5px 10px;
    }

    .dateBox{
        padding: 9px 10px 7px;
    }

    .answer{
        margin: 0 10px;
    }

    .questionBox{
        margin: 0 -10px;
    }

    .title{
        font-size: 21px;
    }

    .dateItem{
        padding: 7px;
    }
}

@media(max-width: 310px){
    .title{
        font-size: 19.5px;
    }
}