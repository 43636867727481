.wrapper{
    top: 0;
    left: 0;
    position: fixed;
    background-color: #000;
    width: 100%;
}

.box{
    aspect-ratio: 1.1 / 1;
    height: 100vh;
    background-image: url('../media/newBackground.png');
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    color: #fff
}

.gameTitle{
    position: absolute;
    top: 4.5vh;
    left: 15%;
    width: 11.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuBtn{
    position: absolute;
    top: 4.5vh;
    right: 13%;
    width: 13.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; 
}

.gameTitle, .menuBtn{
    font-family: 'Alegreya', serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-transform: uppercase;
    color: #e4d9c4;
    height: 5.5vh;
}

.menuBtn:hover{
    transform: scale(1.2,1.2);
}

.spread{
    position: absolute;
    top: 11.5vh;
    left: 2.5vh
}

.intention{
    position: absolute;
    top: 11.5vh;
    right: 2.5vh
}

.content{
    position: absolute;
    bottom: 10.2vh;
}


.btns{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn{
    border: 0.15vh solid #c8b38e;
    padding: 0.5vh 0.4vh;
    outline: 0;
    background-color: unset;
    border-radius: 2.2vh;
    background-color: #ebd6b1;
    color: #000;
    font-family: 'Eczar', serif;
    font-size: 2.35vh;
    cursor: pointer;
    font-weight: 600;
    box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.6);
}

.btn:hover{
    transform: scale(1.1,1.1);
}

.btns .btn:first-of-type{
    margin-right: 2vh;
}

.innerBtn{
    border: 0.15vh solid #000;
    padding: 0.35vh 2vh;
    border-radius: 1.9vh;
    box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.6);
}

.instructions{
    font-family: Century Gothic;
    font-size: 2.2vh;
    letter-spacing: 0.1vh;
    padding: 0 14%;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    line-height: 1.35;
    margin-bottom: 3vh;
    text-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 40px #e2cfad, 0 0 70px #e2cfad, 0 0 80px #e2cfad, 0 0 100px #e2cfad;
    font-weight: 600;

}

.cards{
    width: 110vh;
    position: relative;
}

.card{
    width: 8.5vh;
    height: 13vh;
    border: 0.23vh solid #323131;
    border-radius: 0.8vh;
    position: absolute;
}

.cards[length="3"] .card{
    width: 9.5vh;
    height: 14.5vh;
}

.cards .card:first-of-type{
    top: 39vh;
    left: 40%;
}

.cards[length="3"] .card:first-of-type{
    top: 38vh;
    left: 45%;
}

.cards[length="3"] .card:nth-of-type(2){
    top: 55vh;
    left: 38%;
}

.cards[length="3"] .card:last-of-type{
    top: 55vh;
    left:52%;
}


.cards .card:nth-of-type(2){
    top: 39vh;
    left: 50%;
}

.cards .card:nth-of-type(3){
    top: 55vh;
    left: 36%;
}

.cards .card:nth-of-type(4){
    top: 55vh;
    left: 46%;
}

.cards .card:last-of-type{
    top: 55vh;
    left: 56%;
}