.menu{
    position: absolute;
    box-shadow: 0 0 0 1px #424244;
    background-color: #1e1f21;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 7px;
    top: 40px;
    left: -77.5px;
    width: 223px;
    color: #fff;
    z-index: 3;
}

.menu header{
    padding: 8px 12px;
    background-color: #333436;
    border-radius: 7px 7px 0 0;
    font-family: 'Poppins', sans-serif;
    font-size: 12.4px;
    font-weight: 600;
}

.content{
    border-top: 0.8px solid #4d4b4b;
    border-bottom: 0.8px solid #4d4b4b;
    padding: 5.5px 7px 3px;
}

.fieldItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fieldRep{
    display: flex;
    align-items: center;
}

.fieldItem{
    display: flex;
    align-items: center;
    padding: 6.5px 5px;
    cursor: pointer;
    margin-bottom: 4.5px;
    border-radius: 6px;
}

.check{
    width: 20px;
    display: flex;
    padding-right: 5px;
    box-sizing: border-box;
    font-size: 15px;
}

.fieldItem:hover{
    background-color: #514e4e;
}

.fieldItem.selected{
    color: #f5e3c5;
    background-color: #514e4e;
    font-weight: 600;
}

.fieldMenu .fieldItem:last-of-type{
    margin-bottom: 0;
}

.fieldName{
    font-family: 'Poppins', sans-serif;
    font-size: 11.2px;
    margin-left: 3px;
}

.fieldIcon{
    display: flex;
    align-items: center;
    font-size: 13.1px;
}

.orderWrapper{
    display: flex;
    align-items: center;
}

.order{
    width: 15px;
    height: 15px;
    font-size: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.3px solid #7e7c7c;
    border-radius: 50%;
}

.order:hover{
    background-color: #9c9b9b;;
}

.order.selected{
    background-color: #f5e3c5;
    color: #000;
}

.orderWrapper .order:last-of-type{
    margin-left: 3px;
}


.menu footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 3px;
    padding: 10px;;
}

.saveBtn{
    height: 28px;
    padding: 0 7px;
    border: 0;
    outline: 0;
    background-color: #f5e3c5;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 11.2px;
    border-radius: 5.5px;
    font-weight: 600;
    cursor: pointer;
}

.saveBtn:hover{
    background-color: #e8d4b2;
}

.saveBtn:disabled{
    opacity: 0.6;
    pointer-events: none;
}

.resetBtn{
    height: 28px;
    padding: 0 10px;
    border: 0;
    outline: 0;
    border: 1.25px solid #7e7c7c;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 11.2px;
    border-radius: 5.5px;
    font-weight: 600;
    margin-right: 10px;
    cursor: pointer;
    background-color: inherit;
    box-sizing: border-box;
}

.resetBtn:hover{
    background-color: #676464;
}

@media(max-width: 750px){
    .menu{
        left: unset;
        right: 0;
    }
}