.menu{
    position: absolute;
    box-shadow: 0 0 0 1px #424244;
    background-color: #1e1f21;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 7px;
    top: 40px;
    left: -85px;
    width: 240px;
    color: #fff;
    z-index: 3;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.menu header{
    padding: 8px 12px;
    background-color: #333436;
    border-radius: 7px 7px 0 0;
    font-family: 'Poppins', sans-serif;
    font-size: 12.4px;
    font-weight: 600;
}

.content{
    border-top: 0.8px solid #4d4b4b;
    border-bottom: 0.8px solid #4d4b4b;
    padding: 10px 7px 13px;
    overflow-y: auto;
    max-height: calc(100vh - 230px);
    flex-grow: 1;
    box-sizing: border-box;
}

.section{
    margin-bottom: 11px;
}

.content .section:last-of-type{
    margin-bottom: 0;
}

.menu footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 3px;
    padding: 10px;
}

.saveBtn{
    height: 28px;
    padding: 0 7.5px;
    border: 0;
    outline: 0;
    background-color: #f5e3c5;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    border-radius: 5.5px;
    font-weight: 600;
    cursor: pointer;
}

.saveBtn:hover{
    background-color: #e8d4b2;
}

.saveBtn:disabled{
    opacity: 0.6;
    pointer-events: none;
}

.resetBtn{
    height: 28px;
    padding: 0 10px;
    border: 0;
    outline: 0;
    border: 1.25px solid #7e7c7c;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    border-radius: 5.5px;
    font-weight: 600;
    margin-right: 10px;
    cursor: pointer;
    background-color: inherit;
    box-sizing: border-box;
}

.resetBtn:hover{
    background-color: #676464;
}

.label{
    font-size: 12.4px;
    font-family: 'Lato', sans-serif;
    color: #ccc8c8;
    margin-bottom: 6px;
}

.selectorBox{
    position: relative;
}

.selectorInput{
    display: flex;
    align-items: center;
    border: 1.2px solid #565557;
    width: 100%;
    padding: 0 8px;
    height: 33.5px;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
}

.selectorInput.disabled{
    pointer-events: none;
    cursor: unset;
    opacity: 0.65;
}

.valueBox{
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.selectorInput:hover, .selectorInput.active{
    background-color: #3d3e3f;
}

.value{
    font-family: 'Poppins', sans-serif;
    font-size: 11.5px;
    margin-left: 4.5px;
}

.icon{
    display: flex;
    align-items: center;
    margin-top: -2.5px;
    font-size: 13px;
}

.fieldMenu{
    position: fixed;
    box-shadow: 0 0 0 1px #424244;
    background-color: #292a2d;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 4px;
    width: 100%;
    padding: 3px 0;
    box-sizing: border-box;
    z-index: 3;
    overflow-y: auto;
}


.fieldItem{
    display: flex;
    align-items: center;
    padding: 5px 12px;
    cursor: pointer;
    margin-bottom: 1px;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
}

.itemBox{
    display: flex;
    padding: 5px 11px;
    cursor: pointer;
}

.itemName{
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
}

.selectedName{
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
}

.delete{
    display: flex;
    align-items: center;
    margin-left: 7.5px;
    font-size: 12.7px;
    cursor: pointer;
}

.delete:hover{
    transform: scale(1.25,1.25);
}

.spreadIcon{
    display: flex;
    align-items: center;
    font-size: 11px;
    margin-right: 4px;
}

.spreadIcon path{
    stroke: #fff !important;
}

.card{
    font-family: 'Poppins', sans-serif;
    font-size: 11.5px; 
}


.fieldItem:hover, .itemBox:hover{
    background-color: #514e4e;
}

.fieldItem.selected, .itemBox.selected{
    color: #f5e3c5;
    background-color: #514e4e;
    font-weight: 600;
}

.fieldMenu .fieldItem:last-of-type{
    margin-bottom: 0;
}

.removeBtn{
    position: absolute;
    height: 23px;
    width: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    top: 4.5px;
    right: 0;
    font-size: 17px;
    cursor: pointer;
}

.removeBtn:hover{
    background-color: #414244;;
}

.min .selectorInput, .min .fieldMenu{
    width: calc(100% - 30px);
}

.check{
    width: 16px;
    display: flex;
    padding-right: 5px;
    box-sizing: border-box;
    font-size: 16px;
}

.selectedBox{
    display: inline-flex;
    align-items: center;
    padding: 4px 6px;
    background-color: #414244;
    border-radius: 5px;
    margin-bottom: 6px;
}

.results{
    padding-top: 10px;
}

.content .section:nth-of-type(2) .results{
    padding-top: p6x;
}

.results .selectedBox:last-of-type{
    margin-bottom: 0;
}

.content .section:last-of-type .results{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -6px;
}

.content .section:last-of-type .selectedBox{
    margin-right: 5.5px;
}

.content .section:last-of-type .results .selectedBox:last-of-type{
    margin-bottom: 6px;
}

@media(max-width: 750px){
    .menu{
        left: unset;
        right: 0;
    }
}