.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(64, 65, 68, 0.82);
    z-index: 6000;
}

.loaderWrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pulseLoader{
    animation: pulse 4s infinite linear;
    width: 32vh;
    height: 32vh;
    border-radius: 50%;
    background-color: #b1a898;
}
  
.innerLoader{
    animation: pulse 4s 1s infinite linear;
    width: 26vh;
    height: 26vh;
    border-radius: 50%;
    background-color: #ebd6b1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17vh;
}

.innerLoader img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.loadingText{
    font-family: 'Caudex', serif;
    color: #efd9b8;
    font-size: 4.3vh;
    font-weight: 600;
    letter-spacing: 0.05vh;
    margin-top: 2vh
}

@keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 0.1;
    }
    33% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0.1;
    }
}

@media (max-width: 106vh){
    .pulseLoader{
        height: 33vw;
        width: 33vw;
    }
      
    .innerLoader{
        width: 27vw;
        height: 27vw;
        font-size: 17.5vw;
    }

    .loadingText{
        font-size: 4.4vw;
        letter-spacing: 0.06vw;;
        margin-top: 2.1vw;
    }
    
}