.box{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.bookArea{
    padding: 3vh 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.leftPage{
    width: 42%;
    height: 71.5vh;
    background-size: 100% 100%;
    padding: 1% 6.5% 0.8% 2%;
    box-sizing: border-box;
    background-image: url('../media/bookside.png');
    transform: scale(-1,1);
}

.rightPage{
    width: 42%;
    height: 71.5vh;
    background-size: 100% 100%;
    padding: 2% 6.5% 0.8% 2%;
    box-sizing: border-box;
    background-image: url('../media/bookside.png');
}

.imageBox{
    width: 50%;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
    border-radius: 1.2vh;
    background-color: #ac9562;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.dreamTitle{
    margin: 0.9vh auto 0.9vh;
    background-color: #ac9562;
    height: 3.05vh;
    width: 65%;
    border-radius: 1vh;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.dreamContent{
    margin: 0.7vh 0 0.2vh;
    width: 100%;
    transform: scale(-1,1);
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.dreamText{
    width: 97%;
    height: 1.6vh;
    border-radius: 1.4vh;
    background-color: #ac9562;
    margin-bottom: 0.55vh;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.dreamDate{
    height: 2.65vh;
    width: 40%;
    margin-bottom: 0.85vh;
    border-radius: 0.9vh;
    background-color: #ac9562;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.chatTitle{
    height: 2.8vh;
    width: 60%;
    border-radius: 1vh;
    background-color: #ac9562;
    margin: 0 auto 1vh;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.dialog{
    overflow-y: hidden;
    padding: 2vh 0;
    width: 100%;
    flex-grow: 1;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.outputWrapper{
    display: flex;
   justify-content: flex-start;
    width: 100%;
    height: 31%;
    margin-bottom: 1.2vh;
}

.output{
    width: 63%;
    height: 100%;
    background-color: #ac9562;
    border-radius: 2vh;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.inputWrapper{
    width: 100%;
    height: 31%;
    display: flex;
    margin-bottom: 1.2vh;
    justify-content: flex-end;
}

.input{
    width: 63%;
    background-color: #ac9562;
    border-radius: 2vh; 
    height: 100%;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.chatFooter{
    height: 6vh;
}

.chatInput{
    display: flex;
    margin: 2% 0.5vh .3vh -0.5vh;
    padding: 0.7vh;
    border-radius: 2vh;
    box-sizing: border-box;
    align-items: center;
    background-color: #ac9562;
    height: 4.2vh;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.seperator{
    background-color: rgba(0, 0, 0, 0.15);
    height: 0.23vh;
    animation: skeleton-loading 1.8s linear infinite alternate;
}

.chatContent{
    display: flex;
    height: 100%;
    flex-direction: column;
}

@keyframes skeleton-loading{
    0%{
        opacity: 0.35;
    }
    100%{
        opacity: 1;
    }
}


@media (max-width: 140vh){
    .leftPage, .rightPage{
        width: 47%;
    }
}


@media (max-width: 125vh){
    .leftPage, .rightPage{
        width: 48%;
        height: 73vh;
    }

    .imageBox{
        border-radius: 1vw;
    }
    
    .dreamTitle{
        margin: 0.75vw auto;
        height: 2.9vw;
        border-radius: 0.65vw;
    }
    
    .dreamContent{
        margin: 0.6vw 0 0.1vw;
    }
    
    .dreamText{
        height: 1.45vw;
        border-radius: 1.25vw;
        margin-bottom: 0.4vw;
    }
    
    .dreamDate{
        height: 2.45vw;
        margin-bottom: 0.7vw;
        border-radius: 0.8vw;
    }
    
    .chatTitle{
        height: 2.6vw;
        border-radius: 0.9vw;
        margin: 0 auto 0.85vw;
    }
    
    .dialog{
        padding: 1.7vw 0;
    }
    
    .outputWrapper{
        margin-bottom: 1.1vw;
    }
    
    .output{
        border-radius: 1.7vw;
    }
    
    .inputWrapper{
        margin-bottom: 1.1vw;
    }
    
    .input{
        border-radius: 1.7vw;
    }
    
    .chatFooter{
        height: 6vw;
    }
    
    .chatInput{
        height: 4.05vw;
        margin: 0 0.35vw .7vw -0.35vw;
        padding: 0.45vw;
        border-radius: 1.85vw;
    }
    
    .seperator{
        height: 0.2vw;
        margin-bottom: 2.5%;
    }
}