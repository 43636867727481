.wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #22272b;
    padding: 40px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color-scheme: dark;
}

.box{
    width: 100%;
    min-height: 73vh;
    max-width: 1100px;
    display: flex;
    align-items: stretch;
    box-shadow: rgba(112, 128, 144, 0.05) 0px 54px 55px, rgba(112, 128, 144, 0.12) 0px -12px 30px, rgba(112, 128, 144, 0.12) 0px 4px 6px, rgba(112, 128, 144, 0.17) 0px 12px 13px, rgba(112, 128, 144, 0.09) 0px -3px 5px;
    border-radius: 14px;
    background-color: #161616;
}

.leftSection{
    width: calc(47% - 30px);
    border-radius:16px;
    background-color: #ebd6b1;
    margin: 15px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
    padding: 0 20px;
}


.rightSection{
    border-radius: 0 14px 14px 0px;
    color: #fff;
    width: 53%;
    box-sizing: border-box;
    position: relative;
}

.contentWrapper{
    padding: 38px 20px 60px;
}

.rightSection form{
    width: 100%;
    box-sizing: border-box;
}

.content{
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
}

.title{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0.23px;
    position: relative;
    color: #ebd6b1;
}

.title::after {
    content: "";
    position: absolute;
    left: 2px;
    bottom: -5px;
    width: 11%; 
    height: 4px; 
    background-color: #c3a36e;
    border-radius: 12px;
  }

.stepper{
    display: flex;
    align-items: center;
    padding: 14px 5px;
    box-sizing: border-box;
    border-top: 2.1px solid #2d2c2c;
    border-bottom: 2.1px solid #2d2c2c;
    margin:40px 0 30px;
}

.stepBox{
    display: flex;
    align-items: center;
    width: fit-content;
    width: 150px;
}

.stepNum, .check{
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: #ebd6b1;
    border-radius: 9.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
}

.stepNum.current, .check{
    background-color: #d4b27c;;
}

.stepNum{
    font-family: 'Poppins', sans-serif;
    font-size: 14.7px;
    font-weight: 600;
}

.stepName{
    font-family: 'Poppins', sans-serif;
    font-size: 13.5px; 
    margin-left: 7px;
    line-height: 1.15;
}

.stepper .stepBox:last-of-type{
    width: 160px;
}

.inputContainer label{
    font-size: 13.3px;
    color: #c8c3c3;
    margin-bottom: 5px;
    display: block;
    font-family: 'DM Sans', sans-serif;
}

.innerInput{
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    height: 45px;
}

.inputIcon{
    height: 100%;
    position: absolute;
    left: 12px;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #dedbdb;
}

.inputIcon.fullname{
    height: 95%;
    font-size: 16.5px;
}

.warningIcon{
    height: 100%;
    position: absolute;
    right: 8px;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #dedbdb;
    top: 0;
    color: #d53d1f;
}

.locWarningIcon{
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 3px;
    margin-top: -1px;
}

.error .inputIcon, .error input{
    color: #d53d1f;
}

.phoneBox.error, .codeBoxes.error input, .locationBox.errorBox{
    border: 1.6px solid #e23535;
    background-color: #36100a;
}


.phoneBox.error .flagBtn{
    border-color: #e23535;
}

.phoneBox.error input, .phoneBox.error .areaCode, .phoneBox.error .arrow{
    color: #d53d1f;
}

.error .innerInput input{
    border: 1.6px solid #e23535;
}

.innerInput input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    border: 0;
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 12px 0 33px;
    box-sizing: border-box;
    font-size: 12.8px;
    font-family: 'Poppins', sans-serif;
    border-radius: 7px;
}

.innerInput input::placeholder{
    color: #8c8989;
}

.shake{
    animation: shake 1s ease-in-out;
}

.inputContainer{
    margin-bottom: 17px;
}

.inputContainer.fullname{
    position: relative;
}

.lengthBox{
    position: absolute;
    top: 0;
    right: 4px;
    font-family: 'Poppins', sans-serif;
    color: #8c8989;
    font-size: 13px;
}

.btns{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 35px;
}

.submitBtn{
    padding: 0 14px;
    height: 42px;
    border: 0;
    outline: 0;
    background-color: #e8d2ad;
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    margin-left: 15px;
}

.submitBtn:disabled{
    opacity: 0.75;
    pointer-events: none;
    cursor: unset;
    z-index: 0;
    position: relative;
}

.loadingBtn{
    height: 42px;
    width: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8d2ad;
    border-radius: 5px;
    margin-left: 15px;
}

.backBtn{
    padding: 0 14px;
    height: 42px;
    border: 0;
    outline: 0;
    background-color: #4a4848;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
}

.backBtn:hover{
    background-color: #555454;  
}

.expandedBtn .submitBtn{
    width: 100%;
    height: 44px;
    margin-left: unset;
}

.expandedBtn .loadingBtn{
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8d2ad;
    border-radius: 5px;
    margin-left: 0;
}

.loader{
    border: 3px solid #000;
    border-radius: 50%;
    border-top: 3px solid transparent;
    width: 31px;
    height: 31px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.smallLoader{
    border: 2px solid #000;
    border-radius: 50%;
    border-top: 2px solid transparent;
    width: 19px;
    height: 19px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.linkWrapper{
    text-align: right;
    margin-top: 12px;
    margin-right: 15px;
    font-family: 'DM Sans', sans-serif;
    font-size: 13.5px;
    letter-spacing: -0.1px;
}

.linkWrapper a{
    color: #e8d2ad;
    font-weight: 600;
}

.dateInputs{
    display: flex;
    align-items: center;
}

.selectorBox, .locationBox, .phoneBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 0.7px solid rgba(255, 255, 255, 0.3);
    height: 43px;
    padding: 0 12px;
    box-sizing: border-box;
    cursor: pointer;
}

.phoneWrapper{
    display: flex;
    align-items: center;
}

.phoneBox{
    justify-content: unset;
    height: 46.5px;
    padding-left: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 14.4px;
}

.phoneBox.disabled{
    width: calc(100% - 135px);
    opacity: 0.84;
}

.areaCodeWrapper{
    height: 100%;
    position: relative;
    z-index: 3;
}


.flagBtn{
    display: flex;
    align-items: center;
    width: 70px;
    justify-content: center;
    border-right:0.7px solid #5a5858;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.disabled .flagBtn{
    pointer-events: none;
    cursor: none;
}

.flagBtn:hover, .flagBtn.active{
    background-color: #403f3f;
    border-radius: 7px 0 0 7px;
}

.flag{
    width: 26px;
    height: 26px;
    margin-right: 8px;
}

.flag img{
    width: 100% !important;
    height: 100% !important;
}

.phoneBox .arrow{
    font-size: 18px;
}

.areaCode{
    margin-left: 8px;
    margin-right: 6px;
    color: #ccc9c9
}

.phoneBox input{
    flex-grow: 1;
    height: 100%;
    border: 0;
    outline:0;
    background: rgba(255, 255, 255, 0);
    padding-left: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 14.4px;

}

.changeBtn{
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.changeBtn:hover{
    transform: scale(1.04,1.04);
}

.changeIcon{
    font-size: 15px;
    margin-right: 3px;
    display: flex;
    align-items: center;
}

.locationBox{
    padding-right: 8px;
    width: 65%;
    cursor: unset;
}

.doubleWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.doubleWrapper .inputContainer{
    width: calc(50% - 7px);
}

.selectorInput, .placeholder{
    font-size: 12.8px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}

.selectorInput{
    display: flex;
    align-items: center;
}


.arrow{
    display: flex;
    align-items: center;
}

.dateInputs .selectorBox{
    padding: 0 8px;
}

.selectorWrapper{
    margin-right: 12px;
    position: relative;
}

.dateInputs .selectorWrapper:last-of-type{
    margin-right: 0;
}

.dateInputs .arrow{
    margin-left: 8px;
}

.emptyInput{
    color: #afadad;
}

.genderIcon{
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    margin-top: -5px;
}

.locationIcon{
    font-size: 15.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    margin-top: -4px;
}

.locationBtn{
    background-color: #e8d2ad;
    color: #000;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}

.errorBox .locationBtn{
    background-color: #d53d1f;
}

.submitBtn:hover, .locationBtn:hover, .resendBtn:hover{
    background-color: #dfc69b;
}

.errorBox .locationBtn:hover{
    background-color: #e04c2e;
}

.menu{
    position: absolute;
    background-color: #282828;
    width: 100%;
    left: 0;
    padding: 4px 0;
    top: 50px;
    box-shadow: 0 0 0 1px #424244;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 4px;
    z-index: 3;
    overflow-y: auto;
}

.menu::-webkit-scrollbar {
    width: 6px; 
}

.menu::-webkit-scrollbar-track {
    background: #3e3e3e;
    border-radius: 6px;
}

.menu::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 6px;
}

.menuItem{
    display: flex;
    align-items: center;
    height: 36px;
    cursor: pointer;
}

.menuCheck{
    width: 36px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15.5px;
}

.menuValue, .dateInputs .menuItem{
    font-size: 13.7px;
    color: #d9d6d6;
    font-family: 'DM Sans', sans-serif;
}

.dateInputs .menuItem{
    justify-content: center;
    font-size: 12.7px;
    height: 28px;
}


.phoneBox .menu{
    top: 50px;
    width: 230px;
}

.phoneBox .menuItem{
    height: unset;
}

.menuItem:hover{
    background-color: #4a4848
}

.phoneBox .menuItem{
    align-items: unset;
    padding: 6.5px 5px;
    border-bottom: 0.9px solid #4a4848;
    margin-bottom: 2px;
}

.selectedCountry{
    font-weight: 600;
    background-color: #4a4848
}


.countryName{
    font-family: 'Poppins', sans-serif;
    font-size: 11.8px;
    margin-top: 1px;
}

.menuItem .flag{
    width: 18.5px;
    height: 18.5px;
    margin-right: 6px;
}

.searchBox{
    position: relative;
    height: 38px;
    border-bottom:0.7px solid #5a5858;
}


.searchIcon{
    font-size: 13px;
    height: 100%;
    display: flex;
    align-items: center;
    left: 8px;
    position: absolute;
}

.emptyInput .searchIcon{
    color: #afadad;
}


.searchBox input{
    padding-left: 26px;
    padding-right: 5px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-size: 12.6px;
}

.noResults{
    padding: 18px 0 14px;
    font-family: 'Poppins', sans-serif;
    font-size: 13.2px;
    text-align: center;
}

.codeBoxes{
    display: flex;
    align-items: center;
}

.codeBoxes input{
    width: 60px;
    height: 60px;
    margin-right: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    background: #2c2c2c;
    border: 0.7px solid rgba(255, 255, 255, 0.3);
    font-size: 25px;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    outline: 0;
}

.box input:disabled{
    opacity: 0.5;
}

.codeBoxes input:last-child{
    margin-right: 0;
}

.successMsg{
    font-family: 'DM Sans', sans-serif;
    font-size: 13.7px;
    line-height: 1.5;
    margin: -3px 0 10px
}

.successMsg span{
    color: #dfc69b;
    cursor: pointer;
    text-decoration: underline;
}

.successMsg span:hover{
    font-weight: 600;
}

.expiryTime{
    width: 285px;
    text-align: center;
    color: #dfc69b;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    margin: 4px 0 -10px
}

.expiryTime.expired{
    color: #EE4B2B;
    font-weight: 600;
}

.errorBox{
    font-family: 'Poppins', sans-serif;
    font-size: 12.5px; 
    color: #e23535;
    margin-top: -3px;
}

.warningBox{
    background-color: #d53d1f;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 9.5px 8px;
    margin: -14px 0 13px;
}

.successBox .warningBox{
    width: 100%;
    margin: 2px 0 5px;
    max-width: 440px
}

.errorIcon{
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 2.5px;
}

.warningText{
    font-size: 13.2px;
    font-family: 'Poppins', sans-serif;
}

.errorBox.validation{
    height: 14px;
    color: #EE4B2B;
    font-weight: 600;
    margin-top: -2px;
    margin-bottom: 5px;
}

.changeBtn{
    font-family: 'Poppins', sans-serif;
    font-size: 12.5px;
}

.successBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    box-sizing: border-box;
    height: 100%;
}

.verificationTitle{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    font-size: 31px;
    margin: 10px 0 8px;
}

.error .verificationTitle{
    font-size: 29px;
}

.subtext{
    font-family: 'Poppins', sans-serif;
    font-size: 13.4px;
    color: #c8c3c3;
    line-height: 1.8;
    text-align: center;
    max-width: 460px;
}

.error .subtext{
    font-size: 13.2px;
}

.subtext b{
    color: #fff
}

.buttons{
    margin-top: 20px;
    width: 100%;
    max-width: 460px;
}

.loginBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    height: 44px;
    border: 0;
    outline: 0;
    background-color: #666565;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 15.3px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
}

.loginBtn:hover{
    background-color: #4a4848;
}

.resendBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    height: 44px;
    border: 0;
    outline: 0;
    background-color: #e8d2ad;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 15.3px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 17.5px;
}

.mailImage{
    width: 175px;
    height: 175px;
}

.mailImage img{
    width: 100%;
    height: 100%;
}

.error .mailImage{
    width: 165px;
    height: 165px;
}

.rightSection .appTitle{
    display: none;
}

.leftSection .appTitle{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: -14px;
}

.logo{
    width: 33px;
    height: 33px;
}

.logo img{
    width: 100%;
    height: 100%;
}

.name{
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-left: 4.5px;
    font-family:'Parisienne', cursive;
    font-size: 27px;
    color: #000;
    font-weight: 600;
}

.imageSection{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.repImage{
    max-width: 394px;
    width: 97%;
    aspect-ratio: 1 / 1;
    margin: 0 auto 5px;
}

.repImage1{
    max-width: 380px;
}

.repImage img{
    width: 100%;
    height: 100%;
}

.imageTitle{
    font-family: Century Gothic;
    font-weight: 600;
    font-size: 29px;
}

.imageDesc{
    font-family: 'DM Sans', sans-serif;
    line-height: 1.25;
    font-size: 13.6px;
    padding: 5px 8px 13px;
    max-width: 390px;
    text-align: center;
}

.dotStepper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.dot{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #cab591;
    margin-right: 6px;
    cursor: pointer;
}

.dotStepper .dot:last-of-type{
    margin-right: 0;
}

.dot.active{
    background-color: #000;
    width: 31px;
    border-radius: 6px;
    pointer-events: none;
}

.dot:hover{
    transform: scale(1.15,1.15);
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
    20%, 40%, 60%, 80% { transform: translateX(10px); }
}

@-webkit-keyframes shake {
    0%, 100% { -webkit-transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { -webkit-transform: translateX(-10px); }
    20%, 40%, 60%, 80% { -webkit-transform: translateX(10px); }
}
  

@media (max-width: 1060px){
    .leftSection{
        display: none;
    }

    .rightSection{
        width: 100%;
    }

    .box{
        max-width: 500px;
        width: 100%;
        min-height: unset;
        box-sizing: border-box;
    }

    .linkWrapper{
        position: absolute;
        bottom: 26px;
        margin: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }

    .contentWrapper{
        padding-bottom: 53px;
        padding: 24px 20px 58px
    }

    .stepNum{
        width: 28px;
        min-width: 28px;
        height: 28px;
        font-size: 14.3px;
        border-radius: 6.5px;
    }

    .stepName{
        font-size: 13px;
    }

    .stepper{
        padding: 12px 5px;
        margin: 30px 0 20px;
    }

    .inputContainer label{
        font-size: 12.5px;
    }

    .inputIcon{
        font-size: 16.3px;
    }

    .innerInput input{
        font-size: 12.5px;
        border-radius: 5px;
    }

    .inputIcon.password{
        margin-top: -1.5px;
    }

    .btns{
        margin-top: 25px;
    }

    .title{
        font-size: 30.5px;
    }

    .title::after{
        width: 9.5%;
    }

    .rightSection .appTitle{
        display: flex;
        align-items: center;
        margin-top: 17px;
        margin-left: 15px;
    }

    .logo{
        width: 28px;
        height: 28px;
    }

    .logo img{
        width: 100%;
        height: 100%;
    }

    .name{
        display: flex;
        align-items: center;
        margin-top: 3px;
        margin-left: 4.5px;
        font-family:'Parisienne', cursive;
        font-size: 29px;
        color: #d2b98e;
        font-weight: 600;
    }

    .wrapper{
        padding: 40px 0;
    }

    .successBox{
        padding: 0 0 5px;
        margin-top: -5px;
    }

    .mailImage{
        width: 155px;
        height: 155px;
    }

    .verificationTitle{
        font-size: 29px;
    }

    .subtext{
        font-size: 12.9px;
    }

    .resendBtn{
        margin-top: 13px;
    }

    .buttons{
        margin-top: 15px;
    }
}

@media(max-width: 540px){
    .wrapper{
        padding: 0;
    }

    .box{
        height: 100%;
        box-shadow: unset;
        border-radius: unset;
        max-width: unset;
    }

    .rightSection{
        height: fit-content;
    }

    .contentWrapper{
        padding: 24px 14px 58px;
    }

    .rightSection .appTitle{
        margin-top: 23px;
    }
}