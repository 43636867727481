.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(64, 65, 68, 0.65);
    z-index: 6000;
}

/* .boxWrapper{
    border: 4px solid #e59b49;
    border-radius: 1vh;
} */

.box{
    background-image: url('../media/restartBackground.png');
    background-size: 100% 100%;
    width: 65vh;
    aspect-ratio: 471 / 398;
    position: relative;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
    background-color: #000;
    border-radius: 1vh; /* Change as needed */
    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 16px 0px;
    font-family: 'Caudex', serif;
}

.boxWrapper{
    position: relative;
}

.title{
    font-size: 3.3vh;
    font-weight: 600;
    position: absolute;
    top: 36%;
    height: 7.7%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 18%;
    width: 64%;
    color: #fdeed8;
    text-transform: uppercase;
}

.title.complete{
    font-size: 3.6vh;
}

.content{
    position: absolute;
    text-align: center;
    top: 44%;
    color: #fdeed8;
    padding: 0 22%;
    font-size: 2.4vh;
    line-height: 1.1;
    font-weight: 600;
}

.content.complete{
    font-size: 2.5vh;
    top: 43.5%;
}
.buttons{
    top: 63%;
    position: absolute;
    left: 16.5%;
    width: 67%;
    display: flex;
    justify-content: space-between;
    height: 30%;
    align-items: center;
}

.buttons button{
    width: 41%;
    aspect-ratio: 304 / 182;
    background-image: url('../media/restartBtn.png');
    background-size: 100% 100%;
    border: 0;
    outline: 0;
    background-color: unset;
    font-family: 'IM Fell English SC', serif;
    font-size: 3.7vh;
    font-weight: 800;
    cursor: pointer;

}

.buttons button:first-of-type{
    transform: scaleX(-1);
}

.buttons button:first-of-type .text{
    transform: scaleX(-1);
}

.buttons button:hover{
    transform: scale(1.1,1.1);
}

.buttons button:first-of-type:hover{
    transform: scaleX(-1) scale(1.1,1.1);
}

.closeIcon{
    width: 5.5vh;
    height: 5.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: -6vh;
    position: absolute;
    background-color: #000;
    color: #fdeed8;
    border-radius: 50%;
    font-size: 4vh;
    cursor: pointer;
}

.closeIcon:hover{
    background-color: #111111;
    transform: scale(1.05,1.05);
}

.loadingDots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    height: 1.4vh;
    width: 1.4vh;
}
    
.loadingDots .dot:nth-child(2) {
    animation-delay: .25s;
    margin-left: 0.7vh;
}
    
.loadingDots .dot:nth-child(3) {
    animation-delay: 0.5s;
    margin-left: 0.7vh;
}

.buttons.loading{
    pointer-events: none;
}

@keyframes dot-keyframes {
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }
  
    50% {
      opacity: 1;
      transform: scale(1.1, 1.1);
    }
  
    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
}

@media (max-width: 105vh){
    .box{
        width: 68vw;
        aspect-ratio: 471 / 398;
        border-radius: 1vw;
    }
    
    .boxWrapper{
        position: relative;
    }
    
    .title{
        font-size: 3.34vw;
    }
    
    .content{
        font-size: 2.5vw;
    }

    
    .buttons button{
        font-size: 3.9vw;
    
    }
    
    .closeIcon{
        width: 5.5vw;
        height: 5.5vw;
        display: flex;
        right: -6vw;
        font-size: 4vw;
    }
    .dot {
        height: 1.5vw;
        width: 1.5vw;
    }
        
    .loadingDots .dot:nth-child(2) {
        margin-left: 0.75vw;
    }
        
    .loadingDots .dot:nth-child(3) {
        margin-left: 0.75vw;
    }

    .title.complete{
        font-size: 3.7vw;
    }

    .content.complete{
        font-size: 2.6vw;
    }

}