.wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #22272b;
    padding: 20px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color-scheme: dark;
    min-width: 280px;
    overflow-x: hidden;
}

.container{
    width: 100%;
    max-width: 1150px;
    display: flex;
    align-items: stretch;
    box-shadow: rgba(112, 128, 144, 0.05) 0px 54px 55px, rgba(112, 128, 144, 0.12) 0px -12px 30px, rgba(112, 128, 144, 0.12) 0px 4px 6px, rgba(112, 128, 144, 0.17) 0px 12px 13px, rgba(112, 128, 144, 0.09) 0px -3px 5px;
    border-radius: 14px;
}


.rightContainer{
    width: 53%;
    display: flex;
    flex-direction: column;
    padding: 48px 25px;
    box-sizing: border-box;
    background-color: #161616;
    border-radius: 0 14px 14px 0px;
    color: #fff;
}

.appLogo{
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.8px solid rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    color: #262424;
    background-color: rgba(0, 0, 0, 0.13);;
    margin: 0 auto;
}

.appLogo img{
    width: 100%;
    height: 100%;
}

.leftContainer{
    width: 47%;
    border-radius:16px;
    background-color: #ebd6b1;
    margin: 12px 15px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleWrapper{
    margin: 8px 0 30px;
    text-align: center;
}

.title{
    font-family: "Sora", sans-serif;
    font-size: 34px;
    letter-spacing: 0.1px;
    font-weight: 600; 
    color: #ebd6b1;
}

.subtitle{
    font-family: 'Poppins', sans-serif;
    font-size: 13.3px;
    color: #d7bf96;
    font-weight: 600; 
    letter-spacing: 0.2px;
}

.inputContainer label{
    font-size: 13.6px;
    color: #c8c3c3;
    margin-bottom: 5px;
    display: block;
    font-family: 'DM Sans', sans-serif;
}

.rightContainer form{
    width: 100%;
    max-width: 490px;
    margin: 0 auto;
}

.inputs{
    width: 100%;
    padding-bottom: 8px;
    margin-top: -9px;
}

.inputContainer{
    width: 100%;
    margin-bottom: 17.5px;
}

.inputBox{
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    height: 47px;
}


.inputBox input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    border: 0;
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 12px 0 30px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    border-radius: 7px;
}

.inputBox.error input{
    border: 1.6px solid #e23535;
    color: #d53d1f;
}

.inputBox input::placeholder{
    color: #8c8989;

}

.errorBanner{
    background-color: #d53d1f;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 9.5px 8px;
    margin: -20px 0 22px;
}

.errorIcon{
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 2.5px;
}

.errorText{
    font-size: 13.2px;
    font-family: 'Poppins', sans-serif;
}

.inputs input[type="password"]{
    padding: 0 41px 0 38px;
}

.contentWrapper{
    max-width: 530px;
    margin: 0 auto;
    width: 100%;
}

.emailIcon{
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 18px;
    position: absolute;
    left: 8px;
    color: #dedbdb;
}

.emailIcon.placeholder, .passwordIcon.placeholder, .showIcon.placeholder{
    color: #a2a1a1;
}

.warningIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 18px;
    width: 18px;
    position: absolute;
    left: 8px;
    color: #d53d1f;
}

.errorBox{
    font-family: 'Poppins', sans-serif;
    font-size: 12.3px; 
    color: #e23535;
    margin: -4px 0 1px;
}

.passwordIcon, .showIcon{
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
    position: absolute;
    left: 8px;
    color: #dedbdb;
}

.passwordIcon{
    width: 18px;
    justify-content: center;
    top: -1.5px;
}

.showIcon{
    left: unset;
    right: 8px;
    top: 0;
    font-size: 22px;
    cursor: pointer;
}

.linkWrapper{
    margin: 20px auto 0;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #c8c3c3;
}

.linkWrapper a{
    font-weight: bold;
    color: #d7bf96;
    font-size: 13.8px;
    margin-left: 1px;
    text-decoration: none;
}

.linkWrapper a:hover{
    text-decoration: underline;
}

.submitBtn{
    height: 47px;
    border: 0;
    outline: 0;
    background-color: #e8d2ad;
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 16.5px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingBtn{
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8d2ad;
    border-radius: 5px;
}

.loader{
    border: 3px solid #000;
    border-radius: 50%;
    border-top: 3px solid transparent;
    width: 31px;
    height: 31px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.submitBtn:disabled{
    opacity: 0.8;
    pointer-events: none;
    cursor: unset;
    z-index: 0;
    position: relative;
}

.rememberForgot{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
}

.square{
    width: 12.5px;
    height: 12.5px;
    border: 1px solid #747171;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.square:hover{
    background-color: #4e4e4e;
}

.square.checked{
    background-color: #e8d2ad;
    color: #000;
}

.rememberBox{
    display: flex;
    align-items: center;
}

.rememberText{
    font-family: 'DM Sans', sans-serif;
    font-size: 13.3px;
    color: #dedbdb;
    margin-left: 4px;
}

.forgotLink{
    font-family: 'Poppins', sans-serif;
    font-size: 12.8px;
}

.forgotLink a{
    color: #d7bf96;
    font-weight: 600;
}

.name{
    font-family: 'Parisienne', cursive;
    font-size: 25px;
    color: #ebd6b1;
}

@media (max-width: 1060px){
    .leftContainer{
        display: none;
    }

    .rightContainer{
        width: 100%;
        border-radius: 14px;
    }

    .container{
        max-width: 510px;
        width: 100%;
        min-height: unset;
        box-sizing: border-box;
    }

    .rightContainer{
        padding: 39px 30px;
    }
}

@media(max-width: 540px){
    .wrapper{
        padding: 0;
    }

    .container{
        height: 100%;
        box-shadow: unset;
        border-radius: unset;
        max-width: unset;
    }

    .rightContainer{
        height: 100%;
        border-radius: unset;
    }
}

@media(max-width: 480px){
    .rightContainer{
        padding: 30px 20px;
    }
}

@media (max-width: 420px){
    .rightContainer{
        padding: 30px 12px;
    }

    .title{
        font-size: 30px;
    }

    .subtitle{
        font-size: 12.6px;
    }

    .name{
        font-size: 23px;
    }

    .inputContainer label{
        font-size: 13px;
    }

    .inputBox{
        height: 45px;
    }

    .inputBox input{
        font-size: 12.6px;
    }

    .emailIcon{
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 18px;
        position: absolute;
        left: 8px;
        color: #dedbdb;
    }
    
    .warningIcon{
        font-size: 16px;
        width: 17px;
    }
    
    .showIcon{
        font-size: 16px;;
    }
    .passwordIcon{
        width: 17px;
        font-size: 14px;
    }

    .forgotLink{
        font-size: 11.8px;
    }

    .square{
        width: 11.5px;
        height: 11.5px;
        border-radius: 3.5px;
        font-size: 9px;
    }
    
    .rememberText{
        font-size: 12.3px;
    }

    .linkWrapper{
        font-size: 13px;
    }

    .linkWrapper a{
        font-size: 12.8px;
    }

    .appLogo{
        width: 100px;
        height: 100px;
    }

    .titleWrapper{
        margin-top: 5px;
    }

    .submitBtn{
        height: 44.5px;
        font-size: 15.5px;
        border-radius: 4px;
    }

    .loadingBtn{
        height: 44.5px;
        border-radius: 4px;
    }
    
    .loader{
        width: 28px;
        height: 28px;
    }

    .errorBox{
        font-size: 11.8px;
    }

    .errorIcon{
        font-size: 16px;
        margin-top: -1.5px;
    }

    .errorText{
        font-size: 12.2px;
    }
}

@media (max-width: 380px){
    .title{
        font-size: 28px;
    }

    .subtitle{
        font-size: 12px;
    }

    .name{
        font-size: 22px;
    }
}

@media (max-width: 355px){
    .rightContainer{
        padding: 30px 7px;
    }

    .title{
        font-size: 26px;
    }

    .subtitle{
        font-size: 11.5px;
    }

    .name{
        font-size: 21px;
    }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}