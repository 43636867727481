.menuItem{
    display: flex;
    align-items: center;
    padding: 7.2px 11px;
    cursor: pointer;
}

.menuItem:hover{
    background-color: #373839;
}

.menu{
    position: fixed;
    background-color: 0 0 0 1px #424244;
    background-color: #1e1f21;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 7px;
    width: 128px;
    color: #f2e5cd;
    color: #fff;
    padding: 3.5px 0;
    z-index: 10;
    background-color: #1e1f21;
}

.menuText{
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
}

.menuIcon{
    display: flex;
    align-items: center;
    margin-right: 4px;
}


.menuIcon.continue{
    font-size: 16.5px;
}

.menuIcon.view, .menuIcon.edit{
    font-size: 14.5px;
}

.menuIcon.delete{
    font-size: 14px;
    margin-top: -2px;
}

.menu .menuItem:last-of-type{
    color: #e4486a
}

.menu .menuItem:last-of-type:hover{
    background-color: #732234;
    font-weight: normal;
}

.divider{
    border: 0.4px solid #3d3b3b;
}