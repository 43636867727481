.wrapper{
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    color-scheme: dark;
}

.box{
    flex-grow: 1;
    height: 100%;
    background-color: #171616;
    width: 100%;
    overflow-x: hidden;
}

.menuSection{
    top: 0;
    right: 18px;
    position: fixed;
    height: 70px;
    display: flex;
    align-items: center;
}

.profileWrapper{
    position: relative;
}

.profileBtn{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    background-color: #e0c8a0;
    color: #000;
    font-size: 20px;
    cursor: pointer;
    background-size: 100% 100%;
}

@media (max-width: 850px){
    .menuSection{
        right: 18px
    }
}

@media (max-width: 750px){
    .menuSection{
        height: 58px;
    }

    .profileBtn{
        width: 34px;
        height: 34px;
        font-size: 17px;
    }
}