.wrapper{
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(48, 48, 49, 0.75);
    color: #fff;
    z-index: 10;
}

.wrapper form{
    background-color: #252424;
    width: 95%;
    max-width: 540px;
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    min-width: 280px;
    display: flex;
    flex-direction: column;
}

.header{
    height: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    justify-content: space-between;
    border-bottom: 0.85px solid #424244;
}

.titleBox{
    display: flex;
    align-items: center;
}

.titleIcon{
    display: flex;
    align-items: center;
    font-size: 22px;
    margin-right: 5px;
    color: #ee5b5b;
}

.title{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.closeIcon{
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21.5px;
    border-radius: 7px;
    cursor: pointer;
    color: #a2a0a2;
}

.closeIcon:hover{
    color: #fff;
    background-color: #424244;
}

.footer{
    padding: 16px 4px;
    margin: 0 8px;
    border-top: 1px solid #424244;
}


.deleteBtn{
    background-color: #ea4e4e;
    border: 0;
    outline: 0;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    cursor: pointer;
}

.deleteBtn:disabled{
    color: #fff;
    opacity: 0.7;
}

.loadingBtn{
    background-color: #ea4e4e;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 5px;
}

.loader{
    border: 2.5px solid #f6cece;
    border-radius: 50%;
    border-top: 2.5px solid transparent;
    width: 27px;
    height: 27px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.deleteBtn:hover{
    background-color: #dd3a3a;
}

.contentWrapper{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.content{
    padding: 30px 12px;
    flex-grow: 1;
    overflow-y: auto;
}

.text{
    font-family: 'Lato', sans-serif;
    font-size: 14.7px;
    line-height: 1.5;
    color: #ccc8c8;
}

.text b{
    color: #fff;
}

.section{
    margin-top: 10px;
}

.section label{
    font-size: 13.2px;
    color: #aeaaaa;
    margin-bottom: 8.5px;
    display: block;
    font-family: 'DM Sans', sans-serif;
}

.section input{
    height: 41px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    outline: 0;
    border: 1px solid #565557;
    padding: 0 8px;
    background-color: inherit;
    font-size: 12.8px;
    font-family: 'Poppins', sans-serif;
}

.fetchingLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.spinner{
    border: 3.8px solid #676667;
    border-radius: 50%;
    border-top: 3.8px solid transparent;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media(max-width: 500px){
    .wrapper{
        display: block;
    }

    .wrapper form{
        width: 100%;
        height: 100%;
        max-width: unset;
        border-top: 0;
    }

    .header{
        height: 60px;
        min-height: 60px;
    }

    .content{
        padding: 20px 12px;
        flex-grow: unset;
    }

    .footer{
        border-top: 0;
        padding-top: 0;
    }
}

@media(max-width: 370px){
    .dreamContent{
        padding: 20px 8px;
    }

    .text{
        font-size: 13.5px;
    }

    .section label{
        font-size: 12.8px;
    }

    .deleteBtn{
        font-size: 14.2px;
    }
}