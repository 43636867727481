.wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #22272b;
    padding: 20px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color-scheme: dark;
    min-width: 280px;
    overflow-x: hidden;
}

.box{
    width: 100%;
    max-width: 1150px;
    display: flex;
    align-items: stretch;
    box-shadow: rgba(112, 128, 144, 0.05) 0px 54px 55px, rgba(112, 128, 144, 0.12) 0px -12px 30px, rgba(112, 128, 144, 0.12) 0px 4px 6px, rgba(112, 128, 144, 0.17) 0px 12px 13px, rgba(112, 128, 144, 0.09) 0px -3px 5px;
    border-radius: 14px;
}

.leftSection{
    width: calc(47% - 30px);
    border-radius:16px;
    background-color: #ebd6b1;
    margin: 15px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}

.box.crash{
    max-width: 520px;
    background-color: #161616;
    display: block;
    padding: 0 15px 35px;
    box-sizing: border-box;
}

.box.crash .appTitle{
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: -3px;
    margin-bottom: 14px;
}

.box.crash .logo{
    width: 28px;
    height: 28px;
}

.box.crash .logo img{
    width: 100%;
    height: 100%;
}

.box.crash .name{
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-left: 4.5px;
    font-family:'Parisienne', cursive;
    font-size: 28px;
    color: #d2b98e;
    font-weight: 600;
}

.robotImage{
    width: 290px;
    height: 290px;
    margin: 15px auto;
}

.robotImage img{
    width: 100%;
    height: 100%;
}


.leftSection .appTitle{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: -14px;
}

.rightSection .appTitle{
    display: none;
}

.logo{
    width: 33px;
    height: 33px;
}

.logo img{
    width: 100%;
    height: 100%;
}

.name{
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-left: 4.5px;
    font-family:'Parisienne', cursive;
    font-size: 27px;
    color: #000;
    font-weight: 600;
}

.imageSection{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
}

.imageSection img{
    display: block;
    width: 92%;
    aspect-ratio: 442 / 491
}

.rightSection{
    width: 53%;
    display: flex;
    flex-direction: column;
    padding: 48px 25px;
    box-sizing: border-box;
    background-color: #161616;
    border-radius: 0 14px 14px 0px;
    color: #fff;
}

.rightSection .appTitle{
    display: none;
}

.title{
    font-family: "Sora", sans-serif;
    font-size: 33px;
    letter-spacing: 0.1px;
    font-weight: 600; 
    color: #ebd6b1;
    line-height: 1.15;
}

.box.crash .title{
    font-size: 26.7px;
    text-align: center;
}

.instructions, .desc{
    font-family: 'Poppins', sans-serif;
    font-size: 13.7px;
    color: #d7bf96;
    letter-spacing: 0.2px;
    margin-top: 13px;
}

.desc{
    text-align: center;
    font-size: 13.6px;
    margin-top: 8px;
    line-height: 1.5;
}

.submitBtn{
    height: 47px;
    border: 0;
    outline: 0;
    background-color: #e8d2ad;
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 35px;
}

.loadingBtn{
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8d2ad;
    border-radius: 5px;
    margin-top: 35px;
}

.loader{
    border: 3px solid #000;
    border-radius: 50%;
    border-top: 3px solid transparent;
    width: 31px;
    height: 31px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;;
    box-sizing: border-box;
}

.box.crash .submitBtn{
    margin-top: 25px;
}

.statusImage{
    width: 65%;
    max-width: 280px;
    margin: 7px auto 10px;
}

.statusImage.success{
    width: 68%;
    max-width: 300px;
}

.statusImage img{
    width: 100%;
    aspect-ratio: 1 /1;
}

.loaderWrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pulseLoader{
    animation: pulse 4s infinite linear;
    width: 270px;
    height: 270px;
    border-radius: 50%;
    background-color: rgba(110, 112, 110, 0.85)
}
  
.innerLoader{
    animation: pulse 4s 1s infinite linear;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: #ebd6b1;
    position: absolute;
}

.innerLoader img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.loadingText{
    font-family: Century Gothic;
    color: #e3cba2;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-top: 35px;
}

.errorBanner{
    background-color: #d53d1f;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10.5px 8px;
    margin: -6px auto -3px;
    width: fit-content;
    position: relative;
}

.errorIcon{
    display: flex;
    align-items: center;
    font-size: 16.5px;
    position: absolute;
    left: 8px;
    top: 9px;
}

.errorText{
    font-size: 13.2px;
    font-family: 'Poppins', sans-serif;
    text-indent: 19px;
    line-height: 1.25;
}

@media (max-width: 1080px){
    .title.used br{
        display: none;
    }

    .title.used{
        font-size: 31px;
    }
}

@media (max-width: 1060px){
    .leftSection{
        display: none;
    }

    .rightSection{
        width: 100%;
        padding-top: 0;
        border-radius: 14px;
    }

    .box{
        max-width: 500px;
        width: 100%;
        min-height: unset;
        box-sizing: border-box;
    }

    .title{
        font-size: 27px;
    }

    .title br{
        display: none;
    }

    .statusImage, .statusImage.success{
        max-width: 240px;
    }

    .rightSection .appTitle{
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-left: -5px;
        margin-bottom: 14px;
    }

    .logo{
        width: 28px;
        height: 28px;
    }

    .logo img{
        width: 100%;
        height: 100%;
    }

    .name{
        display: flex;
        align-items: center;
        margin-top: 3px;
        margin-left: 4.5px;
        font-family:'Parisienne', cursive;
        font-size: 28px;
        color: #d2b98e;
        font-weight: 600;
    }

    .submitBtn{
        margin-top: 25px;
    }

    .loadingBtn{
        margin-top: 25px;
    }

    .instructions{
        font-size: 13.5px;
    }

    .title.invalid{
        font-size: 28.3px;
    }

}

@media (max-width: 580px){
    .pulseLoader{
        width: 250px;
        height: 250px;
    }
      
    .innerLoader{
        width: 220px;
        height: 220px;
    }
    
    .loadingText{
        font-size: 22px;
        margin-top: 25px;
    }
    
}


@media(max-width: 530px){
    .wrapper{
        padding: 0;
    }

    .box, .box.crash{
        height: 100%;
        box-shadow: unset;
        border-radius: unset;
        max-width: unset;
    }

    .rightSection{
        height: 100%;
        border-radius: unset;
        padding: 0;
    }

    .contentWrapper{
        padding: 14px 18px 10px;
    }

    .rightSection .appTitle{
        margin-top: 12px;
        margin-left: 10px;
        margin-bottom: 0;
    }

    .title br{
        display: none;
    }

    .box.crash .submitBtn{
        margin-top: 15px;
    }
}

@media (max-width: 460px){
    .box.crash .title{
        font-size: 24px;
    }

    .desc{
        font-size: 13.2px;
        margin-top: 6px;
    }

    .box.crash{
        padding: 0 12px 20px
    }
}

@media (max-width: 445px){
    .title, .title.used, .title.invalid{
        font-size: 25px;
    }

    .statusImage, .statusImage.success{
        max-width: 230px;
        min-width: 210px;
    }

    .instructions{
        font-size: 13px;
    }

    .submitBtn{
        height: 45px;
        font-size: 15px;
        margin-top: 18px;
    }

    .loadingBtn{
        height: 45px;
        margin-top: 18px;
    }
    
    .loader{
        width: 29px;
        height: 29px;
    }
}

@media (max-width: 410px){
    .contentWrapper{
        padding: 14px 11px 10px;
    }

    .title, .title.used, .title.invalid{
        font-size: 22.5px;
    }

    .box.crash .title{
        font-size: 21px;
    }

    .instructions{
        font-size: 12.3px;
    }

    .desc{
        font-size: 12.7px;
    }

    .submitBtn{
        height: 44px;
        font-size: 14px;
        margin-top: 16px;
    }

    .loadingBtn{
        height: 44px;
        margin-top: 16px;
    }

    .loader{
        width: 28px;
        height: 28px;
    }

    .robotImage{
        width: 94%;
        aspect-ratio: 1 / 1;
        height: unset;
        max-width: 270px;
        margin: 12px auto;
    }

    .errorIcon{
        font-size: 16px;
    }
    
    .errorText{
        font-size: 12px;
    }
}

@media (max-width: 370px){
    .box.crash{
        padding: 0 8px 20px
    }

    .desc{
        font-size: 12.4px;
    }
}

@media (max-width: 360px){
    .pulseLoader{
        width: 210px;
        height: 210px;
    }
      
    .innerLoader{
        width: 190px;
        height: 190px;
    }
    
    .loadingText{
        font-size: 20px;
    }
    
}

@keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    33% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }


@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}