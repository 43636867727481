.wrapper{
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    z-index: 10;
}

.box{
    background-color: #201f1f;
    width: 95%;
    max-width: 680px;
    border-radius: 10px;
    box-shadow: 0px 6px 20px rgba(48, 48, 48, 0.8), 0px 3px 10px rgba(255, 255, 255, 0.3);
    position: relative;
    padding-top: 7px;
    display: flex;
    padding: 22px 20px;
    box-sizing: border-box;
    color: #f2e5cd;
}

.imageBox{
    display: block;
    width: 290px;
    min-width: 290px;
    height: 290px;
    margin: auto;
}

.imageBox img{
    display: block;
    width: 100%;
    height: 100%;
}

.info{
    margin-left: 10px;
}

.title{
    font-family: Century Gothic;
    font-weight: 600;
    font-size: 34px;
    line-height: 1.1;
    padding-top: 35px;
}

.msg{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    margin-top: 13px;
    line-height: 1.8;
}


.closeIcon{
    position: absolute;
    right: 10px;
    top: 4px;
    font-size: 32px;
    cursor: pointer;
}

.closeIcon:hover{
    transform: scale(1.05,1.05);
}

@media(max-width: 800px){
    .box{
        display: block;
        max-width: 500px;
        padding: 15px 10px;
    }

    .info{
        text-align: center;
        margin-left: 0;
    }

    .title{
        padding-top: 10px;
        font-size: 30px;
    }

    .msg{
        margin-top: 9px;
    }
}

@media(max-width: 500px){
    .wrapper{
        display: block;
    }

    .box{
        width: 100%;
        height: 100%;
        box-shadow: unset;
        border-radius: unset;
        padding: 25px 10px 10px;
    }

    .imageBox{
        margin-top: 10px;
    }

    .title{
        font-size: 26px;
    }

    .imageBox{
        width: 270px;
        min-width: 270px;
        height: 270px;
        max-width: 85%;
        margin-top: 10px;
    }
}

@media(max-width: 410px){
    .title span{
        display: none;
    }

    .msg{
        font-size: 13.6px;
    }
}