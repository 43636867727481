.menu{
    position: fixed;
    width: 280px;
    box-shadow: 0 0 0 1px #424244;
    background-color: #1e1f21;
    border: 0.8px solid rgb(73, 72, 72);
    border-radius: 7px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    z-index: 5;
}

.countries{
    flex-grow: 1;
    overflow-y: auto;
}

.searchBox{
    width: 100%;
    padding: 7px;
    box-sizing: border-box;
    border-bottom: 1px solid #454444;
}

.searchBox input{
    height: 33px;
    border: 1.55px solid #3c3b3b;
    padding: 0 5.5px;
    font-size: 11.4px;
    border-radius: 3px;
    background-color:#2d2e30;
    outline: 0;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.searchBox input:focus{
    border-color:#454444
}

.countryItem{
    display: flex;
    padding: 5px 6px;
    cursor: pointer;
    color: #d4d0d0;
}

.countryItem:hover{
    background-color: #363333;
}

.countryItem.selected, .countryItem.selected:hover{
    background-color: #3a3939;
    color: #fff;
}

.name{
    font-family: 'Poppins', sans-serif;
    font-size: 11.5px;
    margin-left: 6.5px;
}

.flag{
    height: 15px;
    width: 15px;
    margin-top: -3.5px;
}

.emptyContent{
    color: #d4d0d0;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 0;
}

.flagIcon{
    font-size: 24px;
}

.text{
    font-family: 'Poppins', sans-serif;
    font-size: 12.1px;
}