.box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.wrapper{
    width: 100%;
    height: 100vh;
}

.header{
    height: 58px;
    min-height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
}

.subHeader{
    height: 55px;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    border-top: 0.85px solid #464444;
    border-bottom: 0.85px solid #464444;
}

.searchBox{
    display: flex;
    align-items: center;
    width: 40%;
    background-color: #353535;
    height: 37.5px;
    border-radius: 25px;
    padding: 0 10px;
    box-sizing: border-box;
    min-width: 400px;
}

.searchIcon{
    color: #f5e3c5;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin-top: -1.5px;
}

.searchBox input{
    background-color: inherit;
    outline: 0;
    border: 0;
    height: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-size: 13.5px;
    padding-left: 4px;
    border-radius: 0 25px 25px 0;

}


.title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    color: #f2e5cd;
    font-size: 20px;
}

.profileArea{
    width: 36px;
}

.lengthBox{
    display: flex;
    align-items: center;
}

.btns{
    display: flex;
    align-items: center;
}

.sortWrapper, .filterWrapper{
    position: relative;
    margin-right: 16px;
}

.searchWrapper.min{
    display: none;
}


.filterBtn, .addBtn, .sortBtn{
    display: flex;
    align-items: center;
    cursor: pointer;
}


.filterBtn, .sortBtn{
    padding: 7.5px 12px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
    font-size: 12.2px;
    color: #f2e5cd;
    border: 0.9px solid #494848;
}

.sortBtn.active, .sortBtn:hover, .filterBtn.active, .filterBtn:hover{
    background-color: #353535;
    color: #faf4e8;
    border-color: transparent
}

.filterIcon{
    color: #f5e3c5;
    display: flex;
    align-items: center;
    margin-right: 3px;
}

.sortIcon{
    display: flex;
    align-items: center;
    margin-right: 2px;
}


.addBtn{
    background-color: #ebd6b1;
    color: #000;
    padding: 9px 13px;
    border-radius: 4px;
}

.addText{
    font-family: 'Poppins', sans-serif;
    font-size: 12.7px;
    font-weight: 600;
    margin-left: 3px;
}

.plusIcon{
    display: flex;
    align-items: center;
    font-size: 15px;
}

.lengthText{
    font-family: 'Poppins', sans-serif;
    font-size: 14.5px;
    color: #f5e3c5;
}

.length{
    padding: 3px 6px;
    background-color: #434242;
    color: #f5e3c5;
    border-radius: 3.5px;
    margin-left: 8px;
    font-family: 'Lato', sans-serif;
    font-size: 12.5px;
    font-weight: 600;
}

.grid{
    flex-grow: 1;
    overflow-y: auto;
}

.emptyContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 700px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.cards{
    position: relative;
}

.cardIcon{
    font-size: 230px;
    display: flex;
    align-items: center;
    color: #ebd7b6;
}

.cardImage{
    width: 350px;
    aspect-ratio: 445 / 397;
}

.cardImage img{
    width: 100%;
    height: 100%;
}

.search{
    position: absolute;
    width: 65px;
    height: 65px;
    font-size: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -20px;
    right: 10px;
    color: #ebd7b6;
    background-color: #e5ca9f;
    color: #000;
    border-radius: 50%;
}

.emptyTitle{
    font-family: Century Gothic;
    font-weight: 600;
    font-size: 34px;
    line-height: 1.1;
    margin-top: 30px;
    color: #f2e5cd;
}

.emptyTitle.minMargin{
    margin-top: 2px;
}

.emptyMsg{
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    margin-top: 8px;
    line-height: 1.8;
    color: #f1dcb4;
}

.tarots{
    padding: 20px 18px;
    box-sizing: border-box;
}

.row{
    display: flex;
    align-items: stretch;
    width: 100%;
}

.tarotBox{
    width: calc((100% - 60px)/4);
    min-width: calc((100% - 60px)/4);
    margin-right: 20px;
    margin-bottom: 25px;
}


.row .tarotBox:last-of-type{
    margin-right: 0;
}

@media(max-height: 660px){
    .cardImage{
        width: 280px;
    }

    .emptyTitle{
        font-size: 31px;
    }

    .emptyMsg{
        font-size: 14px;
        margin-top: 4.5px;
    }

    .cardIcon{
        font-size: 190px;
    }

    .search{
        width: 50px;
        height: 50px;
        font-size: 30px;
        bottom: -15px;
        right: 10px;
    }
}

@media (max-width: 1500px){
    .tarotBox{
        width: calc((100% - 45px)/4);
        min-width: calc((100% - 45px)/4);
        margin-right: 15px;
    }
}



@media (max-width: 1320px){
    .tarotBox{
        width: calc((100% - 40px)/3);
        min-width: calc((100% - 40px)/3);
        margin-right: 20px;
    }
}


@media (max-width: 1000px){
    .tarotBox{
        width: calc((100% - 25px)/2);
        min-width: calc((100% - 25px)/2);
        margin-right: 25px;
    }
}


@media (max-width: 750px){
    .searchBox{
        display: none;
    }

    .searchWrapper.min{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border-bottom: 0.6px solid #3c3a3a;
        border-top: 0.7px solid #3c3a3a;
    }

    .min .searchBox{
        display: flex;
        width: 100%;
        border-radius: 7px;
        min-width: unset;
    }

    .addText, .filterText, .sortText{
        display: none;
    }

    .sortBtn, .filterBtn, .addBtn{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding: 0;
        border-radius: 4.5px;
    }

    .sortWrapper, .filterWrapper{
        margin-right: 13px;
    }

    .sortIcon{
        font-size: 15px;
    }

    .plusIcon{
        font-size: 18px;
    }

    .addBtn{
        border-radius: 50%;
        margin-left: -1px;
    }

    .header{
        height: 58px;
        min-height: 58px;
    }

    .title{
        font-size: 17.5px;
    }

    .profileArea{
        width: 34px;
    }

    .subHeader{
        height: 48px;
        min-height: 48px;
        border-top: 0.6px solid #3c3a3a;
        padding: 0 6px;
        margin: 0 8px;
    }
}

@media (max-width: 820px){
    .tarotBox{
        width: calc((100% - 15px)/2);
        min-width: calc((100% - 15px)/2);
        margin-right: 15px;
    }
}

@media (max-width: 640px){
    .tarotBox{
        width: 100%;
        max-width: 505px;
        margin: 0 auto 22px;
    }

    .row .tarotBox:last-of-type{
        margin-right: auto;
    }

    .emptyContent{
        padding: 20px 14px;
    }

    .cardIcon{
        font-size: 210px;
    }
    
    .search{
        width: 55px;
        height: 55px;
        font-size: 35px;
        bottom: -15px;
        right: 10px;
    }
    
    .emptyTitle{
        font-size: 30px;
        margin-top: 26px;
    }
    
    .emptyMsg{
        font-size: 14px;
        line-height: 1.5;
    }

    .cardImage{
        width: 290px;
    }
}


@media (max-width: 600px){
    .header{
        padding-left: 48px;
    }
}


@media(max-width: 500px){
    .subHeader{
        margin: 0 5px;
    }

    .searchWrapper.min{
        padding: 10px;
    }

    .emptyTitle span{
        display: none;
    }

}

@media(max-width: 330px){

    .emptyContent{
        padding: 20px 10px;
    }
    .emptyTitle{
        font-size: 27px;
    }
    
    .emptyMsg{
        font-size: 13.7px;
    }
    
    .cardIcon{
        font-size: 190px;
    }

    .search{
        width: 50px;
        height: 50px;
        font-size: 30px;
        bottom: -15px;
        right: 10px;
    }

    .cardImage{
        width: 245px;
    }
}