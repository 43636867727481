.headerContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1450px;
    width: 100%;
    margin: 0 auto;
    height: 67px;
    padding: 0 80px;
    min-height: 67px;
}

.wrapper header{
    width: 100%;
}

.wrapper footer{
    width: 100%;
    background-color: #363636;
}

.links{
    display: flex;
    align-items: center;
}

.clickUps{
    display: flex;
    align-items: center;
}


.btns{
    display: flex;
    align-items: center;
}

.wrapper{
    background-color: #171616;
    color: #fff;
}

.box{
    height: 100%;
    overflow-y: hidden;
}

.content{
    flex-grow: 1;
    overflow-y: auto;
}

.illustrationWrapper{
    padding: 105px 70px;
    padding-right: 120px;
    background-color: #272626;
    height: 560px;
    box-sizing: border-box;
    width: 100%;
}

.illustration{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1450px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.leftBox{
    width: calc(48% - 50px);
    min-height: 400px;
}

.leftContent{
    width: 100%;
}

.rightBox{
    width: calc(52% - 50px);
    justify-content: center;
    margin-top: 20px;
}

.logoBox{
    display: flex;
    align-items: center;
}

.appName{
    font-family: 'Parisienne', cursive;
    font-size: 34px;
    font-weight: 600;
    color: #ebd6b1;
}

.logoBox img{
    width: 46px;
    height: 46px;
    margin-right: 8px;
}

.links{
    margin-right: 80px;
}

.link{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #e9e7e7;
    margin-right: 20px;
}


.illustrationTitle{
    font-family: Century Gothic;
    font-weight: 600;
    font-size: 41px;
    line-height: 1.1;
    letter-spacing: 0.4px;
    color: #ebd6b1;
}

.illustrationText{
    font-family: 'DM Sans', sans-serif;
    padding-top: 8px;
    color: #f5ead8;
    font-size: 15.5px;
    line-height: 1.4;
    width: 80%;
}

.inputBox{
    margin-top: 27px;
    display: flex;
    align-items: center;
    border: 1.3px solid #464444;
    width: 83%;
    box-sizing: border-box;
    border-radius: 4px;
    height: 50px;
    padding: 0 10px;
    padding-right: 6px;
    background-color: #171616;
    color: #d4cfcf;
}

.inputArea{
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.inputArea input{
    font-family: 'Poppins', sans-serif;
    background-color: inherit;
    border: 0;
    outline: 0;
    height: 100%;
    font-size: 13px;
    width: calc(100% - 21px);
}

.inputIcon{
    display: flex;
    align-content: center;
    font-size: 17.5px;
    margin-right: 3.5px;
}

.inputArea input::placeholder{
    color: #d4cfcf;
}

.startBtn{
    background-color: #ebd6b1;
    height: calc(100% - 10px);
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}


.chatItem{
    background-color: #ebd6b1;
    color: #000;
    border-radius: 15px 15px 4px 15px;
    padding: max(11.4px,min(0.6vw,12px));
    font-family: 'Poppins', sans-serif;
    font-size: max(11.6px,min(0.9vw,12.2px));
    width: min(18.2vw,300px);
    min-width: 260px;
    position: absolute;
}


.image{
    width: max(370px,min(26.5vw, 400px));
    height: max(370px,min(26.5vw, 400px));
    position: absolute;
    top: 0;
}

.image img{
    width: 100%;
    height: 100%;
}

.rightBox{
    display: flex;
    position: relative;
    align-items: center;
}

.image{
    position: relative;
    /* width: 100%;
    height: 100%; */
}

.image .chatItem:first-of-type{
    top: -58px;
    left: calc((100% - 330px) / 2)
}

.image .chatItem:nth-of-type(2){
    top: 60px;
    left: -48%;
    /* z-index: 10; */
}

.image .chatItem:nth-of-type(3){
    top: 60px;
    left: 70%;
}
.image .chatItem:nth-of-type(4){
    top: 190px;
    left: -56%;
    z-index: 10;
}
.image .chatItem:last-of-type{
    top: 190px;
    left: 78%;
    z-index: 10;
}

.signupBtn{
    height: 38px;
    padding: 0 17px;
    background-color: #ebd6b1;
    color: #000;
    border: 0;
    outline: 0;
    border-radius: 3px;
    font-family: 'Poppins', sans-serif;
    font-size: 14.5px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 20px;
}

.loginBtn{
    height: 38px;
    padding: 0 10px;
    background-color: inherit;
    color: #ebd6b1;
    border: 0;
    outline: 0;
    border-radius: 3px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.loginBtn:hover{
    background-color: #464444;
}

.signupBtn:hover{
    transform: scale(1.05,1.05);
}

.link:hover, .link.selected{
    color: #ebd6b1;
    text-decoration: underline;
    cursor: pointer;
}

.footerContent{
    display: flex;
    align-items: center;
    max-width: 1450px;
    width: 100%;
    margin: 0 auto;
    height: 58px;
    padding: 0 80px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14.4px;
    letter-spacing: -0.2px;
}

.text{
    margin-left: 40px;
    cursor: pointer;
}

.language{
    display: flex;
    align-items: center;
    margin-left: 80px;
}

.languageIcon{
    display: flex;
    align-items: center;
    margin-right: 3px;
}

.text:hover{
    text-decoration: underline;
}

.categories{
    width: 100%;
}

.category{
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 80px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
}

.categoryImage{
    width: 385px;
    min-width: 385px;
    height: 385px;
    margin-left: 60px;
}

.categoryImage img{
    width: 100%;
    height: 100%;
}

.categories .category:first-of-type .categoryImage{
    order: 2;
}

.categories .category:first-of-type .info{
    order: 1;
}

.categories .category:last-of-type{
    padding-top: 60px;
    border-top: 1.8px solid #333232;
}

.categories .category:last-of-type .categoryImage{
    margin-right: 60px;
    margin-left: 0;
    margin-top: -30px;
    height: 370px;
    width: 370px;
    min-width: 370px;
}

.info{
    flex-grow: 1;
    max-width: 600px;
}

.infoTitle{
    background: rgba(137, 32, 254, .81);
    background: linear-gradient(45deg, #ebd6b1, #534f4f 50%, #ebd6b1 75%);
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: 900;
    -webkit-text-fill-color: transparent;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 5px;
}

.infoDesc{
    font-family: 'Poppins', sans-serif;
    font-size: 13.8px;
    line-height: 1.8; 
}

.btn{
    background-color: #ebd6b1;
    color: #000;
    font-family: 'Poppins', sans-serif;
    outline: 0;
    border: 0;
    font-weight: 600;
    padding: 8px 15px;
    font-size: 14.8px;
    border-radius: 20px;
    margin-top: 16px;
    cursor: pointer;
}

.btn:hover{
    transform: scale(1.05,1.05);
}

@media (max-width: 1460px){
    .headerContent{
        max-width: unset;
        box-sizing: border-box;
    }

    .illustration{
        max-width: unset;
    }
}

@media (max-width: 1350px){
    .illustrationWrapper{
        padding-right: max(10vw,140px);
    }
}

@media (max-width: 1300px){
    .illustrationWrapper{
        padding-left: 40px;
    }
    .chatItem{
        padding: max(11px, min(0.8vw, 10.8px));
        font-family: 'Poppins', sans-serif;
        font-size: max(11px, min(0.9vw, 11.3px));
        min-width: 230px;
    }

    .illustrationTitle{
        font-size: 33px;
    }

    .illustrationText{
        font-size: 14.9px;
    }

    .leftBox, .rightBox{
        width: 46%;
    }

    .illustrationWrapper{
        height: 530px
    }
}

@media (max-width: 1245px){
    .illustration{
        flex-direction: column;
    }

    .illustrationWrapper{
        height: unset;
        padding: 90px 0 0;
    }

    .leftBox{
        order: 2;
        margin-top: 35px;
        width: 100%;
    }

    .rightBox{
        order: 1;
        margin-top: 0;
        width: 100%;
        padding: 0 50px;
    }

    .illustrationTitle{
        color: #000;
        text-align: center;
    }

    .illustrationText{
        color: #958064;
        text-align: center;
        width: 100%;
        padding-top: 15px;
    }

    .image{
        width: 360px;
        height: 360px;
    }

    .chatItem{
        padding: 10.7px;
        font-size: 12.1px;
        width: 290px;
    }

    .image .chatItem:first-of-type{
        top: -55px;
        left: calc((100% - 310px) / 2)
    }
    
    .image .chatItem:nth-of-type(2){
        left: -70%
    }
    
    .image .chatItem:nth-of-type(3){
        left: 81%;
    }
    .image .chatItem:nth-of-type(4){
        left: -83%;
    }
    .image .chatItem:last-of-type{
        left: 95%;
    }

    .leftBox{
        background-color: #ebd6b1;
        padding: 15px 0 45px;
        min-height: unset;
    }

    .leftContent{
        max-width: 630px;
        width: 100%;
        margin: 10px auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inputBox{
        background-color: #d9c5a4;
        border-color: #aa977a;
    }

    .startBtn{
        color: #ebd6b1;
        background-color: #000;
    }

    .inputBox{
        color: #5b5a5a;
        margin-top: 30px;
    }

    .inputArea input::placeholder{
        color: #5b5a5a;

    }
    
}

@media (max-width: 1100px){

    .category{
        padding: 40px 30px;
    }


    .categoryImage{
        width: 365px;
        min-width: 365px;
        height: 365px;
        margin-left: 40px;
    }
    
    .categories .category:last-of-type .categoryImage{
        margin-right: 40px;
        margin-left: 0;
        margin-top: -30px;
        height: 350px;
        width: 350px;
        min-width: 350px;
    }

    .infoTitle{
        font-size: 26px;
    }
    
    .infoDesc{
        font-size: 13.4px;
    }
    
    .btn{
        font-size: 14.5px;
    }
}

@media (max-width: 970px){
    .chatItem{
        padding: 1vw;
        font-size: max(1.28vw,10.5px);
        width: 28vw;
        min-width: 225px;
        left: calc((100% - 310px) / 2);
    }

    .image .chatItem:first-of-type{
        left: calc((100% - 30vw) / 2)
    }

    .image{
        width: 35vw;
        height: 35vw;
    }

    .headerContent, .footerContent{
        padding: 0 20px;
    }

    .categoryImage{
        margin-left: 12px;
    }
    
    .categories .category:last-of-type .categoryImage{
        margin-right: 12px;
    }
}

@media (max-width: 780px){
    .category{
        display: block;
    }

    .categoryImage{
        float: left;
    }

    .infoTitle{
        font-size: 24px;
    }
    
    .infoDesc{
        font-size: 13px;
        line-height: 1.7;
    }
    
    .btn{
        font-size: 14.2px;
    }

    .categoryImage{
        margin-left: 0;
        width: 150px;
        height: 150px;
        min-width: 150px;
        margin-right: 10px;
    }
    
    .categories .category:last-of-type .categoryImage{
        margin-right: 10px;
        width: 130px;
        height: 130px;
        min-width: 130px;
        margin-top: 0px;
    }

    .infoTitle{
        margin-bottom: 0;
        margin-top: 6px;
    }

    .infoDesc{
        margin-top: -2px;
    }

    .categories .category:last-of-type{
        padding-top: 30px;
        padding-bottom: 70px;
    }
}

@media (max-width: 760px){
    .clickUps{
        flex-grow: 1;
    }

    .links{
        flex-grow: 1;
        justify-content: center;
        margin-right: 0;
    }

    .appName{
        font-size: 30px;
    }

    .logoBox img{
        width: 42px;
        height: 42px;
        margin-right: 5px;
    }
}

@media (max-width: 730px){
    .rightBox{
        display: none;
    }

    .illustrationTitle{
        color: #ebd6b1;
    }

    .illustrationText{
        color: #f5ead8;
    }

    .inputBox{
        border-color: #464444;
        background-color: #171616;
        color: #d4cfcf;
    }

    .leftBox{
        background-color: unset;
        margin-top: 0;
        padding: 40px 0 50px;
    }

    .illustrationWrapper{
        padding: 0;
    }

    .leftContent{
        max-width: unset;
        width: 100%;
        margin: 0 auto;
        max-width: 630px;
        padding: 0 40px;
    }

    .inputArea input::placeholder{
        color: #d4cfcf;
    }

    .startBtn{
        background-color: #ebd6b1;
        color: #000;
    }
}

@media (min-width: 1600px){
    .chatItem{
        padding: 11.2px;
        font-size: 12.8px;
        width: 310px;
    }

    .image{
        width: 410px;
        height: 410px;
    }
}