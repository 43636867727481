.wrapper{
    width: 100%;
    height: 100vh;
    background-color: black;
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    background-color: rgba(64, 65, 68, 0.85);
    z-index:4500;
}

.container{
    position: relative;
    margin-top: 8vh;
}

.box{
    background-image: url('../media/cardFrame.png');
    background-size: 100% 100%;
    height: 30vh;
    width: 64vh;
    background-color: #F2E6D0;
    position: relative;
    border-radius: 1vh;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 2%;
    box-shadow: inset 4px 5px 7px #b0a58f, inset -4px -5px 7px #b0a58f;
}

.card{
    height: 86%;
    aspect-ratio: 1 / 1.5;
    position: absolute;
    left: -15%;
    /* border: 0.6vh solid #cdc4b4; */
    box-sizing: border-box;
    background-size: 100% 100%;
    border-radius: 1.5vh;
    box-shadow: inset 1.5px 2px 4px #b0a58f, inset -1.5px -2px 4px #b0a58f;
}

.card.reversed{
    transform: scaleY(-1);
}

.content{
    height: 80%;
    margin-left: 13%;
    padding-right: 6%;
}

.cardTitle{
    font-family: 'Eczar', serif;
    font-family: 'IM Fell English SC', serif;
    font-size: 4vh;
    font-weight: 700;
}

.cardTitle span{
    font-weight: 400;
}

.cardShortDesc{
    font-family: 'Caudex', serif;
    font-size: 1.95vh;
    font-weight: 700;
    text-decoration: underline;
    font-family: 'Almendra', serif;
    letter-spacing: 0.04vh;
}

.cardLongDesc{
    font-size: 1.9vh;
    font-weight: 600;
    line-height: 1.55;
    color: #a28d65;
    font-family: 'Macondo', cursive;
    padding-top: 0.4vh;
}

.closeIcon{
    position: absolute;
    right: -4vh;
    top: 0;
    background-color: #F2E6D0;
    width: 3.6vh;
    height: 3.6vh;
    border-radius: 50%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.6vh;
    cursor: pointer;
}

.closeIcon:hover{
    transform: scale(1.05,1.05);
    background-color: #e7d8bd;
}


.stepper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1.5vh;
    left: 0;
    width: 100%;
}

.dot{
    width: 1.2vh;
    height: 1.2vh;
    border-radius: 50%;
    background-color: #000;;
    margin-right: 1vh;
    cursor: pointer;
    opacity: 0.25;
}

.dot:hover{
    transform: scale(1.2,1.2);
}

.stepper .dot:last-of-type{
    margin-right: 0;
}

.dot.active{
    width: 3.5vh;
    border-radius: 0.7vh;
    background-color: #000;;
    pointer-events: none;
    opacity: 1;
}

.viewBox{
    display: flex;
    align-items: center;
    position: absolute;
    top:0.7vh;
    right: 1.5vh;
    color: #907d59;
    cursor: pointer;
}

.viewBox:hover{
    text-decoration: underline;
    transform: scale(1.05,1.05);
}

.viewText{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.45vh;
}

.redirectIcon{
    font-size: 1.75vh;
    display: flex;
    align-items: center;
    margin-right: 0.25vh;
    margin-top: -0.1vh;
}

@media (not (orientation:landscape)) and (not (height:100vw)){
    .container{
        margin-top: calc(30vw + (100vh - 100vw) / 2)
    }

    .box{
        height: 30.2vw;
        width: 64.2vw;
        border-radius: 1.1vw;
        box-shadow: inset 0.51vw 0.63vw 0.885vw #b0a58f, inset -0.5vw -0.625vw 0.885vw #b0a58f;
    }

    .card{
        border-radius: 1.52vw;
        box-shadow: inset 1.5px 2px 4px #b0a58f, inset -1.5px -2px 4px #b0a58f;
    }

    .cardTitle{
        font-size: 4.02vw;
    }

    .cardShortDesc{
        font-size: 1.97vw;
        letter-spacing: 0.05vw;
    }

    .cardLongDesc{
        font-size: 1.92vw;
        padding-top: 0.42vw;
    }

    .closeIcon{
        right: -4.02vw;
        width: 3.62vw;
        height: 3.62vw;
        font-size: 2.62vw;
    }

    .stepper{
        bottom: 1.52vw;
    }

    .dot{
        width: 1.22vw;
        height: 1.22vw;
        margin-right: 1.02vw;
    }

    .dot.active{
        width: 3.52vw;
        border-radius: 0.72vw;
    }

    .viewBox{
        top:0.72vw;
        right: 1.52vw
    }

    .viewText{
        font-size: 1.47vw;
    }

    .redirectIcon{
        font-size: 1.77vw;
        margin-right: 0.27vw;
        margin-top: -0.12vw;
    }
}