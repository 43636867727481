.wrapper{
    flex-grow: 1;
    width: 100%;
    max-height: 720px;
    overflow-y: auto;
    padding: 25px 0;
    display: flex;
    align-items: center;
    border-top: 0.85px solid #464444;
    box-sizing: border-box;
    overflow-x: hidden;
}

.box{
    margin: auto;
    width: 580px;
    min-width: 280px;
}

.imageBox{
    display: block;
    width: 340px;
    min-width: 340px;
    height: 340px;
    margin: auto;
}

.imageBox img{
    display: block;
    width: 100%;
    height: 100%;
}

.info{
    text-align: center;
}

.title{
    font-family: Century Gothic;
    font-weight: 600;
    font-size: 34px;
    line-height: 1.1;
    text-transform: uppercase;
    color: #f2e5cd;
}

.msg{
    font-size: 14.7px;
    font-family: 'Poppins', sans-serif;
    margin-top: 13px;
    line-height: 1.8;
    color: #f1dcb4;
}


@media(max-height: 620px){
    .title{
        font-size: 33.5px;
    }
    
    .msg{
        font-size: 13.8px;
    }  
}

@media(max-width: 660px){

    .box{
        width: 100%;
        padding: 0 20px;
    }
    
    .imageBox{
        width: 290px;
        min-width: 290px;
        height: 290px;
    }

    .title{
        font-size: 30px;
    }
    
    .msg{
        font-size: 13.8px;
        margin-top: 7px;
    }   
}

@media(max-width: 560px){
    .title span{
        display: none;
    }

    .title::after {
        content: "!!";
    }
}

@media(max-width: 400px){
    .box{
        padding: 0 12px;
    }
}

@media(max-width: 320px){

    .title{
        font-size: 27.5px;
    }

    .imageBox{
        width: 255px;
        min-width: 255px;
        height: 255px;
    }

    .msg{
        font-size: 13.5px;
    }
}