.navItem{
    display: flex;
    align-items: center;
    padding: 7px 0;
    text-decoration: none;
    color: unset;
    cursor: pointer;
    color:#f5e3c5;
    position: relative;
    padding-left: 22px;
    border-radius: 5px;
    margin-bottom: 2px;
}

.box main .navItem:last-of-type, .box footer .navItem:last-of-type{
    margin-bottom: 0;
}

.navIcon.dashboard{
    font-size: 14.5px;
}

.navIcon.dreams{
    font-size: 14.5px;
    margin-top:-1px;
}

.navIcon.tarots{
    margin-top: -2.5px;
}

.navIcon{
    position: absolute;
    left: 6px;
    font-size: 15.5px;
    display: flex;
    align-items: center;
}

.navText{
    font-family: 'Poppins', sans-serif;
    font-size: 12.6px;
    margin-left:4.5px;
}

.navIcon.logout{
    font-size: 14,5px;
}

.navIcon.settings{
    font-size: 16.5px;
    margin-left: -1.5px;
}

.box{
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 160px;
    box-sizing: border-box;
    background-color: #272525;
    border-right:1.1px solid #484848;
}

.box main{
    flex-grow: 1;
    padding: 10px;
    border-top:1.1px solid #484848;
    border-bottom: 1.1px solid #484848;
}

.box header{
    display: flex;
    align-items: center;
    padding: 10px 10px 3px;
}

.box header.dashboard{
    height: 67px;
    min-height: 67px;
    box-sizing: border-box;
}

.appName{
    font-family: 'Parisienne', cursive;
    font-size: 25px;
    font-weight: 600;
    color:#e8d2ad;
    margin-left: 4px;
}

.icon img{
    width: 35px;
    height: 35px;
}

.navItem:hover, .navItem.selected{
    background-color: #4a4949;
    color:#e8d2ad;
}

.navItem.selected{
    background-color: #4e4638;
    margin: 3px 0;
}

.box footer{
    padding: 10px;
}

.closeIcon{
    display: none;
}

@media (max-width: 1300px) and (min-width: 600px){

    .box{
        width: 60px;
    }

    .navText{
        display: none;
    }

    .navItem{
        width: 32px;
        height: 32px;
        padding:0;
        justify-content: center;
    }

    .navIcon{
        position: unset;
    }

    .appName{
        display: none;
    }

    .box main{
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .box footer{
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .box header{
        justify-content: center;
    }

    .navIcon.dashboard{
        font-size: 15.5px;
    }
    
    .navIcon.dreams{
        font-size: 15.5px;
    }
    
    .navIcon{
        font-size: 16.5px;
    }

    .navIcon.logout{
        font-size: 15.5px;
    }
    
    .navIcon.settings{
        font-size: 17.5px;
    }

    .navIcon.users{
        font-size: 18.5px;
    }
    
}


@media(max-width: 650px){
    .wrapper{
        width: 0;
    }
    
    .wrapper.hide .box{
        display: none;
    }

    .box:not(.hide){
        width: 180px;
        position: fixed;
        display: block;
        z-index: 10;
        padding-top: 6px;
        box-shadow: 0 0 0 1px #424244;
        background-color: #1e1f21;
        border-right: 0.8px solid rgb(73, 72, 72);
        box-sizing: border-box;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .wrapper.hide .navMenu{
        position: fixed;
        top: 0;
        left: 7px;
        height: 53px;
        display: flex;
        align-items: center;
        color: #f2e5cd;
    }

    .menuIcon{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        border-radius: 7px;
        color: #f2e5cd;
    }

    .menuIcon:hover, .closeIcon:hover{
        background-color: #4a4949;
        color:#e8d2ad;    }

    .closeIcon{
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        cursor: pointer;
        border-radius: 10px;
        position: absolute;
        top: 5px;
        right: 3px;
        color:#f5e3c5;
    }

    .box header{
        padding-top: 20px;
    }
}