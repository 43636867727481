.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(64, 65, 68, 0.65);
    z-index: 6000;
}



.box{
    background-image: url('../media/restartBackground3.png');
    background-size: 100% 100%;
    width: 65vh;
    height: 62vh;
    position: relative;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
    background-color: #000;
    border-radius: 1vh;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 16px 0px;
}

.boxWrapper{
    position: relative;
}

.title{
    font-family: 'IM Fell English SC', serif;
    font-family: 'Eczar', serif;
    text-transform: uppercase;
    font-size: 4.4vh;
    font-weight: 600;
    position: absolute;
    top: 33.5%;
    height: 14.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 15%;
    width: 70%;
    color: #f7e6c5;
}

.title.complete{
    font-size: 4.5vh;
}

.content{
    position: absolute;
    text-align: center;
    top: 48.5%;
    color: #000;
    padding: 0 24%;
    font-family: 'Caudex', serif;
    font-size: 2.22vh;
    line-height: 1.2;
    font-weight: 600;
}

.content.complete{
    font-size: 2.4vh;
    line-height: 1.16;
    top: 48%;
}

.buttons{
    top: 62%;
    position: absolute;
    left: 15%;
    width: 70%;
    display: flex;
    justify-content: center;
    height: 17.5%;
    align-items: center;
    padding: 0 7%;
    box-sizing: border-box;
}

.buttons button{
    width: 41%;
    aspect-ratio: 532 / 358;
    background-image: url('../media/restartBtn.PNG');
    background-size: 100% 100%;
    border: 0;
    outline: 0;
    background-color: unset;
    font-family: 'IM Fell English SC', serif;
    font-size: 2.9vh;
    font-weight: 800;
    cursor: pointer;
    text-transform: uppercase;
    color: #f7e6c5;
    display: flex;
    align-items: center;
    justify-content: center;

}

.buttons button:first-of-type{
    transform: scale(-1,1);
    margin-right: 1.3vh;
}

.btnText{
    margin-left: -1.5vh;
}

.buttons button:first-of-type .btnText{
    transform: scale(-1,1);
    margin-right: 1.5vh;
    margin-left: unset;
}

.buttons button:hover{
    transform: scale(1.1,1.1);
}

.buttons button:first-of-type:hover{
    transform: scale(-1.1,1.1);
}



.closeIcon{
    width: 5.5vh;
    height: 5.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: -6vh;
    position: absolute;
    background-color: #000;
    color: #e8d5ae;
    border-radius: 50%;
    font-size: 4vh;
    cursor: pointer;
}

.closeIcon:hover{
    background-color: #111111;
    transform: scale(1.05,1.05);
}

.loadingDots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #f7e6c5;
    border-radius: 50%;
    display: inline-block;
    height: 1.4vh;
    width: 1.4vh;
}

.loadingDots .dot:first-of-type{
    margin-left: -1.5vh;
}
    
.loadingDots .dot:nth-child(2) {
    animation-delay: .25s;
    margin-left: 0.7vh;
}
    
.loadingDots .dot:nth-child(3) {
    animation-delay: 0.5s;
    margin-left: 0.7vh;
}

.buttons.loading{
    pointer-events: none;
}

.loader{
    border: 0.4vh solid #000;
    border-radius: 50%;
    border-top: 0.4vh solid transparent;
    width: 4.8vh;
    height: 4.8vh;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@keyframes dot-keyframes {
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }
  
    50% {
      opacity: 1;
      transform: scale(1.1, 1.1);
    }
  
    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
}

@media (max-width: 113vh){
    .box{
        width: 58vw;
        height: 54vw;
        border-radius: 1.2vw;
    }

    .title{
        font-size: 4vw;
    }
    
    .title.complete{
        font-size: 4.1vw;
    }
    
    .content{
        font-size: 2vw;
    }
    
    .content.complete{
        font-size: 2.2vw;
    }
    
    
    .buttons button{
        font-size: 2.7vw;
    }
    
    .buttons button:first-of-type{
        margin-right: 1.2vw;
    }
    
    .btnText{
        margin-left: -1.2vw;
    }
    
    .buttons button:first-of-type .btnText{
        margin-right: 1.2vw;
    }

    .closeIcon{
        width: 5.3vw;
        height: 5.3vw;
        right: -5.8vw;
        font-size: 3.8vw;
    }
    
    .dot {
        height: 1.4vw;
        width: 1.4vw;
    }
    
    .loadingDots .dot:first-of-type{
        margin-left: -1.2vw;
    }
        
    .loadingDots .dot:nth-child(2) {
        margin-left: 0.65vw;
    }
        
    .loadingDots .dot:nth-child(3) {
        margin-left: 0.65vw;
    }
    
    .loader{
        border-width: 0.3vw;
        width: 4.6vw;
        height: 4.6vw;
    }
}