.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.85px solid #414040;
    padding: 8px 0;
    color: #ccc8c8;
}

.btns{
    display: flex;
    align-items: center;
}

.intentionBox{
    padding: 8px 0 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
}

.intention{
    color: #ccc8c8;
}

.doubleWrapper{
    position: relative;
}

.spreadBox{
    display: inline-flex;
    align-items: center;
    background-color: #323233;
    color: #c2bfbf;
    padding: 4px 5.5px;
    border-radius: 7px;
}

.spreadIcon{
    display: flex;
    align-items: center;
    font-size: 12.2px;
    margin-right: 2px;
}

.unbookMarked{
    display: flex;
    align-items: center;
    color: #ccc8c8;
    font-size: 15px;
}

.bookMarkButton{
    position: absolute;
    right: -2px;
    top: -1px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    justify-content: center;
}

.unbookMarked{
    font-size: 20px;
}

.bookMarked{
    font-size: 17px;
}

.bookMarkButton:not(.selected):hover .unbookMarked{
    color: #ebd6b1;
}

.bookMarkButton.selected:hover .unbookMarked{
    color: grey;
}

.bookMarkButton:hover{
    transform: scale(1.05,1.05);
    background-color: #414040;
}

.bookMarked{
    display: none;
    align-items: center;
    color: #ebd6b1;
}

.bookMarkButton.selected .bookMarked{
    display: flex;
}

.bookMarkButton.selected .unbookMarked{
    display: none;
}

.spreadName{
    font-family: 'DM Sans', sans-serif;
    font-size: 12.3px;
}


.labelBox{
    float: left;
    display: flex;
    align-items: center;
    margin-right: 2.5px;
}

.targetIcon{
    margin-right: 1.2px;
    display: flex;
    align-items: center;
}

.box{
    padding: 10px 10px 0;
    color: #fff;
    width: 100%;
    box-shadow: 0 0 0 1px #424244;
    cursor: pointer;
    background-color: #1f1f1f;
    border-radius: 6px;
    border: 0.5px solid #363535;
    box-sizing: border-box;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cardsArea{
    width: 100%;
    min-width: 100%;
    aspect-ratio: 2.2 / 1;
    max-height: 165px;
    min-height: 120px;
    background-color: #323233;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 8px;
    box-sizing: border-box;
}

.cardsArea .card{
    width: calc((100% - 32px) / 5);
    margin-right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 80px;
    cursor: pointer;
    position: relative;
}

.tooltip{
    position: absolute;
    top: -20%;
    background-color: #575555;
    padding: 3px 8px;
    text-wrap: nowrap;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    display: none;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
}

.tooltip.bottom{
    top: unset;
    bottom: 5px;
}

.card:hover .tooltip{
    display: block;
}

.cardsArea.max .tooltip{
    top: -27%;
}

.cardsArea .tooltip.bottom{
    top: unset;
    bottom: -22%;
}

.cardsArea.max .tooltip.bottom{
    top: unset;
    bottom: -33%;
}

.card:hover img{
    transform: scale(1.15,1.15);
}

.cardsArea.max .card:hover img{
    transform: scale(1.08,1.08);
}

.cardsArea.max .card{
    width: calc((100% - 30px) / 3);
    margin-right: 15px;
    max-width: 65px;
}


.cardsArea .card:last-of-type{
    margin-right: 0;
}

.card img{
    display: block;
    width: 100%;
    aspect-ratio: 1 / 1.7;
    max-height: 100%;
}

.content{
    padding: 12px 0 8px;
    flex-grow: 1;
}

.resumeBtn{
    margin-left: 1.5px;
    margin-top: 1.5px;
}

.resumeWrapper{
    font-size: 6.5px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.3px solid #ccc8c8;
    border-radius: 50%;
    margin-right: 7px;
    cursor: pointer;
}

.menuWrapper{
    position: relative;
}

.ellipsisBtn{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
}

.ellipsisBtn:hover, .ellipsisBtn.active{
    background-color: #565555;
    border-radius: 3.5px;
}

.resumeWrapper:hover{
    background-color: #565555;
    border-color: transparent;
}

.dateBox{
    display: flex;
    align-items: center;
}

.date, .questionNum{
    font-family: 'DM Sans', sans-serif;
    font-size: 12.7px;
}

.calendarIcon{
    display: flex;
    align-items: center;
    font-size: 14.5px;
    margin-right: 2.5px;
    margin-top: -2.5px;
}

.questionBox{
    display: flex;
    align-items: center;
    margin-right: 12.5px;
}

.questionIcon{
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 4px;
}

@media (max-width: 1320px) {
    .cardsArea{
        max-height: 140px;
        min-height: 100px;
    }
}

@media(max-width: 1000px){
    .cardsArea .card{
        max-width: 90px;
    }
}


@media(max-width: 640px){
    .cardsArea .card{
        max-width: 60px;
        margin-right: 12px;
    }
}